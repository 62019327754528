"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function () {
  function r(e, n, t) {
    function o(i, f) {
      if (!n[i]) {
        if (!e[i]) {
          var c = "function" == typeof require && require;
          if (!f && c) return c(i, !0);
          if (u) return u(i, !0);
          var a = new Error("Cannot find module '" + i + "'");
          throw a.code = "MODULE_NOT_FOUND", a;
        }

        var p = n[i] = {
          exports: {}
        };
        e[i][0].call(p.exports, function (r) {
          var n = e[i][1][r];
          return o(n || r);
        }, p, p.exports, r, e, n, t);
      }

      return n[i].exports;
    }

    for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
      o(t[i]);
    }

    return o;
  }

  return r;
})()({
  1: [function (require, module, exports) {
    'use strict'; 

    window.$ = window.jQuery = require('jquery'); 

    require('slick-carousel'); 


    require('tooltipster'); 

  }, {
    "jquery": 2,
    "slick-carousel": 3,
    "tooltipster": 4
  }],
  2: [function (require, module, exports) {
    (function (global, factory) {
      "use strict";

      if (_typeof(module) === "object" && _typeof(module.exports) === "object") {
        module.exports = global.document ? factory(global, true) : function (w) {
          if (!w.document) {
            throw new Error("jQuery requires a window with a document");
          }

          return factory(w);
        };
      } else {
        factory(global);
      } 

    })(typeof window !== "undefined" ? window : this, function (window, noGlobal) {
      "use strict";

      var arr = [];
      var getProto = Object.getPrototypeOf;
      var _slice = arr.slice;
      var flat = arr.flat ? function (array) {
        return arr.flat.call(array);
      } : function (array) {
        return arr.concat.apply([], array);
      };
      var push = arr.push;
      var indexOf = arr.indexOf;
      var class2type = {};
      var toString = class2type.toString;
      var hasOwn = class2type.hasOwnProperty;
      var fnToString = hasOwn.toString;
      var ObjectFunctionString = fnToString.call(Object);
      var support = {};

      var isFunction = function isFunction(obj) {
        return typeof obj === "function" && typeof obj.nodeType !== "number";
      };

      var isWindow = function isWindow(obj) {
        return obj != null && obj === obj.window;
      };

      var document = window.document;
      var preservedScriptAttributes = {
        type: true,
        src: true,
        nonce: true,
        noModule: true
      };

      function DOMEval(code, node, doc) {
        doc = doc || document;
        var i,
            val,
            script = doc.createElement("script");
        script.text = code;

        if (node) {
          for (i in preservedScriptAttributes) {
            val = node[i] || node.getAttribute && node.getAttribute(i);

            if (val) {
              script.setAttribute(i, val);
            }
          }
        }

        doc.head.appendChild(script).parentNode.removeChild(script);
      }

      function toType(obj) {
        if (obj == null) {
          return obj + "";
        } 


        return _typeof(obj) === "object" || typeof obj === "function" ? class2type[toString.call(obj)] || "object" : _typeof(obj);
      }


      var version = "3.5.1",
      jQuery = function jQuery(selector, context) {
        return new jQuery.fn.init(selector, context);
      };

      jQuery.fn = jQuery.prototype = {
        jquery: version,
        constructor: jQuery,
        length: 0,
        toArray: function toArray() {
          return _slice.call(this);
        },
        get: function get(num) {
          if (num == null) {
            return _slice.call(this);
          } 


          return num < 0 ? this[num + this.length] : this[num];
        },
        pushStack: function pushStack(elems) {
          var ret = jQuery.merge(this.constructor(), elems); 

          ret.prevObject = this; 

          return ret;
        },
        each: function each(callback) {
          return jQuery.each(this, callback);
        },
        map: function map(callback) {
          return this.pushStack(jQuery.map(this, function (elem, i) {
            return callback.call(elem, i, elem);
          }));
        },
        slice: function slice() {
          return this.pushStack(_slice.apply(this, arguments));
        },
        first: function first() {
          return this.eq(0);
        },
        last: function last() {
          return this.eq(-1);
        },
        even: function even() {
          return this.pushStack(jQuery.grep(this, function (_elem, i) {
            return (i + 1) % 2;
          }));
        },
        odd: function odd() {
          return this.pushStack(jQuery.grep(this, function (_elem, i) {
            return i % 2;
          }));
        },
        eq: function eq(i) {
          var len = this.length,
              j = +i + (i < 0 ? len : 0);
          return this.pushStack(j >= 0 && j < len ? [this[j]] : []);
        },
        end: function end() {
          return this.prevObject || this.constructor();
        },
        push: push,
        sort: arr.sort,
        splice: arr.splice
      };

      jQuery.extend = jQuery.fn.extend = function () {
        var options,
            name,
            src,
            copy,
            copyIsArray,
            clone,
            target = arguments[0] || {},
            i = 1,
            length = arguments.length,
            deep = false; 

        if (typeof target === "boolean") {
          deep = target; 

          target = arguments[i] || {};
          i++;
        } 


        if (_typeof(target) !== "object" && !isFunction(target)) {
          target = {};
        } 


        if (i === length) {
          target = this;
          i--;
        }

        for (; i < length; i++) {
          if ((options = arguments[i]) != null) {
            for (name in options) {
              copy = options[name]; 

              if (name === "__proto__" || target === copy) {
                continue;
              } 


              if (deep && copy && (jQuery.isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
                src = target[name]; 

                if (copyIsArray && !Array.isArray(src)) {
                  clone = [];
                } else if (!copyIsArray && !jQuery.isPlainObject(src)) {
                  clone = {};
                } else {
                  clone = src;
                }

                copyIsArray = false; 

                target[name] = jQuery.extend(deep, clone, copy); 
              } else if (copy !== undefined) {
                target[name] = copy;
              }
            }
          }
        } 


        return target;
      };

      jQuery.extend({
        expando: "jQuery" + (version + Math.random()).replace(/\D/g, ""),
        isReady: true,
        error: function error(msg) {
          throw new Error(msg);
        },
        noop: function noop() {},
        isPlainObject: function isPlainObject(obj) {
          var proto, Ctor; 

          if (!obj || toString.call(obj) !== "[object Object]") {
            return false;
          }

          proto = getProto(obj); 

          if (!proto) {
            return true;
          } 


          Ctor = hasOwn.call(proto, "constructor") && proto.constructor;
          return typeof Ctor === "function" && fnToString.call(Ctor) === ObjectFunctionString;
        },
        isEmptyObject: function isEmptyObject(obj) {
          var name;

          for (name in obj) {
            return false;
          }

          return true;
        },
        globalEval: function globalEval(code, options, doc) {
          DOMEval(code, {
            nonce: options && options.nonce
          }, doc);
        },
        each: function each(obj, callback) {
          var length,
              i = 0;

          if (isArrayLike(obj)) {
            length = obj.length;

            for (; i < length; i++) {
              if (callback.call(obj[i], i, obj[i]) === false) {
                break;
              }
            }
          } else {
            for (i in obj) {
              if (callback.call(obj[i], i, obj[i]) === false) {
                break;
              }
            }
          }

          return obj;
        },
        makeArray: function makeArray(arr, results) {
          var ret = results || [];

          if (arr != null) {
            if (isArrayLike(Object(arr))) {
              jQuery.merge(ret, typeof arr === "string" ? [arr] : arr);
            } else {
              push.call(ret, arr);
            }
          }

          return ret;
        },
        inArray: function inArray(elem, arr, i) {
          return arr == null ? -1 : indexOf.call(arr, elem, i);
        },
        merge: function merge(first, second) {
          var len = +second.length,
              j = 0,
              i = first.length;

          for (; j < len; j++) {
            first[i++] = second[j];
          }

          first.length = i;
          return first;
        },
        grep: function grep(elems, callback, invert) {
          var callbackInverse,
              matches = [],
              i = 0,
              length = elems.length,
              callbackExpect = !invert; 

          for (; i < length; i++) {
            callbackInverse = !callback(elems[i], i);

            if (callbackInverse !== callbackExpect) {
              matches.push(elems[i]);
            }
          }

          return matches;
        },
        map: function map(elems, callback, arg) {
          var length,
              value,
              i = 0,
              ret = []; 

          if (isArrayLike(elems)) {
            length = elems.length;

            for (; i < length; i++) {
              value = callback(elems[i], i, arg);

              if (value != null) {
                ret.push(value);
              }
            } 

          } else {
            for (i in elems) {
              value = callback(elems[i], i, arg);

              if (value != null) {
                ret.push(value);
              }
            }
          } 


          return flat(ret);
        },
        guid: 1,
        support: support
      });

      if (typeof Symbol === "function") {
        jQuery.fn[Symbol.iterator] = arr[Symbol.iterator];
      } 


      jQuery.each("Boolean Number String Function Array Date RegExp Object Error Symbol".split(" "), function (_i, name) {
        class2type["[object " + name + "]"] = name.toLowerCase();
      });

      function isArrayLike(obj) {
        var length = !!obj && "length" in obj && obj.length,
            type = toType(obj);

        if (isFunction(obj) || isWindow(obj)) {
          return false;
        }

        return type === "array" || length === 0 || typeof length === "number" && length > 0 && length - 1 in obj;
      }

      var Sizzle =
      function (window) {
        var i,
            support,
            Expr,
            getText,
            isXML,
            tokenize,
            compile,
            select,
            outermostContext,
            sortInput,
            hasDuplicate,
        setDocument,
            document,
            docElem,
            documentIsHTML,
            rbuggyQSA,
            rbuggyMatches,
            matches,
            contains,
        expando = "sizzle" + 1 * new Date(),
            preferredDoc = window.document,
            dirruns = 0,
            done = 0,
            classCache = createCache(),
            tokenCache = createCache(),
            compilerCache = createCache(),
            nonnativeSelectorCache = createCache(),
            sortOrder = function sortOrder(a, b) {
          if (a === b) {
            hasDuplicate = true;
          }

          return 0;
        },
        hasOwn = {}.hasOwnProperty,
            arr = [],
            pop = arr.pop,
            pushNative = arr.push,
            push = arr.push,
            slice = arr.slice,
        indexOf = function indexOf(list, elem) {
          var i = 0,
              len = list.length;

          for (; i < len; i++) {
            if (list[i] === elem) {
              return i;
            }
          }

          return -1;
        },
            booleans = "checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|" + "ismap|loop|multiple|open|readonly|required|scoped",
        whitespace = "[\\x20\\t\\r\\n\\f]",
        identifier = "(?:\\\\[\\da-fA-F]{1,6}" + whitespace + "?|\\\\[^\\r\\n\\f]|[\\w-]|[^\0-\\x7f])+",
        attributes = "\\[" + whitespace + "*(" + identifier + ")(?:" + whitespace + 
        "*([*^$|!~]?=)" + whitespace + 
        "*(?:'((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\"|(" + identifier + "))|)" + whitespace + "*\\]",
            pseudos = ":(" + identifier + ")(?:\\((" + 
        "('((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\")|" + 
        "((?:\\\\.|[^\\\\()[\\]]|" + attributes + ")*)|" + 
        ".*" + ")\\)|)",
        rwhitespace = new RegExp(whitespace + "+", "g"),
            rtrim = new RegExp("^" + whitespace + "+|((?:^|[^\\\\])(?:\\\\.)*)" + whitespace + "+$", "g"),
            rcomma = new RegExp("^" + whitespace + "*," + whitespace + "*"),
            rcombinators = new RegExp("^" + whitespace + "*([>+~]|" + whitespace + ")" + whitespace + "*"),
            rdescend = new RegExp(whitespace + "|>"),
            rpseudo = new RegExp(pseudos),
            ridentifier = new RegExp("^" + identifier + "$"),
            matchExpr = {
          "ID": new RegExp("^#(" + identifier + ")"),
          "CLASS": new RegExp("^\\.(" + identifier + ")"),
          "TAG": new RegExp("^(" + identifier + "|[*])"),
          "ATTR": new RegExp("^" + attributes),
          "PSEUDO": new RegExp("^" + pseudos),
          "CHILD": new RegExp("^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(" + whitespace + "*(even|odd|(([+-]|)(\\d*)n|)" + whitespace + "*(?:([+-]|)" + whitespace + "*(\\d+)|))" + whitespace + "*\\)|)", "i"),
          "bool": new RegExp("^(?:" + booleans + ")$", "i"),
          "needsContext": new RegExp("^" + whitespace + "*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(" + whitespace + "*((?:-\\d)?\\d*)" + whitespace + "*\\)|)(?=[^-]|$)", "i")
        },
            rhtml = /HTML$/i,
            rinputs = /^(?:input|select|textarea|button)$/i,
            rheader = /^h\d$/i,
            rnative = /^[^{]+\{\s*\[native \w/,
        rquickExpr = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/,
            rsibling = /[+~]/,
        runescape = new RegExp("\\\\[\\da-fA-F]{1,6}" + whitespace + "?|\\\\([^\\r\\n\\f])", "g"),
            funescape = function funescape(escape, nonHex) {
          var high = "0x" + escape.slice(1) - 0x10000;
          return nonHex ? 
          nonHex : 
          high < 0 ? String.fromCharCode(high + 0x10000) : String.fromCharCode(high >> 10 | 0xD800, high & 0x3FF | 0xDC00);
        },
        rcssescape = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g,
            fcssescape = function fcssescape(ch, asCodePoint) {
          if (asCodePoint) {
            if (ch === "\0") {
              return "\uFFFD";
            } 


            return ch.slice(0, -1) + "\\" + ch.charCodeAt(ch.length - 1).toString(16) + " ";
          } 


          return "\\" + ch;
        },
        unloadHandler = function unloadHandler() {
          setDocument();
        },
            inDisabledFieldset = addCombinator(function (elem) {
          return elem.disabled === true && elem.nodeName.toLowerCase() === "fieldset";
        }, {
          dir: "parentNode",
          next: "legend"
        }); 


        try {
          push.apply(arr = slice.call(preferredDoc.childNodes), preferredDoc.childNodes); 

          arr[preferredDoc.childNodes.length].nodeType;
        } catch (e) {
          push = {
            apply: arr.length ? 
            function (target, els) {
              pushNative.apply(target, slice.call(els));
            } : 
            function (target, els) {
              var j = target.length,
                  i = 0; 

              while (target[j++] = els[i++]) {}

              target.length = j - 1;
            }
          };
        }

        function Sizzle(selector, context, results, seed) {
          var m,
              i,
              elem,
              nid,
              match,
              groups,
              newSelector,
              newContext = context && context.ownerDocument,
          nodeType = context ? context.nodeType : 9;
          results = results || []; 

          if (typeof selector !== "string" || !selector || nodeType !== 1 && nodeType !== 9 && nodeType !== 11) {
            return results;
          } 


          if (!seed) {
            setDocument(context);
            context = context || document;

            if (documentIsHTML) {
              if (nodeType !== 11 && (match = rquickExpr.exec(selector))) {
                if (m = match[1]) {
                  if (nodeType === 9) {
                    if (elem = context.getElementById(m)) {
                      if (elem.id === m) {
                        results.push(elem);
                        return results;
                      }
                    } else {
                      return results;
                    } 

                  } else {
                    if (newContext && (elem = newContext.getElementById(m)) && contains(context, elem) && elem.id === m) {
                      results.push(elem);
                      return results;
                    }
                  } 

                } else if (match[2]) {
                  push.apply(results, context.getElementsByTagName(selector));
                  return results; 
                } else if ((m = match[3]) && support.getElementsByClassName && context.getElementsByClassName) {
                  push.apply(results, context.getElementsByClassName(m));
                  return results;
                }
              } 


              if (support.qsa && !nonnativeSelectorCache[selector + " "] && (!rbuggyQSA || !rbuggyQSA.test(selector)) && ( 
              nodeType !== 1 || context.nodeName.toLowerCase() !== "object")) {
                newSelector = selector;
                newContext = context; 

                if (nodeType === 1 && (rdescend.test(selector) || rcombinators.test(selector))) {
                  newContext = rsibling.test(selector) && testContext(context.parentNode) || context; 

                  if (newContext !== context || !support.scope) {
                    if (nid = context.getAttribute("id")) {
                      nid = nid.replace(rcssescape, fcssescape);
                    } else {
                      context.setAttribute("id", nid = expando);
                    }
                  } 


                  groups = tokenize(selector);
                  i = groups.length;

                  while (i--) {
                    groups[i] = (nid ? "#" + nid : ":scope") + " " + toSelector(groups[i]);
                  }

                  newSelector = groups.join(",");
                }

                try {
                  push.apply(results, newContext.querySelectorAll(newSelector));
                  return results;
                } catch (qsaError) {
                  nonnativeSelectorCache(selector, true);
                } finally {
                  if (nid === expando) {
                    context.removeAttribute("id");
                  }
                }
              }
            }
          } 


          return select(selector.replace(rtrim, "$1"), context, results, seed);
        }
        /**
         * Create key-value caches of limited size
         * @returns {function(string, object)} Returns the Object data after storing it on itself with
         *	property name the (space-suffixed) string and (if the cache is larger than Expr.cacheLength)
         *	deleting the oldest entry
         */


        function createCache() {
          var keys = [];

          function cache(key, value) {
            if (keys.push(key + " ") > Expr.cacheLength) {
              delete cache[keys.shift()];
            }

            return cache[key + " "] = value;
          }

          return cache;
        }
        /**
         * Mark a function for special use by Sizzle
         * @param {Function} fn The function to mark
         */


        function markFunction(fn) {
          fn[expando] = true;
          return fn;
        }
        /**
         * Support testing using an element
         * @param {Function} fn Passed the created element and returns a boolean result
         */


        function assert(fn) {
          var el = document.createElement("fieldset");

          try {
            return !!fn(el);
          } catch (e) {
            return false;
          } finally {
            if (el.parentNode) {
              el.parentNode.removeChild(el);
            } 


            el = null;
          }
        }
        /**
         * Adds the same handler for all of the specified attrs
         * @param {String} attrs Pipe-separated list of attributes
         * @param {Function} handler The method that will be applied
         */


        function addHandle(attrs, handler) {
          var arr = attrs.split("|"),
              i = arr.length;

          while (i--) {
            Expr.attrHandle[arr[i]] = handler;
          }
        }
        /**
         * Checks document order of two siblings
         * @param {Element} a
         * @param {Element} b
         * @returns {Number} Returns less than 0 if a precedes b, greater than 0 if a follows b
         */


        function siblingCheck(a, b) {
          var cur = b && a,
              diff = cur && a.nodeType === 1 && b.nodeType === 1 && a.sourceIndex - b.sourceIndex; 

          if (diff) {
            return diff;
          } 


          if (cur) {
            while (cur = cur.nextSibling) {
              if (cur === b) {
                return -1;
              }
            }
          }

          return a ? 1 : -1;
        }
        /**
         * Returns a function to use in pseudos for input types
         * @param {String} type
         */


        function createInputPseudo(type) {
          return function (elem) {
            var name = elem.nodeName.toLowerCase();
            return name === "input" && elem.type === type;
          };
        }
        /**
         * Returns a function to use in pseudos for buttons
         * @param {String} type
         */


        function createButtonPseudo(type) {
          return function (elem) {
            var name = elem.nodeName.toLowerCase();
            return (name === "input" || name === "button") && elem.type === type;
          };
        }
        /**
         * Returns a function to use in pseudos for :enabled/:disabled
         * @param {Boolean} disabled true for :disabled; false for :enabled
         */


        function createDisabledPseudo(disabled) {
          return function (elem) {
            if ("form" in elem) {
              if (elem.parentNode && elem.disabled === false) {
                if ("label" in elem) {
                  if ("label" in elem.parentNode) {
                    return elem.parentNode.disabled === disabled;
                  } else {
                    return elem.disabled === disabled;
                  }
                } 


                return elem.isDisabled === disabled || 

                elem.isDisabled !== !disabled && inDisabledFieldset(elem) === disabled;
              }

              return elem.disabled === disabled; 
            } else if ("label" in elem) {
              return elem.disabled === disabled;
            } 


            return false;
          };
        }
        /**
         * Returns a function to use in pseudos for positionals
         * @param {Function} fn
         */


        function createPositionalPseudo(fn) {
          return markFunction(function (argument) {
            argument = +argument;
            return markFunction(function (seed, matches) {
              var j,
                  matchIndexes = fn([], seed.length, argument),
                  i = matchIndexes.length; 

              while (i--) {
                if (seed[j = matchIndexes[i]]) {
                  seed[j] = !(matches[j] = seed[j]);
                }
              }
            });
          });
        }
        /**
         * Checks a node for validity as a Sizzle context
         * @param {Element|Object=} context
         * @returns {Element|Object|Boolean} The input node if acceptable, otherwise a falsy value
         */


        function testContext(context) {
          return context && typeof context.getElementsByTagName !== "undefined" && context;
        } 


        support = Sizzle.support = {};
        /**
         * Detects XML nodes
         * @param {Element|Object} elem An element or a document
         * @returns {Boolean} True iff elem is a non-HTML XML node
         */

        isXML = Sizzle.isXML = function (elem) {
          var namespace = elem.namespaceURI,
              docElem = (elem.ownerDocument || elem).documentElement; 

          return !rhtml.test(namespace || docElem && docElem.nodeName || "HTML");
        };
        /**
         * Sets document-related variables once based on the current document
         * @param {Element|Object} [doc] An element or document object to use to set the document
         * @returns {Object} Returns the current document
         */


        setDocument = Sizzle.setDocument = function (node) {
          var hasCompare,
              subWindow,
              doc = node ? node.ownerDocument || node : preferredDoc; 

          if (doc == document || doc.nodeType !== 9 || !doc.documentElement) {
            return document;
          } 


          document = doc;
          docElem = document.documentElement;
          documentIsHTML = !isXML(document); 

          if (preferredDoc != document && (subWindow = document.defaultView) && subWindow.top !== subWindow) {
            if (subWindow.addEventListener) {
              subWindow.addEventListener("unload", unloadHandler, false); 
            } else if (subWindow.attachEvent) {
              subWindow.attachEvent("onunload", unloadHandler);
            }
          } 


          support.scope = assert(function (el) {
            docElem.appendChild(el).appendChild(document.createElement("div"));
            return typeof el.querySelectorAll !== "undefined" && !el.querySelectorAll(":scope fieldset div").length;
          });

          support.attributes = assert(function (el) {
            el.className = "i";
            return !el.getAttribute("className");
          });

          support.getElementsByTagName = assert(function (el) {
            el.appendChild(document.createComment(""));
            return !el.getElementsByTagName("*").length;
          }); 

          support.getElementsByClassName = rnative.test(document.getElementsByClassName); 

          support.getById = assert(function (el) {
            docElem.appendChild(el).id = expando;
            return !document.getElementsByName || !document.getElementsByName(expando).length;
          }); 

          if (support.getById) {
            Expr.filter["ID"] = function (id) {
              var attrId = id.replace(runescape, funescape);
              return function (elem) {
                return elem.getAttribute("id") === attrId;
              };
            };

            Expr.find["ID"] = function (id, context) {
              if (typeof context.getElementById !== "undefined" && documentIsHTML) {
                var elem = context.getElementById(id);
                return elem ? [elem] : [];
              }
            };
          } else {
            Expr.filter["ID"] = function (id) {
              var attrId = id.replace(runescape, funescape);
              return function (elem) {
                var node = typeof elem.getAttributeNode !== "undefined" && elem.getAttributeNode("id");
                return node && node.value === attrId;
              };
            }; 


            Expr.find["ID"] = function (id, context) {
              if (typeof context.getElementById !== "undefined" && documentIsHTML) {
                var node,
                    i,
                    elems,
                    elem = context.getElementById(id);

                if (elem) {
                  node = elem.getAttributeNode("id");

                  if (node && node.value === id) {
                    return [elem];
                  } 


                  elems = context.getElementsByName(id);
                  i = 0;

                  while (elem = elems[i++]) {
                    node = elem.getAttributeNode("id");

                    if (node && node.value === id) {
                      return [elem];
                    }
                  }
                }

                return [];
              }
            };
          } 


          Expr.find["TAG"] = support.getElementsByTagName ? function (tag, context) {
            if (typeof context.getElementsByTagName !== "undefined") {
              return context.getElementsByTagName(tag); 
            } else if (support.qsa) {
              return context.querySelectorAll(tag);
            }
          } : function (tag, context) {
            var elem,
                tmp = [],
                i = 0,
            results = context.getElementsByTagName(tag); 

            if (tag === "*") {
              while (elem = results[i++]) {
                if (elem.nodeType === 1) {
                  tmp.push(elem);
                }
              }

              return tmp;
            }

            return results;
          }; 

          Expr.find["CLASS"] = support.getElementsByClassName && function (className, context) {
            if (typeof context.getElementsByClassName !== "undefined" && documentIsHTML) {
              return context.getElementsByClassName(className);
            }
          };


          rbuggyMatches = []; 

          rbuggyQSA = [];

          if (support.qsa = rnative.test(document.querySelectorAll)) {
            assert(function (el) {
              var input; 

              docElem.appendChild(el).innerHTML = "<a id='" + expando + "'></a>" + "<select id='" + expando + "-\r\\' msallowcapture=''>" + "<option selected=''></option></select>"; 

              if (el.querySelectorAll("[msallowcapture^='']").length) {
                rbuggyQSA.push("[*^$]=" + whitespace + "*(?:''|\"\")");
              } 


              if (!el.querySelectorAll("[selected]").length) {
                rbuggyQSA.push("\\[" + whitespace + "*(?:value|" + booleans + ")");
              } 


              if (!el.querySelectorAll("[id~=" + expando + "-]").length) {
                rbuggyQSA.push("~=");
              } 


              input = document.createElement("input");
              input.setAttribute("name", "");
              el.appendChild(input);

              if (!el.querySelectorAll("[name='']").length) {
                rbuggyQSA.push("\\[" + whitespace + "*name" + whitespace + "*=" + whitespace + "*(?:''|\"\")");
              } 


              if (!el.querySelectorAll(":checked").length) {
                rbuggyQSA.push(":checked");
              } 


              if (!el.querySelectorAll("a#" + expando + "+*").length) {
                rbuggyQSA.push(".#.+[+~]");
              } 


              el.querySelectorAll("\\\f");
              rbuggyQSA.push("[\\r\\n\\f]");
            });
            assert(function (el) {
              el.innerHTML = "<a href='' disabled='disabled'></a>" + "<select disabled='disabled'><option/></select>"; 

              var input = document.createElement("input");
              input.setAttribute("type", "hidden");
              el.appendChild(input).setAttribute("name", "D"); 

              if (el.querySelectorAll("[name=d]").length) {
                rbuggyQSA.push("name" + whitespace + "*[*^$|!~]?=");
              } 


              if (el.querySelectorAll(":enabled").length !== 2) {
                rbuggyQSA.push(":enabled", ":disabled");
              } 


              docElem.appendChild(el).disabled = true;

              if (el.querySelectorAll(":disabled").length !== 2) {
                rbuggyQSA.push(":enabled", ":disabled");
              } 


              el.querySelectorAll("*,:x");
              rbuggyQSA.push(",.*:");
            });
          }

          if (support.matchesSelector = rnative.test(matches = docElem.matches || docElem.webkitMatchesSelector || docElem.mozMatchesSelector || docElem.oMatchesSelector || docElem.msMatchesSelector)) {
            assert(function (el) {
              support.disconnectedMatch = matches.call(el, "*"); 

              matches.call(el, "[s!='']:x");
              rbuggyMatches.push("!=", pseudos);
            });
          }

          rbuggyQSA = rbuggyQSA.length && new RegExp(rbuggyQSA.join("|"));
          rbuggyMatches = rbuggyMatches.length && new RegExp(rbuggyMatches.join("|"));

          hasCompare = rnative.test(docElem.compareDocumentPosition); 

          contains = hasCompare || rnative.test(docElem.contains) ? function (a, b) {
            var adown = a.nodeType === 9 ? a.documentElement : a,
                bup = b && b.parentNode;
            return a === bup || !!(bup && bup.nodeType === 1 && (adown.contains ? adown.contains(bup) : a.compareDocumentPosition && a.compareDocumentPosition(bup) & 16));
          } : function (a, b) {
            if (b) {
              while (b = b.parentNode) {
                if (b === a) {
                  return true;
                }
              }
            }

            return false;
          };

          sortOrder = hasCompare ? function (a, b) {
            if (a === b) {
              hasDuplicate = true;
              return 0;
            } 


            var compare = !a.compareDocumentPosition - !b.compareDocumentPosition;

            if (compare) {
              return compare;
            } 


            compare = (a.ownerDocument || a) == (b.ownerDocument || b) ? a.compareDocumentPosition(b) : 
            1; 

            if (compare & 1 || !support.sortDetached && b.compareDocumentPosition(a) === compare) {
              if (a == document || a.ownerDocument == preferredDoc && contains(preferredDoc, a)) {
                return -1;
              } 


              if (b == document || b.ownerDocument == preferredDoc && contains(preferredDoc, b)) {
                return 1;
              } 


              return sortInput ? indexOf(sortInput, a) - indexOf(sortInput, b) : 0;
            }

            return compare & 4 ? -1 : 1;
          } : function (a, b) {
            if (a === b) {
              hasDuplicate = true;
              return 0;
            }

            var cur,
                i = 0,
                aup = a.parentNode,
                bup = b.parentNode,
                ap = [a],
                bp = [b]; 

            if (!aup || !bup) {

              return a == document ? -1 : b == document ? 1 :
              aup ? -1 : bup ? 1 : sortInput ? indexOf(sortInput, a) - indexOf(sortInput, b) : 0; 
            } else if (aup === bup) {
              return siblingCheck(a, b);
            } 


            cur = a;

            while (cur = cur.parentNode) {
              ap.unshift(cur);
            }

            cur = b;

            while (cur = cur.parentNode) {
              bp.unshift(cur);
            } 


            while (ap[i] === bp[i]) {
              i++;
            }

            return i ? 
            siblingCheck(ap[i], bp[i]) : 

            ap[i] == preferredDoc ? -1 : bp[i] == preferredDoc ? 1 :
            0;
          };
          return document;
        };

        Sizzle.matches = function (expr, elements) {
          return Sizzle(expr, null, null, elements);
        };

        Sizzle.matchesSelector = function (elem, expr) {
          setDocument(elem);

          if (support.matchesSelector && documentIsHTML && !nonnativeSelectorCache[expr + " "] && (!rbuggyMatches || !rbuggyMatches.test(expr)) && (!rbuggyQSA || !rbuggyQSA.test(expr))) {
            try {
              var ret = matches.call(elem, expr); 

              if (ret || support.disconnectedMatch || 
              elem.document && elem.document.nodeType !== 11) {
                return ret;
              }
            } catch (e) {
              nonnativeSelectorCache(expr, true);
            }
          }

          return Sizzle(expr, document, null, [elem]).length > 0;
        };

        Sizzle.contains = function (context, elem) {
          if ((context.ownerDocument || context) != document) {
            setDocument(context);
          }

          return contains(context, elem);
        };

        Sizzle.attr = function (elem, name) {
          if ((elem.ownerDocument || elem) != document) {
            setDocument(elem);
          }

          var fn = Expr.attrHandle[name.toLowerCase()],
          val = fn && hasOwn.call(Expr.attrHandle, name.toLowerCase()) ? fn(elem, name, !documentIsHTML) : undefined;
          return val !== undefined ? val : support.attributes || !documentIsHTML ? elem.getAttribute(name) : (val = elem.getAttributeNode(name)) && val.specified ? val.value : null;
        };

        Sizzle.escape = function (sel) {
          return (sel + "").replace(rcssescape, fcssescape);
        };

        Sizzle.error = function (msg) {
          throw new Error("Syntax error, unrecognized expression: " + msg);
        };
        /**
         * Document sorting and removing duplicates
         * @param {ArrayLike} results
         */


        Sizzle.uniqueSort = function (results) {
          var elem,
              duplicates = [],
              j = 0,
              i = 0; 

          hasDuplicate = !support.detectDuplicates;
          sortInput = !support.sortStable && results.slice(0);
          results.sort(sortOrder);

          if (hasDuplicate) {
            while (elem = results[i++]) {
              if (elem === results[i]) {
                j = duplicates.push(i);
              }
            }

            while (j--) {
              results.splice(duplicates[j], 1);
            }
          } 


          sortInput = null;
          return results;
        };
        /**
         * Utility function for retrieving the text value of an array of DOM nodes
         * @param {Array|Element} elem
         */


        getText = Sizzle.getText = function (elem) {
          var node,
              ret = "",
              i = 0,
              nodeType = elem.nodeType;

          if (!nodeType) {
            while (node = elem[i++]) {
              ret += getText(node);
            }
          } else if (nodeType === 1 || nodeType === 9 || nodeType === 11) {
            if (typeof elem.textContent === "string") {
              return elem.textContent;
            } else {
              for (elem = elem.firstChild; elem; elem = elem.nextSibling) {
                ret += getText(elem);
              }
            }
          } else if (nodeType === 3 || nodeType === 4) {
            return elem.nodeValue;
          } 


          return ret;
        };

        Expr = Sizzle.selectors = {
          cacheLength: 50,
          createPseudo: markFunction,
          match: matchExpr,
          attrHandle: {},
          find: {},
          relative: {
            ">": {
              dir: "parentNode",
              first: true
            },
            " ": {
              dir: "parentNode"
            },
            "+": {
              dir: "previousSibling",
              first: true
            },
            "~": {
              dir: "previousSibling"
            }
          },
          preFilter: {
            "ATTR": function ATTR(match) {
              match[1] = match[1].replace(runescape, funescape); 

              match[3] = (match[3] || match[4] || match[5] || "").replace(runescape, funescape);

              if (match[2] === "~=") {
                match[3] = " " + match[3] + " ";
              }

              return match.slice(0, 4);
            },
            "CHILD": function CHILD(match) {
              match[1] = match[1].toLowerCase();

              if (match[1].slice(0, 3) === "nth") {
                if (!match[3]) {
                  Sizzle.error(match[0]);
                } 


                match[4] = +(match[4] ? match[5] + (match[6] || 1) : 2 * (match[3] === "even" || match[3] === "odd"));
                match[5] = +(match[7] + match[8] || match[3] === "odd"); 
              } else if (match[3]) {
                Sizzle.error(match[0]);
              }

              return match;
            },
            "PSEUDO": function PSEUDO(match) {
              var excess,
                  unquoted = !match[6] && match[2];

              if (matchExpr["CHILD"].test(match[0])) {
                return null;
              } 


              if (match[3]) {
                match[2] = match[4] || match[5] || ""; 
              } else if (unquoted && rpseudo.test(unquoted) && ( 
              excess = tokenize(unquoted, true)) && ( 
              excess = unquoted.indexOf(")", unquoted.length - excess) - unquoted.length)) {
                match[0] = match[0].slice(0, excess);
                match[2] = unquoted.slice(0, excess);
              } 


              return match.slice(0, 3);
            }
          },
          filter: {
            "TAG": function TAG(nodeNameSelector) {
              var nodeName = nodeNameSelector.replace(runescape, funescape).toLowerCase();
              return nodeNameSelector === "*" ? function () {
                return true;
              } : function (elem) {
                return elem.nodeName && elem.nodeName.toLowerCase() === nodeName;
              };
            },
            "CLASS": function CLASS(className) {
              var pattern = classCache[className + " "];
              return pattern || (pattern = new RegExp("(^|" + whitespace + ")" + className + "(" + whitespace + "|$)")) && classCache(className, function (elem) {
                return pattern.test(typeof elem.className === "string" && elem.className || typeof elem.getAttribute !== "undefined" && elem.getAttribute("class") || "");
              });
            },
            "ATTR": function ATTR(name, operator, check) {
              return function (elem) {
                var result = Sizzle.attr(elem, name);

                if (result == null) {
                  return operator === "!=";
                }

                if (!operator) {
                  return true;
                }

                result += "";

                return operator === "=" ? result === check : operator === "!=" ? result !== check : operator === "^=" ? check && result.indexOf(check) === 0 : operator === "*=" ? check && result.indexOf(check) > -1 : operator === "$=" ? check && result.slice(-check.length) === check : operator === "~=" ? (" " + result.replace(rwhitespace, " ") + " ").indexOf(check) > -1 : operator === "|=" ? result === check || result.slice(0, check.length + 1) === check + "-" : false;
              };
            },
            "CHILD": function CHILD(type, what, _argument, first, last) {
              var simple = type.slice(0, 3) !== "nth",
                  forward = type.slice(-4) !== "last",
                  ofType = what === "of-type";
              return first === 1 && last === 0 ? 
              function (elem) {
                return !!elem.parentNode;
              } : function (elem, _context, xml) {
                var cache,
                    uniqueCache,
                    outerCache,
                    node,
                    nodeIndex,
                    start,
                    dir = simple !== forward ? "nextSibling" : "previousSibling",
                    parent = elem.parentNode,
                    name = ofType && elem.nodeName.toLowerCase(),
                    useCache = !xml && !ofType,
                    diff = false;

                if (parent) {
                  if (simple) {
                    while (dir) {
                      node = elem;

                      while (node = node[dir]) {
                        if (ofType ? node.nodeName.toLowerCase() === name : node.nodeType === 1) {
                          return false;
                        }
                      } 


                      start = dir = type === "only" && !start && "nextSibling";
                    }

                    return true;
                  }

                  start = [forward ? parent.firstChild : parent.lastChild]; 

                  if (forward && useCache) {
                    node = parent;
                    outerCache = node[expando] || (node[expando] = {}); 

                    uniqueCache = outerCache[node.uniqueID] || (outerCache[node.uniqueID] = {});
                    cache = uniqueCache[type] || [];
                    nodeIndex = cache[0] === dirruns && cache[1];
                    diff = nodeIndex && cache[2];
                    node = nodeIndex && parent.childNodes[nodeIndex];

                    while (node = ++nodeIndex && node && node[dir] || ( 
                    diff = nodeIndex = 0) || start.pop()) {
                      if (node.nodeType === 1 && ++diff && node === elem) {
                        uniqueCache[type] = [dirruns, nodeIndex, diff];
                        break;
                      }
                    }
                  } else {
                    if (useCache) {
                      node = elem;
                      outerCache = node[expando] || (node[expando] = {}); 

                      uniqueCache = outerCache[node.uniqueID] || (outerCache[node.uniqueID] = {});
                      cache = uniqueCache[type] || [];
                      nodeIndex = cache[0] === dirruns && cache[1];
                      diff = nodeIndex;
                    } 


                    if (diff === false) {
                      while (node = ++nodeIndex && node && node[dir] || (diff = nodeIndex = 0) || start.pop()) {
                        if ((ofType ? node.nodeName.toLowerCase() === name : node.nodeType === 1) && ++diff) {
                          if (useCache) {
                            outerCache = node[expando] || (node[expando] = {}); 

                            uniqueCache = outerCache[node.uniqueID] || (outerCache[node.uniqueID] = {});
                            uniqueCache[type] = [dirruns, diff];
                          }

                          if (node === elem) {
                            break;
                          }
                        }
                      }
                    }
                  } 


                  diff -= last;
                  return diff === first || diff % first === 0 && diff / first >= 0;
                }
              };
            },
            "PSEUDO": function PSEUDO(pseudo, argument) {
              var args,
                  fn = Expr.pseudos[pseudo] || Expr.setFilters[pseudo.toLowerCase()] || Sizzle.error("unsupported pseudo: " + pseudo); 

              if (fn[expando]) {
                return fn(argument);
              } 


              if (fn.length > 1) {
                args = [pseudo, pseudo, "", argument];
                return Expr.setFilters.hasOwnProperty(pseudo.toLowerCase()) ? markFunction(function (seed, matches) {
                  var idx,
                      matched = fn(seed, argument),
                      i = matched.length;

                  while (i--) {
                    idx = indexOf(seed, matched[i]);
                    seed[idx] = !(matches[idx] = matched[i]);
                  }
                }) : function (elem) {
                  return fn(elem, 0, args);
                };
              }

              return fn;
            }
          },
          pseudos: {
            "not": markFunction(function (selector) {
              var input = [],
                  results = [],
                  matcher = compile(selector.replace(rtrim, "$1"));
              return matcher[expando] ? markFunction(function (seed, matches, _context, xml) {
                var elem,
                    unmatched = matcher(seed, null, xml, []),
                    i = seed.length; 

                while (i--) {
                  if (elem = unmatched[i]) {
                    seed[i] = !(matches[i] = elem);
                  }
                }
              }) : function (elem, _context, xml) {
                input[0] = elem;
                matcher(input, null, xml, results); 

                input[0] = null;
                return !results.pop();
              };
            }),
            "has": markFunction(function (selector) {
              return function (elem) {
                return Sizzle(selector, elem).length > 0;
              };
            }),
            "contains": markFunction(function (text) {
              text = text.replace(runescape, funescape);
              return function (elem) {
                return (elem.textContent || getText(elem)).indexOf(text) > -1;
              };
            }),
            "lang": markFunction(function (lang) {
              if (!ridentifier.test(lang || "")) {
                Sizzle.error("unsupported lang: " + lang);
              }

              lang = lang.replace(runescape, funescape).toLowerCase();
              return function (elem) {
                var elemLang;

                do {
                  if (elemLang = documentIsHTML ? elem.lang : elem.getAttribute("xml:lang") || elem.getAttribute("lang")) {
                    elemLang = elemLang.toLowerCase();
                    return elemLang === lang || elemLang.indexOf(lang + "-") === 0;
                  }
                } while ((elem = elem.parentNode) && elem.nodeType === 1);

                return false;
              };
            }),
            "target": function target(elem) {
              var hash = window.location && window.location.hash;
              return hash && hash.slice(1) === elem.id;
            },
            "root": function root(elem) {
              return elem === docElem;
            },
            "focus": function focus(elem) {
              return elem === document.activeElement && (!document.hasFocus || document.hasFocus()) && !!(elem.type || elem.href || ~elem.tabIndex);
            },
            "enabled": createDisabledPseudo(false),
            "disabled": createDisabledPseudo(true),
            "checked": function checked(elem) {
              var nodeName = elem.nodeName.toLowerCase();
              return nodeName === "input" && !!elem.checked || nodeName === "option" && !!elem.selected;
            },
            "selected": function selected(elem) {
              if (elem.parentNode) {
                elem.parentNode.selectedIndex;
              }

              return elem.selected === true;
            },
            "empty": function empty(elem) {
              for (elem = elem.firstChild; elem; elem = elem.nextSibling) {
                if (elem.nodeType < 6) {
                  return false;
                }
              }

              return true;
            },
            "parent": function parent(elem) {
              return !Expr.pseudos["empty"](elem);
            },
            "header": function header(elem) {
              return rheader.test(elem.nodeName);
            },
            "input": function input(elem) {
              return rinputs.test(elem.nodeName);
            },
            "button": function button(elem) {
              var name = elem.nodeName.toLowerCase();
              return name === "input" && elem.type === "button" || name === "button";
            },
            "text": function text(elem) {
              var attr;
              return elem.nodeName.toLowerCase() === "input" && elem.type === "text" && ( 
              (attr = elem.getAttribute("type")) == null || attr.toLowerCase() === "text");
            },
            "first": createPositionalPseudo(function () {
              return [0];
            }),
            "last": createPositionalPseudo(function (_matchIndexes, length) {
              return [length - 1];
            }),
            "eq": createPositionalPseudo(function (_matchIndexes, length, argument) {
              return [argument < 0 ? argument + length : argument];
            }),
            "even": createPositionalPseudo(function (matchIndexes, length) {
              var i = 0;

              for (; i < length; i += 2) {
                matchIndexes.push(i);
              }

              return matchIndexes;
            }),
            "odd": createPositionalPseudo(function (matchIndexes, length) {
              var i = 1;

              for (; i < length; i += 2) {
                matchIndexes.push(i);
              }

              return matchIndexes;
            }),
            "lt": createPositionalPseudo(function (matchIndexes, length, argument) {
              var i = argument < 0 ? argument + length : argument > length ? length : argument;

              for (; --i >= 0;) {
                matchIndexes.push(i);
              }

              return matchIndexes;
            }),
            "gt": createPositionalPseudo(function (matchIndexes, length, argument) {
              var i = argument < 0 ? argument + length : argument;

              for (; ++i < length;) {
                matchIndexes.push(i);
              }

              return matchIndexes;
            })
          }
        };
        Expr.pseudos["nth"] = Expr.pseudos["eq"]; 

        for (i in {
          radio: true,
          checkbox: true,
          file: true,
          password: true,
          image: true
        }) {
          Expr.pseudos[i] = createInputPseudo(i);
        }

        for (i in {
          submit: true,
          reset: true
        }) {
          Expr.pseudos[i] = createButtonPseudo(i);
        } 


        function setFilters() {}

        setFilters.prototype = Expr.filters = Expr.pseudos;
        Expr.setFilters = new setFilters();

        tokenize = Sizzle.tokenize = function (selector, parseOnly) {
          var matched,
              match,
              tokens,
              type,
              soFar,
              groups,
              preFilters,
              cached = tokenCache[selector + " "];

          if (cached) {
            return parseOnly ? 0 : cached.slice(0);
          }

          soFar = selector;
          groups = [];
          preFilters = Expr.preFilter;

          while (soFar) {
            if (!matched || (match = rcomma.exec(soFar))) {
              if (match) {
                soFar = soFar.slice(match[0].length) || soFar;
              }

              groups.push(tokens = []);
            }

            matched = false; 

            if (match = rcombinators.exec(soFar)) {
              matched = match.shift();
              tokens.push({
                value: matched,
                type: match[0].replace(rtrim, " ")
              });
              soFar = soFar.slice(matched.length);
            } 


            for (type in Expr.filter) {
              if ((match = matchExpr[type].exec(soFar)) && (!preFilters[type] || (match = preFilters[type](match)))) {
                matched = match.shift();
                tokens.push({
                  value: matched,
                  type: type,
                  matches: match
                });
                soFar = soFar.slice(matched.length);
              }
            }

            if (!matched) {
              break;
            }
          } 


          return parseOnly ? soFar.length : soFar ? Sizzle.error(selector) : 
          tokenCache(selector, groups).slice(0);
        };

        function toSelector(tokens) {
          var i = 0,
              len = tokens.length,
              selector = "";

          for (; i < len; i++) {
            selector += tokens[i].value;
          }

          return selector;
        }

        function addCombinator(matcher, combinator, base) {
          var dir = combinator.dir,
              skip = combinator.next,
              key = skip || dir,
              checkNonElements = base && key === "parentNode",
              doneName = done++;
          return combinator.first ? 
          function (elem, context, xml) {
            while (elem = elem[dir]) {
              if (elem.nodeType === 1 || checkNonElements) {
                return matcher(elem, context, xml);
              }
            }

            return false;
          } : 
          function (elem, context, xml) {
            var oldCache,
                uniqueCache,
                outerCache,
                newCache = [dirruns, doneName]; 

            if (xml) {
              while (elem = elem[dir]) {
                if (elem.nodeType === 1 || checkNonElements) {
                  if (matcher(elem, context, xml)) {
                    return true;
                  }
                }
              }
            } else {
              while (elem = elem[dir]) {
                if (elem.nodeType === 1 || checkNonElements) {
                  outerCache = elem[expando] || (elem[expando] = {}); 

                  uniqueCache = outerCache[elem.uniqueID] || (outerCache[elem.uniqueID] = {});

                  if (skip && skip === elem.nodeName.toLowerCase()) {
                    elem = elem[dir] || elem;
                  } else if ((oldCache = uniqueCache[key]) && oldCache[0] === dirruns && oldCache[1] === doneName) {
                    return newCache[2] = oldCache[2];
                  } else {
                    uniqueCache[key] = newCache; 

                    if (newCache[2] = matcher(elem, context, xml)) {
                      return true;
                    }
                  }
                }
              }
            }

            return false;
          };
        }

        function elementMatcher(matchers) {
          return matchers.length > 1 ? function (elem, context, xml) {
            var i = matchers.length;

            while (i--) {
              if (!matchers[i](elem, context, xml)) {
                return false;
              }
            }

            return true;
          } : matchers[0];
        }

        function multipleContexts(selector, contexts, results) {
          var i = 0,
              len = contexts.length;

          for (; i < len; i++) {
            Sizzle(selector, contexts[i], results);
          }

          return results;
        }

        function condense(unmatched, map, filter, context, xml) {
          var elem,
              newUnmatched = [],
              i = 0,
              len = unmatched.length,
              mapped = map != null;

          for (; i < len; i++) {
            if (elem = unmatched[i]) {
              if (!filter || filter(elem, context, xml)) {
                newUnmatched.push(elem);

                if (mapped) {
                  map.push(i);
                }
              }
            }
          }

          return newUnmatched;
        }

        function setMatcher(preFilter, selector, matcher, postFilter, postFinder, postSelector) {
          if (postFilter && !postFilter[expando]) {
            postFilter = setMatcher(postFilter);
          }

          if (postFinder && !postFinder[expando]) {
            postFinder = setMatcher(postFinder, postSelector);
          }

          return markFunction(function (seed, results, context, xml) {
            var temp,
                i,
                elem,
                preMap = [],
                postMap = [],
                preexisting = results.length,
            elems = seed || multipleContexts(selector || "*", context.nodeType ? [context] : context, []),
            matcherIn = preFilter && (seed || !selector) ? condense(elems, preMap, preFilter, context, xml) : elems,
                matcherOut = matcher ? 
            postFinder || (seed ? preFilter : preexisting || postFilter) ? 
            [] : 
            results : matcherIn; 

            if (matcher) {
              matcher(matcherIn, matcherOut, context, xml);
            } 


            if (postFilter) {
              temp = condense(matcherOut, postMap);
              postFilter(temp, [], context, xml); 

              i = temp.length;

              while (i--) {
                if (elem = temp[i]) {
                  matcherOut[postMap[i]] = !(matcherIn[postMap[i]] = elem);
                }
              }
            }

            if (seed) {
              if (postFinder || preFilter) {
                if (postFinder) {
                  temp = [];
                  i = matcherOut.length;

                  while (i--) {
                    if (elem = matcherOut[i]) {
                      temp.push(matcherIn[i] = elem);
                    }
                  }

                  postFinder(null, matcherOut = [], temp, xml);
                } 


                i = matcherOut.length;

                while (i--) {
                  if ((elem = matcherOut[i]) && (temp = postFinder ? indexOf(seed, elem) : preMap[i]) > -1) {
                    seed[temp] = !(results[temp] = elem);
                  }
                }
              } 

            } else {
              matcherOut = condense(matcherOut === results ? matcherOut.splice(preexisting, matcherOut.length) : matcherOut);

              if (postFinder) {
                postFinder(null, results, matcherOut, xml);
              } else {
                push.apply(results, matcherOut);
              }
            }
          });
        }

        function matcherFromTokens(tokens) {
          var checkContext,
              matcher,
              j,
              len = tokens.length,
              leadingRelative = Expr.relative[tokens[0].type],
              implicitRelative = leadingRelative || Expr.relative[" "],
              i = leadingRelative ? 1 : 0,
          matchContext = addCombinator(function (elem) {
            return elem === checkContext;
          }, implicitRelative, true),
              matchAnyContext = addCombinator(function (elem) {
            return indexOf(checkContext, elem) > -1;
          }, implicitRelative, true),
              matchers = [function (elem, context, xml) {
            var ret = !leadingRelative && (xml || context !== outermostContext) || ((checkContext = context).nodeType ? matchContext(elem, context, xml) : matchAnyContext(elem, context, xml)); 

            checkContext = null;
            return ret;
          }];

          for (; i < len; i++) {
            if (matcher = Expr.relative[tokens[i].type]) {
              matchers = [addCombinator(elementMatcher(matchers), matcher)];
            } else {
              matcher = Expr.filter[tokens[i].type].apply(null, tokens[i].matches); 

              if (matcher[expando]) {
                j = ++i;

                for (; j < len; j++) {
                  if (Expr.relative[tokens[j].type]) {
                    break;
                  }
                }

                return setMatcher(i > 1 && elementMatcher(matchers), i > 1 && toSelector( 
                tokens.slice(0, i - 1).concat({
                  value: tokens[i - 2].type === " " ? "*" : ""
                })).replace(rtrim, "$1"), matcher, i < j && matcherFromTokens(tokens.slice(i, j)), j < len && matcherFromTokens(tokens = tokens.slice(j)), j < len && toSelector(tokens));
              }

              matchers.push(matcher);
            }
          }

          return elementMatcher(matchers);
        }

        function matcherFromGroupMatchers(elementMatchers, setMatchers) {
          var bySet = setMatchers.length > 0,
              byElement = elementMatchers.length > 0,
              superMatcher = function superMatcher(seed, context, xml, results, outermost) {
            var elem,
                j,
                matcher,
                matchedCount = 0,
                i = "0",
                unmatched = seed && [],
                setMatched = [],
                contextBackup = outermostContext,
            elems = seed || byElement && Expr.find["TAG"]("*", outermost),
            dirrunsUnique = dirruns += contextBackup == null ? 1 : Math.random() || 0.1,
                len = elems.length;

            if (outermost) {
              outermostContext = context == document || context || outermost;
            } 


            for (; i !== len && (elem = elems[i]) != null; i++) {
              if (byElement && elem) {
                j = 0; 

                if (!context && elem.ownerDocument != document) {
                  setDocument(elem);
                  xml = !documentIsHTML;
                }

                while (matcher = elementMatchers[j++]) {
                  if (matcher(elem, context || document, xml)) {
                    results.push(elem);
                    break;
                  }
                }

                if (outermost) {
                  dirruns = dirrunsUnique;
                }
              } 


              if (bySet) {
                if (elem = !matcher && elem) {
                  matchedCount--;
                } 


                if (seed) {
                  unmatched.push(elem);
                }
              }
            } 


            matchedCount += i; 

            if (bySet && i !== matchedCount) {
              j = 0;

              while (matcher = setMatchers[j++]) {
                matcher(unmatched, setMatched, context, xml);
              }

              if (seed) {
                if (matchedCount > 0) {
                  while (i--) {
                    if (!(unmatched[i] || setMatched[i])) {
                      setMatched[i] = pop.call(results);
                    }
                  }
                } 


                setMatched = condense(setMatched);
              } 


              push.apply(results, setMatched); 

              if (outermost && !seed && setMatched.length > 0 && matchedCount + setMatchers.length > 1) {
                Sizzle.uniqueSort(results);
              }
            } 


            if (outermost) {
              dirruns = dirrunsUnique;
              outermostContext = contextBackup;
            }

            return unmatched;
          };

          return bySet ? markFunction(superMatcher) : superMatcher;
        }

        compile = Sizzle.compile = function (selector, match
        ) {
          var i,
              setMatchers = [],
              elementMatchers = [],
              cached = compilerCache[selector + " "];

          if (!cached) {
            if (!match) {
              match = tokenize(selector);
            }

            i = match.length;

            while (i--) {
              cached = matcherFromTokens(match[i]);

              if (cached[expando]) {
                setMatchers.push(cached);
              } else {
                elementMatchers.push(cached);
              }
            } 


            cached = compilerCache(selector, matcherFromGroupMatchers(elementMatchers, setMatchers)); 

            cached.selector = selector;
          }

          return cached;
        };
        /**
         * A low-level selection function that works with Sizzle's compiled
         *  selector functions
         * @param {String|Function} selector A selector or a pre-compiled
         *  selector function built with Sizzle.compile
         * @param {Element} context
         * @param {Array} [results]
         * @param {Array} [seed] A set of elements to match against
         */


        select = Sizzle.select = function (selector, context, results, seed) {
          var i,
              tokens,
              token,
              type,
              find,
              compiled = typeof selector === "function" && selector,
              match = !seed && tokenize(selector = compiled.selector || selector);
          results = results || []; 

          if (match.length === 1) {
            tokens = match[0] = match[0].slice(0);

            if (tokens.length > 2 && (token = tokens[0]).type === "ID" && context.nodeType === 9 && documentIsHTML && Expr.relative[tokens[1].type]) {
              context = (Expr.find["ID"](token.matches[0].replace(runescape, funescape), context) || [])[0];

              if (!context) {
                return results; 
              } else if (compiled) {
                context = context.parentNode;
              }

              selector = selector.slice(tokens.shift().value.length);
            } 


            i = matchExpr["needsContext"].test(selector) ? 0 : tokens.length;

            while (i--) {
              token = tokens[i]; 

              if (Expr.relative[type = token.type]) {
                break;
              }

              if (find = Expr.find[type]) {
                if (seed = find(token.matches[0].replace(runescape, funescape), rsibling.test(tokens[0].type) && testContext(context.parentNode) || context)) {
                  tokens.splice(i, 1);
                  selector = seed.length && toSelector(tokens);

                  if (!selector) {
                    push.apply(results, seed);
                    return results;
                  }

                  break;
                }
              }
            }
          } 


          (compiled || compile(selector, match))(seed, context, !documentIsHTML, results, !context || rsibling.test(selector) && testContext(context.parentNode) || context);
          return results;
        }; 


        support.sortStable = expando.split("").sort(sortOrder).join("") === expando; 

        support.detectDuplicates = !!hasDuplicate; 

        setDocument(); 

        support.sortDetached = assert(function (el) {
          return el.compareDocumentPosition(document.createElement("fieldset")) & 1;
        }); 

        if (!assert(function (el) {
          el.innerHTML = "<a href='#'></a>";
          return el.firstChild.getAttribute("href") === "#";
        })) {
          addHandle("type|href|height|width", function (elem, name, isXML) {
            if (!isXML) {
              return elem.getAttribute(name, name.toLowerCase() === "type" ? 1 : 2);
            }
          });
        } 


        if (!support.attributes || !assert(function (el) {
          el.innerHTML = "<input/>";
          el.firstChild.setAttribute("value", "");
          return el.firstChild.getAttribute("value") === "";
        })) {
          addHandle("value", function (elem, _name, isXML) {
            if (!isXML && elem.nodeName.toLowerCase() === "input") {
              return elem.defaultValue;
            }
          });
        } 


        if (!assert(function (el) {
          return el.getAttribute("disabled") == null;
        })) {
          addHandle(booleans, function (elem, name, isXML) {
            var val;

            if (!isXML) {
              return elem[name] === true ? name.toLowerCase() : (val = elem.getAttributeNode(name)) && val.specified ? val.value : null;
            }
          });
        }

        return Sizzle;
      }(window);

      jQuery.find = Sizzle;
      jQuery.expr = Sizzle.selectors; 

      jQuery.expr[":"] = jQuery.expr.pseudos;
      jQuery.uniqueSort = jQuery.unique = Sizzle.uniqueSort;
      jQuery.text = Sizzle.getText;
      jQuery.isXMLDoc = Sizzle.isXML;
      jQuery.contains = Sizzle.contains;
      jQuery.escapeSelector = Sizzle.escape;

      var dir = function dir(elem, _dir, until) {
        var matched = [],
            truncate = until !== undefined;

        while ((elem = elem[_dir]) && elem.nodeType !== 9) {
          if (elem.nodeType === 1) {
            if (truncate && jQuery(elem).is(until)) {
              break;
            }

            matched.push(elem);
          }
        }

        return matched;
      };

      var _siblings = function siblings(n, elem) {
        var matched = [];

        for (; n; n = n.nextSibling) {
          if (n.nodeType === 1 && n !== elem) {
            matched.push(n);
          }
        }

        return matched;
      };

      var rneedsContext = jQuery.expr.match.needsContext;

      function nodeName(elem, name) {
        return elem.nodeName && elem.nodeName.toLowerCase() === name.toLowerCase();
      }

      ;
      var rsingleTag = /^<([a-z][^\/\0>:\x20\t\r\n\f]*)[\x20\t\r\n\f]*\/?>(?:<\/\1>|)$/i; 

      function winnow(elements, qualifier, not) {
        if (isFunction(qualifier)) {
          return jQuery.grep(elements, function (elem, i) {
            return !!qualifier.call(elem, i, elem) !== not;
          });
        } 


        if (qualifier.nodeType) {
          return jQuery.grep(elements, function (elem) {
            return elem === qualifier !== not;
          });
        } 


        if (typeof qualifier !== "string") {
          return jQuery.grep(elements, function (elem) {
            return indexOf.call(qualifier, elem) > -1 !== not;
          });
        } 


        return jQuery.filter(qualifier, elements, not);
      }

      jQuery.filter = function (expr, elems, not) {
        var elem = elems[0];

        if (not) {
          expr = ":not(" + expr + ")";
        }

        if (elems.length === 1 && elem.nodeType === 1) {
          return jQuery.find.matchesSelector(elem, expr) ? [elem] : [];
        }

        return jQuery.find.matches(expr, jQuery.grep(elems, function (elem) {
          return elem.nodeType === 1;
        }));
      };

      jQuery.fn.extend({
        find: function find(selector) {
          var i,
              ret,
              len = this.length,
              self = this;

          if (typeof selector !== "string") {
            return this.pushStack(jQuery(selector).filter(function () {
              for (i = 0; i < len; i++) {
                if (jQuery.contains(self[i], this)) {
                  return true;
                }
              }
            }));
          }

          ret = this.pushStack([]);

          for (i = 0; i < len; i++) {
            jQuery.find(selector, self[i], ret);
          }

          return len > 1 ? jQuery.uniqueSort(ret) : ret;
        },
        filter: function filter(selector) {
          return this.pushStack(winnow(this, selector || [], false));
        },
        not: function not(selector) {
          return this.pushStack(winnow(this, selector || [], true));
        },
        is: function is(selector) {
          return !!winnow(this, 
          typeof selector === "string" && rneedsContext.test(selector) ? jQuery(selector) : selector || [], false).length;
        }
      }); 

      var rootjQuery,
      rquickExpr = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]+))$/,
          init = jQuery.fn.init = function (selector, context, root) {
        var match, elem; 

        if (!selector) {
          return this;
        } 


        root = root || rootjQuery; 

        if (typeof selector === "string") {
          if (selector[0] === "<" && selector[selector.length - 1] === ">" && selector.length >= 3) {
            match = [null, selector, null];
          } else {
            match = rquickExpr.exec(selector);
          } 


          if (match && (match[1] || !context)) {
            if (match[1]) {
              context = context instanceof jQuery ? context[0] : context; 

              jQuery.merge(this, jQuery.parseHTML(match[1], context && context.nodeType ? context.ownerDocument || context : document, true)); 

              if (rsingleTag.test(match[1]) && jQuery.isPlainObject(context)) {
                for (match in context) {
                  if (isFunction(this[match])) {
                    this[match](context[match]); 
                  } else {
                    this.attr(match, context[match]);
                  }
                }
              }

              return this; 
            } else {
              elem = document.getElementById(match[2]);

              if (elem) {
                this[0] = elem;
                this.length = 1;
              }

              return this;
            } 

          } else if (!context || context.jquery) {
            return (context || root).find(selector); 
          } else {
            return this.constructor(context).find(selector);
          } 

        } else if (selector.nodeType) {
          this[0] = selector;
          this.length = 1;
          return this; 
        } else if (isFunction(selector)) {
          return root.ready !== undefined ? root.ready(selector) : 
          selector(jQuery);
        }

        return jQuery.makeArray(selector, this);
      }; 


      init.prototype = jQuery.fn; 

      rootjQuery = jQuery(document);
      var rparentsprev = /^(?:parents|prev(?:Until|All))/,
      guaranteedUnique = {
        children: true,
        contents: true,
        next: true,
        prev: true
      };
      jQuery.fn.extend({
        has: function has(target) {
          var targets = jQuery(target, this),
              l = targets.length;
          return this.filter(function () {
            var i = 0;

            for (; i < l; i++) {
              if (jQuery.contains(this, targets[i])) {
                return true;
              }
            }
          });
        },
        closest: function closest(selectors, context) {
          var cur,
              i = 0,
              l = this.length,
              matched = [],
              targets = typeof selectors !== "string" && jQuery(selectors); 

          if (!rneedsContext.test(selectors)) {
            for (; i < l; i++) {
              for (cur = this[i]; cur && cur !== context; cur = cur.parentNode) {
                if (cur.nodeType < 11 && (targets ? targets.index(cur) > -1 : 
                cur.nodeType === 1 && jQuery.find.matchesSelector(cur, selectors))) {
                  matched.push(cur);
                  break;
                }
              }
            }
          }

          return this.pushStack(matched.length > 1 ? jQuery.uniqueSort(matched) : matched);
        },
        index: function index(elem) {
          if (!elem) {
            return this[0] && this[0].parentNode ? this.first().prevAll().length : -1;
          } 


          if (typeof elem === "string") {
            return indexOf.call(jQuery(elem), this[0]);
          } 


          return indexOf.call(this, 
          elem.jquery ? elem[0] : elem);
        },
        add: function add(selector, context) {
          return this.pushStack(jQuery.uniqueSort(jQuery.merge(this.get(), jQuery(selector, context))));
        },
        addBack: function addBack(selector) {
          return this.add(selector == null ? this.prevObject : this.prevObject.filter(selector));
        }
      });

      function sibling(cur, dir) {
        while ((cur = cur[dir]) && cur.nodeType !== 1) {}

        return cur;
      }

      jQuery.each({
        parent: function parent(elem) {
          var parent = elem.parentNode;
          return parent && parent.nodeType !== 11 ? parent : null;
        },
        parents: function parents(elem) {
          return dir(elem, "parentNode");
        },
        parentsUntil: function parentsUntil(elem, _i, until) {
          return dir(elem, "parentNode", until);
        },
        next: function next(elem) {
          return sibling(elem, "nextSibling");
        },
        prev: function prev(elem) {
          return sibling(elem, "previousSibling");
        },
        nextAll: function nextAll(elem) {
          return dir(elem, "nextSibling");
        },
        prevAll: function prevAll(elem) {
          return dir(elem, "previousSibling");
        },
        nextUntil: function nextUntil(elem, _i, until) {
          return dir(elem, "nextSibling", until);
        },
        prevUntil: function prevUntil(elem, _i, until) {
          return dir(elem, "previousSibling", until);
        },
        siblings: function siblings(elem) {
          return _siblings((elem.parentNode || {}).firstChild, elem);
        },
        children: function children(elem) {
          return _siblings(elem.firstChild);
        },
        contents: function contents(elem) {
          if (elem.contentDocument != null && 
          getProto(elem.contentDocument)) {
            return elem.contentDocument;
          } 


          if (nodeName(elem, "template")) {
            elem = elem.content || elem;
          }

          return jQuery.merge([], elem.childNodes);
        }
      }, function (name, fn) {
        jQuery.fn[name] = function (until, selector) {
          var matched = jQuery.map(this, fn, until);

          if (name.slice(-5) !== "Until") {
            selector = until;
          }

          if (selector && typeof selector === "string") {
            matched = jQuery.filter(selector, matched);
          }

          if (this.length > 1) {
            if (!guaranteedUnique[name]) {
              jQuery.uniqueSort(matched);
            } 


            if (rparentsprev.test(name)) {
              matched.reverse();
            }
          }

          return this.pushStack(matched);
        };
      });
      var rnothtmlwhite = /[^\x20\t\r\n\f]+/g; 

      function createOptions(options) {
        var object = {};
        jQuery.each(options.match(rnothtmlwhite) || [], function (_, flag) {
          object[flag] = true;
        });
        return object;
      }


      jQuery.Callbacks = function (options) {
        options = typeof options === "string" ? createOptions(options) : jQuery.extend({}, options);

        var 
        firing,
        memory,
        _fired,
        _locked,
        list = [],
        queue = [],
        firingIndex = -1,
        fire = function fire() {
          _locked = _locked || options.once; 

          _fired = firing = true;

          for (; queue.length; firingIndex = -1) {
            memory = queue.shift();

            while (++firingIndex < list.length) {
              if (list[firingIndex].apply(memory[0], memory[1]) === false && options.stopOnFalse) {
                firingIndex = list.length;
                memory = false;
              }
            }
          } 


          if (!options.memory) {
            memory = false;
          }

          firing = false; 

          if (_locked) {
            if (memory) {
              list = []; 
            } else {
              list = "";
            }
          }
        },
        self = {
          add: function add() {
            if (list) {
              if (memory && !firing) {
                firingIndex = list.length - 1;
                queue.push(memory);
              }

              (function add(args) {
                jQuery.each(args, function (_, arg) {
                  if (isFunction(arg)) {
                    if (!options.unique || !self.has(arg)) {
                      list.push(arg);
                    }
                  } else if (arg && arg.length && toType(arg) !== "string") {
                    add(arg);
                  }
                });
              })(arguments);

              if (memory && !firing) {
                fire();
              }
            }

            return this;
          },
          remove: function remove() {
            jQuery.each(arguments, function (_, arg) {
              var index;

              while ((index = jQuery.inArray(arg, list, index)) > -1) {
                list.splice(index, 1); 

                if (index <= firingIndex) {
                  firingIndex--;
                }
              }
            });
            return this;
          },
          has: function has(fn) {
            return fn ? jQuery.inArray(fn, list) > -1 : list.length > 0;
          },
          empty: function empty() {
            if (list) {
              list = [];
            }

            return this;
          },
          disable: function disable() {
            _locked = queue = [];
            list = memory = "";
            return this;
          },
          disabled: function disabled() {
            return !list;
          },
          lock: function lock() {
            _locked = queue = [];

            if (!memory && !firing) {
              list = memory = "";
            }

            return this;
          },
          locked: function locked() {
            return !!_locked;
          },
          fireWith: function fireWith(context, args) {
            if (!_locked) {
              args = args || [];
              args = [context, args.slice ? args.slice() : args];
              queue.push(args);

              if (!firing) {
                fire();
              }
            }

            return this;
          },
          fire: function fire() {
            self.fireWith(this, arguments);
            return this;
          },
          fired: function fired() {
            return !!_fired;
          }
        };

        return self;
      };

      function Identity(v) {
        return v;
      }

      function Thrower(ex) {
        throw ex;
      }

      function adoptValue(value, resolve, reject, noValue) {
        var method;

        try {
          if (value && isFunction(method = value.promise)) {
            method.call(value).done(resolve).fail(reject); 
          } else if (value && isFunction(method = value.then)) {
            method.call(value, resolve, reject); 
          } else {
            resolve.apply(undefined, [value].slice(noValue));
          } 

        } catch (value) {
          reject.apply(undefined, [value]);
        }
      }

      jQuery.extend({
        Deferred: function Deferred(func) {
          var tuples = [
          ["notify", "progress", jQuery.Callbacks("memory"), jQuery.Callbacks("memory"), 2], ["resolve", "done", jQuery.Callbacks("once memory"), jQuery.Callbacks("once memory"), 0, "resolved"], ["reject", "fail", jQuery.Callbacks("once memory"), jQuery.Callbacks("once memory"), 1, "rejected"]],
              _state = "pending",
              _promise = {
            state: function state() {
              return _state;
            },
            always: function always() {
              deferred.done(arguments).fail(arguments);
              return this;
            },
            "catch": function _catch(fn) {
              return _promise.then(null, fn);
            },
            pipe: function pipe()
            {
              var fns = arguments;
              return jQuery.Deferred(function (newDefer) {
                jQuery.each(tuples, function (_i, tuple) {
                  var fn = isFunction(fns[tuple[4]]) && fns[tuple[4]]; 

                  deferred[tuple[1]](function () {
                    var returned = fn && fn.apply(this, arguments);

                    if (returned && isFunction(returned.promise)) {
                      returned.promise().progress(newDefer.notify).done(newDefer.resolve).fail(newDefer.reject);
                    } else {
                      newDefer[tuple[0] + "With"](this, fn ? [returned] : arguments);
                    }
                  });
                });
                fns = null;
              }).promise();
            },
            then: function then(onFulfilled, onRejected, onProgress) {
              var maxDepth = 0;

              function resolve(depth, deferred, handler, special) {
                return function () {
                  var that = this,
                      args = arguments,
                      mightThrow = function mightThrow() {
                    var returned, then; 

                    if (depth < maxDepth) {
                      return;
                    }

                    returned = handler.apply(that, args); 

                    if (returned === deferred.promise()) {
                      throw new TypeError("Thenable self-resolution");
                    } 


                    then = returned && ( 
                    _typeof(returned) === "object" || typeof returned === "function") && returned.then; 

                    if (isFunction(then)) {
                      if (special) {
                        then.call(returned, resolve(maxDepth, deferred, Identity, special), resolve(maxDepth, deferred, Thrower, special)); 
                      } else {
                        maxDepth++;
                        then.call(returned, resolve(maxDepth, deferred, Identity, special), resolve(maxDepth, deferred, Thrower, special), resolve(maxDepth, deferred, Identity, deferred.notifyWith));
                      } 

                    } else {
                      if (handler !== Identity) {
                        that = undefined;
                        args = [returned];
                      } 


                      (special || deferred.resolveWith)(that, args);
                    }
                  },
                  process = special ? mightThrow : function () {
                    try {
                      mightThrow();
                    } catch (e) {
                      if (jQuery.Deferred.exceptionHook) {
                        jQuery.Deferred.exceptionHook(e, process.stackTrace);
                      } 


                      if (depth + 1 >= maxDepth) {
                        if (handler !== Thrower) {
                          that = undefined;
                          args = [e];
                        }

                        deferred.rejectWith(that, args);
                      }
                    }
                  }; 


                  if (depth) {
                    process();
                  } else {
                    if (jQuery.Deferred.getStackHook) {
                      process.stackTrace = jQuery.Deferred.getStackHook();
                    }

                    window.setTimeout(process);
                  }
                };
              }

              return jQuery.Deferred(function (newDefer) {
                tuples[0][3].add(resolve(0, newDefer, isFunction(onProgress) ? onProgress : Identity, newDefer.notifyWith)); 

                tuples[1][3].add(resolve(0, newDefer, isFunction(onFulfilled) ? onFulfilled : Identity)); 

                tuples[2][3].add(resolve(0, newDefer, isFunction(onRejected) ? onRejected : Thrower));
              }).promise();
            },
            promise: function promise(obj) {
              return obj != null ? jQuery.extend(obj, _promise) : _promise;
            }
          },
              deferred = {}; 

          jQuery.each(tuples, function (i, tuple) {
            var list = tuple[2],
                stateString = tuple[5]; 

            _promise[tuple[1]] = list.add; 

            if (stateString) {
              list.add(function () {
                _state = stateString;
              }, 
              tuples[3 - i][2].disable, 
              tuples[3 - i][3].disable, 
              tuples[0][2].lock, 
              tuples[0][3].lock);
            } 


            list.add(tuple[3].fire); 

            deferred[tuple[0]] = function () {
              deferred[tuple[0] + "With"](this === deferred ? undefined : this, arguments);
              return this;
            }; 


            deferred[tuple[0] + "With"] = list.fireWith;
          }); 

          _promise.promise(deferred); 


          if (func) {
            func.call(deferred, deferred);
          } 


          return deferred;
        },
        when: function when(singleValue) {
          var 
          remaining = arguments.length,
          i = remaining,
          resolveContexts = Array(i),
              resolveValues = _slice.call(arguments),
          master = jQuery.Deferred(),
          updateFunc = function updateFunc(i) {
            return function (value) {
              resolveContexts[i] = this;
              resolveValues[i] = arguments.length > 1 ? _slice.call(arguments) : value;

              if (! --remaining) {
                master.resolveWith(resolveContexts, resolveValues);
              }
            };
          }; 


          if (remaining <= 1) {
            adoptValue(singleValue, master.done(updateFunc(i)).resolve, master.reject, !remaining); 

            if (master.state() === "pending" || isFunction(resolveValues[i] && resolveValues[i].then)) {
              return master.then();
            }
          } 


          while (i--) {
            adoptValue(resolveValues[i], updateFunc(i), master.reject);
          }

          return master.promise();
        }
      }); 

      var rerrorNames = /^(Eval|Internal|Range|Reference|Syntax|Type|URI)Error$/;

      jQuery.Deferred.exceptionHook = function (error, stack) {
        if (window.console && window.console.warn && error && rerrorNames.test(error.name)) {
          window.console.warn("jQuery.Deferred exception: " + error.message, error.stack, stack);
        }
      };

      jQuery.readyException = function (error) {
        window.setTimeout(function () {
          throw error;
        });
      }; 


      var readyList = jQuery.Deferred();

      jQuery.fn.ready = function (fn) {
        readyList.then(fn) 
        ["catch"](function (error) {
          jQuery.readyException(error);
        });
        return this;
      };

      jQuery.extend({
        isReady: false,
        readyWait: 1,
        ready: function ready(wait) {
          if (wait === true ? --jQuery.readyWait : jQuery.isReady) {
            return;
          } 


          jQuery.isReady = true; 

          if (wait !== true && --jQuery.readyWait > 0) {
            return;
          } 


          readyList.resolveWith(document, [jQuery]);
        }
      });
      jQuery.ready.then = readyList.then; 

      function completed() {
        document.removeEventListener("DOMContentLoaded", completed);
        window.removeEventListener("load", completed);
        jQuery.ready();
      } 


      if (document.readyState === "complete" || document.readyState !== "loading" && !document.documentElement.doScroll) {
        window.setTimeout(jQuery.ready);
      } else {
        document.addEventListener("DOMContentLoaded", completed); 

        window.addEventListener("load", completed);
      } 


      var access = function access(elems, fn, key, value, chainable, emptyGet, raw) {
        var i = 0,
            len = elems.length,
            bulk = key == null; 

        if (toType(key) === "object") {
          chainable = true;

          for (i in key) {
            access(elems, fn, i, key[i], true, emptyGet, raw);
          } 

        } else if (value !== undefined) {
          chainable = true;

          if (!isFunction(value)) {
            raw = true;
          }

          if (bulk) {
            if (raw) {
              fn.call(elems, value);
              fn = null; 
            } else {
              bulk = fn;

              fn = function fn(elem, _key, value) {
                return bulk.call(jQuery(elem), value);
              };
            }
          }

          if (fn) {
            for (; i < len; i++) {
              fn(elems[i], key, raw ? value : value.call(elems[i], i, fn(elems[i], key)));
            }
          }
        }

        if (chainable) {
          return elems;
        } 


        if (bulk) {
          return fn.call(elems);
        }

        return len ? fn(elems[0], key) : emptyGet;
      }; 


      var rmsPrefix = /^-ms-/,
          rdashAlpha = /-([a-z])/g; 

      function fcamelCase(_all, letter) {
        return letter.toUpperCase();
      } 


      function camelCase(string) {
        return string.replace(rmsPrefix, "ms-").replace(rdashAlpha, fcamelCase);
      }

      var acceptData = function acceptData(owner) {
        return owner.nodeType === 1 || owner.nodeType === 9 || !+owner.nodeType;
      };

      function Data() {
        this.expando = jQuery.expando + Data.uid++;
      }

      Data.uid = 1;
      Data.prototype = {
        cache: function cache(owner) {
          var value = owner[this.expando]; 

          if (!value) {
            value = {}; 

            if (acceptData(owner)) {
              if (owner.nodeType) {
                owner[this.expando] = value; 
              } else {
                Object.defineProperty(owner, this.expando, {
                  value: value,
                  configurable: true
                });
              }
            }
          }

          return value;
        },
        set: function set(owner, data, value) {
          var prop,
              cache = this.cache(owner); 

          if (typeof data === "string") {
            cache[camelCase(data)] = value; 
          } else {
            for (prop in data) {
              cache[camelCase(prop)] = data[prop];
            }
          }

          return cache;
        },
        get: function get(owner, key) {
          return key === undefined ? this.cache(owner) : 
          owner[this.expando] && owner[this.expando][camelCase(key)];
        },
        access: function access(owner, key, value) {
          if (key === undefined || key && typeof key === "string" && value === undefined) {
            return this.get(owner, key);
          } 


          this.set(owner, key, value); 

          return value !== undefined ? value : key;
        },
        remove: function remove(owner, key) {
          var i,
              cache = owner[this.expando];

          if (cache === undefined) {
            return;
          }

          if (key !== undefined) {
            if (Array.isArray(key)) {
              key = key.map(camelCase);
            } else {
              key = camelCase(key); 

              key = key in cache ? [key] : key.match(rnothtmlwhite) || [];
            }

            i = key.length;

            while (i--) {
              delete cache[key[i]];
            }
          } 


          if (key === undefined || jQuery.isEmptyObject(cache)) {
            if (owner.nodeType) {
              owner[this.expando] = undefined;
            } else {
              delete owner[this.expando];
            }
          }
        },
        hasData: function hasData(owner) {
          var cache = owner[this.expando];
          return cache !== undefined && !jQuery.isEmptyObject(cache);
        }
      };
      var dataPriv = new Data();
      var dataUser = new Data(); 

      var rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/,
          rmultiDash = /[A-Z]/g;

      function getData(data) {
        if (data === "true") {
          return true;
        }

        if (data === "false") {
          return false;
        }

        if (data === "null") {
          return null;
        } 


        if (data === +data + "") {
          return +data;
        }

        if (rbrace.test(data)) {
          return JSON.parse(data);
        }

        return data;
      }

      function dataAttr(elem, key, data) {
        var name; 

        if (data === undefined && elem.nodeType === 1) {
          name = "data-" + key.replace(rmultiDash, "-$&").toLowerCase();
          data = elem.getAttribute(name);

          if (typeof data === "string") {
            try {
              data = getData(data);
            } catch (e) {} 


            dataUser.set(elem, key, data);
          } else {
            data = undefined;
          }
        }

        return data;
      }

      jQuery.extend({
        hasData: function hasData(elem) {
          return dataUser.hasData(elem) || dataPriv.hasData(elem);
        },
        data: function data(elem, name, _data) {
          return dataUser.access(elem, name, _data);
        },
        removeData: function removeData(elem, name) {
          dataUser.remove(elem, name);
        },
        _data: function _data(elem, name, data) {
          return dataPriv.access(elem, name, data);
        },
        _removeData: function _removeData(elem, name) {
          dataPriv.remove(elem, name);
        }
      });
      jQuery.fn.extend({
        data: function data(key, value) {
          var i,
              name,
              data,
              elem = this[0],
              attrs = elem && elem.attributes; 

          if (key === undefined) {
            if (this.length) {
              data = dataUser.get(elem);

              if (elem.nodeType === 1 && !dataPriv.get(elem, "hasDataAttrs")) {
                i = attrs.length;

                while (i--) {
                  if (attrs[i]) {
                    name = attrs[i].name;

                    if (name.indexOf("data-") === 0) {
                      name = camelCase(name.slice(5));
                      dataAttr(elem, name, data[name]);
                    }
                  }
                }

                dataPriv.set(elem, "hasDataAttrs", true);
              }
            }

            return data;
          } 


          if (_typeof(key) === "object") {
            return this.each(function () {
              dataUser.set(this, key);
            });
          }

          return access(this, function (value) {
            var data; 

            if (elem && value === undefined) {
              data = dataUser.get(elem, key);

              if (data !== undefined) {
                return data;
              } 


              data = dataAttr(elem, key);

              if (data !== undefined) {
                return data;
              } 


              return;
            } 


            this.each(function () {
              dataUser.set(this, key, value);
            });
          }, null, value, arguments.length > 1, null, true);
        },
        removeData: function removeData(key) {
          return this.each(function () {
            dataUser.remove(this, key);
          });
        }
      });
      jQuery.extend({
        queue: function queue(elem, type, data) {
          var queue;

          if (elem) {
            type = (type || "fx") + "queue";
            queue = dataPriv.get(elem, type); 

            if (data) {
              if (!queue || Array.isArray(data)) {
                queue = dataPriv.access(elem, type, jQuery.makeArray(data));
              } else {
                queue.push(data);
              }
            }

            return queue || [];
          }
        },
        dequeue: function dequeue(elem, type) {
          type = type || "fx";

          var queue = jQuery.queue(elem, type),
              startLength = queue.length,
              fn = queue.shift(),
              hooks = jQuery._queueHooks(elem, type),
              next = function next() {
            jQuery.dequeue(elem, type);
          }; 


          if (fn === "inprogress") {
            fn = queue.shift();
            startLength--;
          }

          if (fn) {
            if (type === "fx") {
              queue.unshift("inprogress");
            } 


            delete hooks.stop;
            fn.call(elem, next, hooks);
          }

          if (!startLength && hooks) {
            hooks.empty.fire();
          }
        },
        _queueHooks: function _queueHooks(elem, type) {
          var key = type + "queueHooks";
          return dataPriv.get(elem, key) || dataPriv.access(elem, key, {
            empty: jQuery.Callbacks("once memory").add(function () {
              dataPriv.remove(elem, [type + "queue", key]);
            })
          });
        }
      });
      jQuery.fn.extend({
        queue: function queue(type, data) {
          var setter = 2;

          if (typeof type !== "string") {
            data = type;
            type = "fx";
            setter--;
          }

          if (arguments.length < setter) {
            return jQuery.queue(this[0], type);
          }

          return data === undefined ? this : this.each(function () {
            var queue = jQuery.queue(this, type, data); 

            jQuery._queueHooks(this, type);

            if (type === "fx" && queue[0] !== "inprogress") {
              jQuery.dequeue(this, type);
            }
          });
        },
        dequeue: function dequeue(type) {
          return this.each(function () {
            jQuery.dequeue(this, type);
          });
        },
        clearQueue: function clearQueue(type) {
          return this.queue(type || "fx", []);
        },
        promise: function promise(type, obj) {
          var tmp,
              count = 1,
              defer = jQuery.Deferred(),
              elements = this,
              i = this.length,
              resolve = function resolve() {
            if (! --count) {
              defer.resolveWith(elements, [elements]);
            }
          };

          if (typeof type !== "string") {
            obj = type;
            type = undefined;
          }

          type = type || "fx";

          while (i--) {
            tmp = dataPriv.get(elements[i], type + "queueHooks");

            if (tmp && tmp.empty) {
              count++;
              tmp.empty.add(resolve);
            }
          }

          resolve();
          return defer.promise(obj);
        }
      });
      var pnum = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source;
      var rcssNum = new RegExp("^(?:([+-])=|)(" + pnum + ")([a-z%]*)$", "i");
      var cssExpand = ["Top", "Right", "Bottom", "Left"];
      var documentElement = document.documentElement;

      var isAttached = function isAttached(elem) {
        return jQuery.contains(elem.ownerDocument, elem);
      },
          composed = {
        composed: true
      }; 


      if (documentElement.getRootNode) {
        isAttached = function isAttached(elem) {
          return jQuery.contains(elem.ownerDocument, elem) || elem.getRootNode(composed) === elem.ownerDocument;
        };
      }

      var isHiddenWithinTree = function isHiddenWithinTree(elem, el) {
        elem = el || elem; 

        return elem.style.display === "none" || elem.style.display === "" && 
        isAttached(elem) && jQuery.css(elem, "display") === "none";
      };

      function adjustCSS(elem, prop, valueParts, tween) {
        var adjusted,
            scale,
            maxIterations = 20,
            currentValue = tween ? function () {
          return tween.cur();
        } : function () {
          return jQuery.css(elem, prop, "");
        },
            initial = currentValue(),
            unit = valueParts && valueParts[3] || (jQuery.cssNumber[prop] ? "" : "px"),
        initialInUnit = elem.nodeType && (jQuery.cssNumber[prop] || unit !== "px" && +initial) && rcssNum.exec(jQuery.css(elem, prop));

        if (initialInUnit && initialInUnit[3] !== unit) {
          initial = initial / 2; 

          unit = unit || initialInUnit[3]; 

          initialInUnit = +initial || 1;

          while (maxIterations--) {
            jQuery.style(elem, prop, initialInUnit + unit);

            if ((1 - scale) * (1 - (scale = currentValue() / initial || 0.5)) <= 0) {
              maxIterations = 0;
            }

            initialInUnit = initialInUnit / scale;
          }

          initialInUnit = initialInUnit * 2;
          jQuery.style(elem, prop, initialInUnit + unit); 

          valueParts = valueParts || [];
        }

        if (valueParts) {
          initialInUnit = +initialInUnit || +initial || 0; 

          adjusted = valueParts[1] ? initialInUnit + (valueParts[1] + 1) * valueParts[2] : +valueParts[2];

          if (tween) {
            tween.unit = unit;
            tween.start = initialInUnit;
            tween.end = adjusted;
          }
        }

        return adjusted;
      }

      var defaultDisplayMap = {};

      function getDefaultDisplay(elem) {
        var temp,
            doc = elem.ownerDocument,
            nodeName = elem.nodeName,
            display = defaultDisplayMap[nodeName];

        if (display) {
          return display;
        }

        temp = doc.body.appendChild(doc.createElement(nodeName));
        display = jQuery.css(temp, "display");
        temp.parentNode.removeChild(temp);

        if (display === "none") {
          display = "block";
        }

        defaultDisplayMap[nodeName] = display;
        return display;
      }

      function showHide(elements, show) {
        var display,
            elem,
            values = [],
            index = 0,
            length = elements.length; 

        for (; index < length; index++) {
          elem = elements[index];

          if (!elem.style) {
            continue;
          }

          display = elem.style.display;

          if (show) {
            if (display === "none") {
              values[index] = dataPriv.get(elem, "display") || null;

              if (!values[index]) {
                elem.style.display = "";
              }
            }

            if (elem.style.display === "" && isHiddenWithinTree(elem)) {
              values[index] = getDefaultDisplay(elem);
            }
          } else {
            if (display !== "none") {
              values[index] = "none"; 

              dataPriv.set(elem, "display", display);
            }
          }
        } 


        for (index = 0; index < length; index++) {
          if (values[index] != null) {
            elements[index].style.display = values[index];
          }
        }

        return elements;
      }

      jQuery.fn.extend({
        show: function show() {
          return showHide(this, true);
        },
        hide: function hide() {
          return showHide(this);
        },
        toggle: function toggle(state) {
          if (typeof state === "boolean") {
            return state ? this.show() : this.hide();
          }

          return this.each(function () {
            if (isHiddenWithinTree(this)) {
              jQuery(this).show();
            } else {
              jQuery(this).hide();
            }
          });
        }
      });
      var rcheckableType = /^(?:checkbox|radio)$/i;
      var rtagName = /<([a-z][^\/\0>\x20\t\r\n\f]*)/i;
      var rscriptType = /^$|^module$|\/(?:java|ecma)script/i;

      (function () {
        var fragment = document.createDocumentFragment(),
            div = fragment.appendChild(document.createElement("div")),
            input = document.createElement("input"); 

        input.setAttribute("type", "radio");
        input.setAttribute("checked", "checked");
        input.setAttribute("name", "t");
        div.appendChild(input); 

        support.checkClone = div.cloneNode(true).cloneNode(true).lastChild.checked; 

        div.innerHTML = "<textarea>x</textarea>";
        support.noCloneChecked = !!div.cloneNode(true).lastChild.defaultValue; 

        div.innerHTML = "<option></option>";
        support.option = !!div.lastChild;
      })(); 


      var wrapMap = {
        thead: [1, "<table>", "</table>"],
        col: [2, "<table><colgroup>", "</colgroup></table>"],
        tr: [2, "<table><tbody>", "</tbody></table>"],
        td: [3, "<table><tbody><tr>", "</tr></tbody></table>"],
        _default: [0, "", ""]
      };
      wrapMap.tbody = wrapMap.tfoot = wrapMap.colgroup = wrapMap.caption = wrapMap.thead;
      wrapMap.th = wrapMap.td; 

      if (!support.option) {
        wrapMap.optgroup = wrapMap.option = [1, "<select multiple='multiple'>", "</select>"];
      }

      function getAll(context, tag) {
        var ret;

        if (typeof context.getElementsByTagName !== "undefined") {
          ret = context.getElementsByTagName(tag || "*");
        } else if (typeof context.querySelectorAll !== "undefined") {
          ret = context.querySelectorAll(tag || "*");
        } else {
          ret = [];
        }

        if (tag === undefined || tag && nodeName(context, tag)) {
          return jQuery.merge([context], ret);
        }

        return ret;
      } 


      function setGlobalEval(elems, refElements) {
        var i = 0,
            l = elems.length;

        for (; i < l; i++) {
          dataPriv.set(elems[i], "globalEval", !refElements || dataPriv.get(refElements[i], "globalEval"));
        }
      }

      var rhtml = /<|&#?\w+;/;

      function buildFragment(elems, context, scripts, selection, ignored) {
        var elem,
            tmp,
            tag,
            wrap,
            attached,
            j,
            fragment = context.createDocumentFragment(),
            nodes = [],
            i = 0,
            l = elems.length;

        for (; i < l; i++) {
          elem = elems[i];

          if (elem || elem === 0) {
            if (toType(elem) === "object") {
              jQuery.merge(nodes, elem.nodeType ? [elem] : elem); 
            } else if (!rhtml.test(elem)) {
              nodes.push(context.createTextNode(elem)); 
            } else {
              tmp = tmp || fragment.appendChild(context.createElement("div")); 

              tag = (rtagName.exec(elem) || ["", ""])[1].toLowerCase();
              wrap = wrapMap[tag] || wrapMap._default;
              tmp.innerHTML = wrap[1] + jQuery.htmlPrefilter(elem) + wrap[2]; 

              j = wrap[0];

              while (j--) {
                tmp = tmp.lastChild;
              } 


              jQuery.merge(nodes, tmp.childNodes); 

              tmp = fragment.firstChild; 

              tmp.textContent = "";
            }
          }
        } 


        fragment.textContent = "";
        i = 0;

        while (elem = nodes[i++]) {
          if (selection && jQuery.inArray(elem, selection) > -1) {
            if (ignored) {
              ignored.push(elem);
            }

            continue;
          }

          attached = isAttached(elem); 

          tmp = getAll(fragment.appendChild(elem), "script"); 

          if (attached) {
            setGlobalEval(tmp);
          } 


          if (scripts) {
            j = 0;

            while (elem = tmp[j++]) {
              if (rscriptType.test(elem.type || "")) {
                scripts.push(elem);
              }
            }
          }
        }

        return fragment;
      }

      var rkeyEvent = /^key/,
          rmouseEvent = /^(?:mouse|pointer|contextmenu|drag|drop)|click/,
          rtypenamespace = /^([^.]*)(?:\.(.+)|)/;

      function returnTrue() {
        return true;
      }

      function returnFalse() {
        return false;
      } 


      function expectSync(elem, type) {
        return elem === safeActiveElement() === (type === "focus");
      } 


      function safeActiveElement() {
        try {
          return document.activeElement;
        } catch (err) {}
      }

      function _on(elem, types, selector, data, fn, one) {
        var origFn, type; 

        if (_typeof(types) === "object") {
          if (typeof selector !== "string") {
            data = data || selector;
            selector = undefined;
          }

          for (type in types) {
            _on(elem, type, selector, data, types[type], one);
          }

          return elem;
        }

        if (data == null && fn == null) {
          fn = selector;
          data = selector = undefined;
        } else if (fn == null) {
          if (typeof selector === "string") {
            fn = data;
            data = undefined;
          } else {
            fn = data;
            data = selector;
            selector = undefined;
          }
        }

        if (fn === false) {
          fn = returnFalse;
        } else if (!fn) {
          return elem;
        }

        if (one === 1) {
          origFn = fn;

          fn = function fn(event) {
            jQuery().off(event);
            return origFn.apply(this, arguments);
          }; 


          fn.guid = origFn.guid || (origFn.guid = jQuery.guid++);
        }

        return elem.each(function () {
          jQuery.event.add(this, types, fn, data, selector);
        });
      }


      jQuery.event = {
        global: {},
        add: function add(elem, types, handler, data, selector) {
          var handleObjIn,
              eventHandle,
              tmp,
              events,
              t,
              handleObj,
              special,
              handlers,
              type,
              namespaces,
              origType,
              elemData = dataPriv.get(elem); 

          if (!acceptData(elem)) {
            return;
          } 


          if (handler.handler) {
            handleObjIn = handler;
            handler = handleObjIn.handler;
            selector = handleObjIn.selector;
          } 


          if (selector) {
            jQuery.find.matchesSelector(documentElement, selector);
          } 


          if (!handler.guid) {
            handler.guid = jQuery.guid++;
          } 


          if (!(events = elemData.events)) {
            events = elemData.events = Object.create(null);
          }

          if (!(eventHandle = elemData.handle)) {
            eventHandle = elemData.handle = function (e) {
              return typeof jQuery !== "undefined" && jQuery.event.triggered !== e.type ? jQuery.event.dispatch.apply(elem, arguments) : undefined;
            };
          } 


          types = (types || "").match(rnothtmlwhite) || [""];
          t = types.length;

          while (t--) {
            tmp = rtypenamespace.exec(types[t]) || [];
            type = origType = tmp[1];
            namespaces = (tmp[2] || "").split(".").sort(); 

            if (!type) {
              continue;
            } 


            special = jQuery.event.special[type] || {}; 

            type = (selector ? special.delegateType : special.bindType) || type; 

            special = jQuery.event.special[type] || {}; 

            handleObj = jQuery.extend({
              type: type,
              origType: origType,
              data: data,
              handler: handler,
              guid: handler.guid,
              selector: selector,
              needsContext: selector && jQuery.expr.match.needsContext.test(selector),
              namespace: namespaces.join(".")
            }, handleObjIn); 

            if (!(handlers = events[type])) {
              handlers = events[type] = [];
              handlers.delegateCount = 0; 

              if (!special.setup || special.setup.call(elem, data, namespaces, eventHandle) === false) {
                if (elem.addEventListener) {
                  elem.addEventListener(type, eventHandle);
                }
              }
            }

            if (special.add) {
              special.add.call(elem, handleObj);

              if (!handleObj.handler.guid) {
                handleObj.handler.guid = handler.guid;
              }
            } 


            if (selector) {
              handlers.splice(handlers.delegateCount++, 0, handleObj);
            } else {
              handlers.push(handleObj);
            } 


            jQuery.event.global[type] = true;
          }
        },
        remove: function remove(elem, types, handler, selector, mappedTypes) {
          var j,
              origCount,
              tmp,
              events,
              t,
              handleObj,
              special,
              handlers,
              type,
              namespaces,
              origType,
              elemData = dataPriv.hasData(elem) && dataPriv.get(elem);

          if (!elemData || !(events = elemData.events)) {
            return;
          } 


          types = (types || "").match(rnothtmlwhite) || [""];
          t = types.length;

          while (t--) {
            tmp = rtypenamespace.exec(types[t]) || [];
            type = origType = tmp[1];
            namespaces = (tmp[2] || "").split(".").sort(); 

            if (!type) {
              for (type in events) {
                jQuery.event.remove(elem, type + types[t], handler, selector, true);
              }

              continue;
            }

            special = jQuery.event.special[type] || {};
            type = (selector ? special.delegateType : special.bindType) || type;
            handlers = events[type] || [];
            tmp = tmp[2] && new RegExp("(^|\\.)" + namespaces.join("\\.(?:.*\\.|)") + "(\\.|$)"); 

            origCount = j = handlers.length;

            while (j--) {
              handleObj = handlers[j];

              if ((mappedTypes || origType === handleObj.origType) && (!handler || handler.guid === handleObj.guid) && (!tmp || tmp.test(handleObj.namespace)) && (!selector || selector === handleObj.selector || selector === "**" && handleObj.selector)) {
                handlers.splice(j, 1);

                if (handleObj.selector) {
                  handlers.delegateCount--;
                }

                if (special.remove) {
                  special.remove.call(elem, handleObj);
                }
              }
            } 


            if (origCount && !handlers.length) {
              if (!special.teardown || special.teardown.call(elem, namespaces, elemData.handle) === false) {
                jQuery.removeEvent(elem, type, elemData.handle);
              }

              delete events[type];
            }
          } 


          if (jQuery.isEmptyObject(events)) {
            dataPriv.remove(elem, "handle events");
          }
        },
        dispatch: function dispatch(nativeEvent) {
          var i,
              j,
              ret,
              matched,
              handleObj,
              handlerQueue,
              args = new Array(arguments.length),
          event = jQuery.event.fix(nativeEvent),
              handlers = (dataPriv.get(this, "events") || Object.create(null))[event.type] || [],
              special = jQuery.event.special[event.type] || {}; 

          args[0] = event;

          for (i = 1; i < arguments.length; i++) {
            args[i] = arguments[i];
          }

          event.delegateTarget = this; 

          if (special.preDispatch && special.preDispatch.call(this, event) === false) {
            return;
          } 


          handlerQueue = jQuery.event.handlers.call(this, event, handlers); 

          i = 0;

          while ((matched = handlerQueue[i++]) && !event.isPropagationStopped()) {
            event.currentTarget = matched.elem;
            j = 0;

            while ((handleObj = matched.handlers[j++]) && !event.isImmediatePropagationStopped()) {
              if (!event.rnamespace || handleObj.namespace === false || event.rnamespace.test(handleObj.namespace)) {
                event.handleObj = handleObj;
                event.data = handleObj.data;
                ret = ((jQuery.event.special[handleObj.origType] || {}).handle || handleObj.handler).apply(matched.elem, args);

                if (ret !== undefined) {
                  if ((event.result = ret) === false) {
                    event.preventDefault();
                    event.stopPropagation();
                  }
                }
              }
            }
          } 


          if (special.postDispatch) {
            special.postDispatch.call(this, event);
          }

          return event.result;
        },
        handlers: function handlers(event, _handlers) {
          var i,
              handleObj,
              sel,
              matchedHandlers,
              matchedSelectors,
              handlerQueue = [],
              delegateCount = _handlers.delegateCount,
              cur = event.target; 

          if (delegateCount && 
          cur.nodeType && 
          !(event.type === "click" && event.button >= 1)) {
            for (; cur !== this; cur = cur.parentNode || this) {
              if (cur.nodeType === 1 && !(event.type === "click" && cur.disabled === true)) {
                matchedHandlers = [];
                matchedSelectors = {};

                for (i = 0; i < delegateCount; i++) {
                  handleObj = _handlers[i]; 

                  sel = handleObj.selector + " ";

                  if (matchedSelectors[sel] === undefined) {
                    matchedSelectors[sel] = handleObj.needsContext ? jQuery(sel, this).index(cur) > -1 : jQuery.find(sel, this, null, [cur]).length;
                  }

                  if (matchedSelectors[sel]) {
                    matchedHandlers.push(handleObj);
                  }
                }

                if (matchedHandlers.length) {
                  handlerQueue.push({
                    elem: cur,
                    handlers: matchedHandlers
                  });
                }
              }
            }
          } 


          cur = this;

          if (delegateCount < _handlers.length) {
            handlerQueue.push({
              elem: cur,
              handlers: _handlers.slice(delegateCount)
            });
          }

          return handlerQueue;
        },
        addProp: function addProp(name, hook) {
          Object.defineProperty(jQuery.Event.prototype, name, {
            enumerable: true,
            configurable: true,
            get: isFunction(hook) ? function () {
              if (this.originalEvent) {
                return hook(this.originalEvent);
              }
            } : function () {
              if (this.originalEvent) {
                return this.originalEvent[name];
              }
            },
            set: function set(value) {
              Object.defineProperty(this, name, {
                enumerable: true,
                configurable: true,
                writable: true,
                value: value
              });
            }
          });
        },
        fix: function fix(originalEvent) {
          return originalEvent[jQuery.expando] ? originalEvent : new jQuery.Event(originalEvent);
        },
        special: {
          load: {
            noBubble: true
          },
          click: {
            setup: function setup(data) {
              var el = this || data; 

              if (rcheckableType.test(el.type) && el.click && nodeName(el, "input")) {
                leverageNative(el, "click", returnTrue);
              } 


              return false;
            },
            trigger: function trigger(data) {
              var el = this || data; 

              if (rcheckableType.test(el.type) && el.click && nodeName(el, "input")) {
                leverageNative(el, "click");
              } 


              return true;
            },
            _default: function _default(event) {
              var target = event.target;
              return rcheckableType.test(target.type) && target.click && nodeName(target, "input") && dataPriv.get(target, "click") || nodeName(target, "a");
            }
          },
          beforeunload: {
            postDispatch: function postDispatch(event) {
              if (event.result !== undefined && event.originalEvent) {
                event.originalEvent.returnValue = event.result;
              }
            }
          }
        }
      }; 

      function leverageNative(el, type, expectSync) {
        if (!expectSync) {
          if (dataPriv.get(el, type) === undefined) {
            jQuery.event.add(el, type, returnTrue);
          }

          return;
        } 


        dataPriv.set(el, type, false);
        jQuery.event.add(el, type, {
          namespace: false,
          handler: function handler(event) {
            var notAsync,
                result,
                saved = dataPriv.get(this, type);

            if (event.isTrigger & 1 && this[type]) {
              if (!saved.length) {
                saved = _slice.call(arguments);
                dataPriv.set(this, type, saved); 

                notAsync = expectSync(this, type);
                this[type]();
                result = dataPriv.get(this, type);

                if (saved !== result || notAsync) {
                  dataPriv.set(this, type, false);
                } else {
                  result = {};
                }

                if (saved !== result) {
                  event.stopImmediatePropagation();
                  event.preventDefault();
                  return result.value;
                } 

              } else if ((jQuery.event.special[type] || {}).delegateType) {
                event.stopPropagation();
              } 

            } else if (saved.length) {
              dataPriv.set(this, type, {
                value: jQuery.event.trigger( 
                jQuery.extend(saved[0], jQuery.Event.prototype), saved.slice(1), this)
              }); 

              event.stopImmediatePropagation();
            }
          }
        });
      }

      jQuery.removeEvent = function (elem, type, handle) {
        if (elem.removeEventListener) {
          elem.removeEventListener(type, handle);
        }
      };

      jQuery.Event = function (src, props) {
        if (!(this instanceof jQuery.Event)) {
          return new jQuery.Event(src, props);
        } 


        if (src && src.type) {
          this.originalEvent = src;
          this.type = src.type; 

          this.isDefaultPrevented = src.defaultPrevented || src.defaultPrevented === undefined && 
          src.returnValue === false ? returnTrue : returnFalse; 

          this.target = src.target && src.target.nodeType === 3 ? src.target.parentNode : src.target;
          this.currentTarget = src.currentTarget;
          this.relatedTarget = src.relatedTarget; 
        } else {
          this.type = src;
        } 


        if (props) {
          jQuery.extend(this, props);
        } 


        this.timeStamp = src && src.timeStamp || Date.now(); 

        this[jQuery.expando] = true;
      }; 


      jQuery.Event.prototype = {
        constructor: jQuery.Event,
        isDefaultPrevented: returnFalse,
        isPropagationStopped: returnFalse,
        isImmediatePropagationStopped: returnFalse,
        isSimulated: false,
        preventDefault: function preventDefault() {
          var e = this.originalEvent;
          this.isDefaultPrevented = returnTrue;

          if (e && !this.isSimulated) {
            e.preventDefault();
          }
        },
        stopPropagation: function stopPropagation() {
          var e = this.originalEvent;
          this.isPropagationStopped = returnTrue;

          if (e && !this.isSimulated) {
            e.stopPropagation();
          }
        },
        stopImmediatePropagation: function stopImmediatePropagation() {
          var e = this.originalEvent;
          this.isImmediatePropagationStopped = returnTrue;

          if (e && !this.isSimulated) {
            e.stopImmediatePropagation();
          }

          this.stopPropagation();
        }
      }; 

      jQuery.each({
        altKey: true,
        bubbles: true,
        cancelable: true,
        changedTouches: true,
        ctrlKey: true,
        detail: true,
        eventPhase: true,
        metaKey: true,
        pageX: true,
        pageY: true,
        shiftKey: true,
        view: true,
        "char": true,
        code: true,
        charCode: true,
        key: true,
        keyCode: true,
        button: true,
        buttons: true,
        clientX: true,
        clientY: true,
        offsetX: true,
        offsetY: true,
        pointerId: true,
        pointerType: true,
        screenX: true,
        screenY: true,
        targetTouches: true,
        toElement: true,
        touches: true,
        which: function which(event) {
          var button = event.button; 

          if (event.which == null && rkeyEvent.test(event.type)) {
            return event.charCode != null ? event.charCode : event.keyCode;
          } 


          if (!event.which && button !== undefined && rmouseEvent.test(event.type)) {
            if (button & 1) {
              return 1;
            }

            if (button & 2) {
              return 3;
            }

            if (button & 4) {
              return 2;
            }

            return 0;
          }

          return event.which;
        }
      }, jQuery.event.addProp);
      jQuery.each({
        focus: "focusin",
        blur: "focusout"
      }, function (type, delegateType) {
        jQuery.event.special[type] = {
          setup: function setup() {
            leverageNative(this, type, expectSync); 

            return false;
          },
          trigger: function trigger() {
            leverageNative(this, type); 

            return true;
          },
          delegateType: delegateType
        };
      }); 

      jQuery.each({
        mouseenter: "mouseover",
        mouseleave: "mouseout",
        pointerenter: "pointerover",
        pointerleave: "pointerout"
      }, function (orig, fix) {
        jQuery.event.special[orig] = {
          delegateType: fix,
          bindType: fix,
          handle: function handle(event) {
            var ret,
                target = this,
                related = event.relatedTarget,
                handleObj = event.handleObj; 

            if (!related || related !== target && !jQuery.contains(target, related)) {
              event.type = handleObj.origType;
              ret = handleObj.handler.apply(this, arguments);
              event.type = fix;
            }

            return ret;
          }
        };
      });
      jQuery.fn.extend({
        on: function on(types, selector, data, fn) {
          return _on(this, types, selector, data, fn);
        },
        one: function one(types, selector, data, fn) {
          return _on(this, types, selector, data, fn, 1);
        },
        off: function off(types, selector, fn) {
          var handleObj, type;

          if (types && types.preventDefault && types.handleObj) {
            handleObj = types.handleObj;
            jQuery(types.delegateTarget).off(handleObj.namespace ? handleObj.origType + "." + handleObj.namespace : handleObj.origType, handleObj.selector, handleObj.handler);
            return this;
          }

          if (_typeof(types) === "object") {
            for (type in types) {
              this.off(type, selector, types[type]);
            }

            return this;
          }

          if (selector === false || typeof selector === "function") {
            fn = selector;
            selector = undefined;
          }

          if (fn === false) {
            fn = returnFalse;
          }

          return this.each(function () {
            jQuery.event.remove(this, types, fn, selector);
          });
        }
      });
      var 
      rnoInnerhtml = /<script|<style|<link/i,
      rchecked = /checked\s*(?:[^=]|=\s*.checked.)/i,
          rcleanScript = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g; 

      function manipulationTarget(elem, content) {
        if (nodeName(elem, "table") && nodeName(content.nodeType !== 11 ? content : content.firstChild, "tr")) {
          return jQuery(elem).children("tbody")[0] || elem;
        }

        return elem;
      } 


      function disableScript(elem) {
        elem.type = (elem.getAttribute("type") !== null) + "/" + elem.type;
        return elem;
      }

      function restoreScript(elem) {
        if ((elem.type || "").slice(0, 5) === "true/") {
          elem.type = elem.type.slice(5);
        } else {
          elem.removeAttribute("type");
        }

        return elem;
      }

      function cloneCopyEvent(src, dest) {
        var i, l, type, pdataOld, udataOld, udataCur, events;

        if (dest.nodeType !== 1) {
          return;
        } 


        if (dataPriv.hasData(src)) {
          pdataOld = dataPriv.get(src);
          events = pdataOld.events;

          if (events) {
            dataPriv.remove(dest, "handle events");

            for (type in events) {
              for (i = 0, l = events[type].length; i < l; i++) {
                jQuery.event.add(dest, type, events[type][i]);
              }
            }
          }
        } 


        if (dataUser.hasData(src)) {
          udataOld = dataUser.access(src);
          udataCur = jQuery.extend({}, udataOld);
          dataUser.set(dest, udataCur);
        }
      } 


      function fixInput(src, dest) {
        var nodeName = dest.nodeName.toLowerCase(); 

        if (nodeName === "input" && rcheckableType.test(src.type)) {
          dest.checked = src.checked; 
        } else if (nodeName === "input" || nodeName === "textarea") {
          dest.defaultValue = src.defaultValue;
        }
      }

      function domManip(collection, args, callback, ignored) {
        args = flat(args);
        var fragment,
            first,
            scripts,
            hasScripts,
            node,
            doc,
            i = 0,
            l = collection.length,
            iNoClone = l - 1,
            value = args[0],
            valueIsFunction = isFunction(value); 

        if (valueIsFunction || l > 1 && typeof value === "string" && !support.checkClone && rchecked.test(value)) {
          return collection.each(function (index) {
            var self = collection.eq(index);

            if (valueIsFunction) {
              args[0] = value.call(this, index, self.html());
            }

            domManip(self, args, callback, ignored);
          });
        }

        if (l) {
          fragment = buildFragment(args, collection[0].ownerDocument, false, collection, ignored);
          first = fragment.firstChild;

          if (fragment.childNodes.length === 1) {
            fragment = first;
          } 


          if (first || ignored) {
            scripts = jQuery.map(getAll(fragment, "script"), disableScript);
            hasScripts = scripts.length; 

            for (; i < l; i++) {
              node = fragment;

              if (i !== iNoClone) {
                node = jQuery.clone(node, true, true); 

                if (hasScripts) {
                  jQuery.merge(scripts, getAll(node, "script"));
                }
              }

              callback.call(collection[i], node, i);
            }

            if (hasScripts) {
              doc = scripts[scripts.length - 1].ownerDocument; 

              jQuery.map(scripts, restoreScript); 

              for (i = 0; i < hasScripts; i++) {
                node = scripts[i];

                if (rscriptType.test(node.type || "") && !dataPriv.access(node, "globalEval") && jQuery.contains(doc, node)) {
                  if (node.src && (node.type || "").toLowerCase() !== "module") {
                    if (jQuery._evalUrl && !node.noModule) {
                      jQuery._evalUrl(node.src, {
                        nonce: node.nonce || node.getAttribute("nonce")
                      }, doc);
                    }
                  } else {
                    DOMEval(node.textContent.replace(rcleanScript, ""), node, doc);
                  }
                }
              }
            }
          }
        }

        return collection;
      }

      function _remove(elem, selector, keepData) {
        var node,
            nodes = selector ? jQuery.filter(selector, elem) : elem,
            i = 0;

        for (; (node = nodes[i]) != null; i++) {
          if (!keepData && node.nodeType === 1) {
            jQuery.cleanData(getAll(node));
          }

          if (node.parentNode) {
            if (keepData && isAttached(node)) {
              setGlobalEval(getAll(node, "script"));
            }

            node.parentNode.removeChild(node);
          }
        }

        return elem;
      }

      jQuery.extend({
        htmlPrefilter: function htmlPrefilter(html) {
          return html;
        },
        clone: function clone(elem, dataAndEvents, deepDataAndEvents) {
          var i,
              l,
              srcElements,
              destElements,
              clone = elem.cloneNode(true),
              inPage = isAttached(elem); 

          if (!support.noCloneChecked && (elem.nodeType === 1 || elem.nodeType === 11) && !jQuery.isXMLDoc(elem)) {
            destElements = getAll(clone);
            srcElements = getAll(elem);

            for (i = 0, l = srcElements.length; i < l; i++) {
              fixInput(srcElements[i], destElements[i]);
            }
          } 


          if (dataAndEvents) {
            if (deepDataAndEvents) {
              srcElements = srcElements || getAll(elem);
              destElements = destElements || getAll(clone);

              for (i = 0, l = srcElements.length; i < l; i++) {
                cloneCopyEvent(srcElements[i], destElements[i]);
              }
            } else {
              cloneCopyEvent(elem, clone);
            }
          } 


          destElements = getAll(clone, "script");

          if (destElements.length > 0) {
            setGlobalEval(destElements, !inPage && getAll(elem, "script"));
          } 


          return clone;
        },
        cleanData: function cleanData(elems) {
          var data,
              elem,
              type,
              special = jQuery.event.special,
              i = 0;

          for (; (elem = elems[i]) !== undefined; i++) {
            if (acceptData(elem)) {
              if (data = elem[dataPriv.expando]) {
                if (data.events) {
                  for (type in data.events) {
                    if (special[type]) {
                      jQuery.event.remove(elem, type); 
                    } else {
                      jQuery.removeEvent(elem, type, data.handle);
                    }
                  }
                } 


                elem[dataPriv.expando] = undefined;
              }

              if (elem[dataUser.expando]) {
                elem[dataUser.expando] = undefined;
              }
            }
          }
        }
      });
      jQuery.fn.extend({
        detach: function detach(selector) {
          return _remove(this, selector, true);
        },
        remove: function remove(selector) {
          return _remove(this, selector);
        },
        text: function text(value) {
          return access(this, function (value) {
            return value === undefined ? jQuery.text(this) : this.empty().each(function () {
              if (this.nodeType === 1 || this.nodeType === 11 || this.nodeType === 9) {
                this.textContent = value;
              }
            });
          }, null, value, arguments.length);
        },
        append: function append() {
          return domManip(this, arguments, function (elem) {
            if (this.nodeType === 1 || this.nodeType === 11 || this.nodeType === 9) {
              var target = manipulationTarget(this, elem);
              target.appendChild(elem);
            }
          });
        },
        prepend: function prepend() {
          return domManip(this, arguments, function (elem) {
            if (this.nodeType === 1 || this.nodeType === 11 || this.nodeType === 9) {
              var target = manipulationTarget(this, elem);
              target.insertBefore(elem, target.firstChild);
            }
          });
        },
        before: function before() {
          return domManip(this, arguments, function (elem) {
            if (this.parentNode) {
              this.parentNode.insertBefore(elem, this);
            }
          });
        },
        after: function after() {
          return domManip(this, arguments, function (elem) {
            if (this.parentNode) {
              this.parentNode.insertBefore(elem, this.nextSibling);
            }
          });
        },
        empty: function empty() {
          var elem,
              i = 0;

          for (; (elem = this[i]) != null; i++) {
            if (elem.nodeType === 1) {
              jQuery.cleanData(getAll(elem, false)); 

              elem.textContent = "";
            }
          }

          return this;
        },
        clone: function clone(dataAndEvents, deepDataAndEvents) {
          dataAndEvents = dataAndEvents == null ? false : dataAndEvents;
          deepDataAndEvents = deepDataAndEvents == null ? dataAndEvents : deepDataAndEvents;
          return this.map(function () {
            return jQuery.clone(this, dataAndEvents, deepDataAndEvents);
          });
        },
        html: function html(value) {
          return access(this, function (value) {
            var elem = this[0] || {},
                i = 0,
                l = this.length;

            if (value === undefined && elem.nodeType === 1) {
              return elem.innerHTML;
            } 


            if (typeof value === "string" && !rnoInnerhtml.test(value) && !wrapMap[(rtagName.exec(value) || ["", ""])[1].toLowerCase()]) {
              value = jQuery.htmlPrefilter(value);

              try {
                for (; i < l; i++) {
                  elem = this[i] || {}; 

                  if (elem.nodeType === 1) {
                    jQuery.cleanData(getAll(elem, false));
                    elem.innerHTML = value;
                  }
                }

                elem = 0; 
              } catch (e) {}
            }

            if (elem) {
              this.empty().append(value);
            }
          }, null, value, arguments.length);
        },
        replaceWith: function replaceWith() {
          var ignored = []; 

          return domManip(this, arguments, function (elem) {
            var parent = this.parentNode;

            if (jQuery.inArray(this, ignored) < 0) {
              jQuery.cleanData(getAll(this));

              if (parent) {
                parent.replaceChild(elem, this);
              }
            } 

          }, ignored);
        }
      });
      jQuery.each({
        appendTo: "append",
        prependTo: "prepend",
        insertBefore: "before",
        insertAfter: "after",
        replaceAll: "replaceWith"
      }, function (name, original) {
        jQuery.fn[name] = function (selector) {
          var elems,
              ret = [],
              insert = jQuery(selector),
              last = insert.length - 1,
              i = 0;

          for (; i <= last; i++) {
            elems = i === last ? this : this.clone(true);
            jQuery(insert[i])[original](elems); 

            push.apply(ret, elems.get());
          }

          return this.pushStack(ret);
        };
      });
      var rnumnonpx = new RegExp("^(" + pnum + ")(?!px)[a-z%]+$", "i");

      var getStyles = function getStyles(elem) {
        var view = elem.ownerDocument.defaultView;

        if (!view || !view.opener) {
          view = window;
        }

        return view.getComputedStyle(elem);
      };

      var swap = function swap(elem, options, callback) {
        var ret,
            name,
            old = {}; 

        for (name in options) {
          old[name] = elem.style[name];
          elem.style[name] = options[name];
        }

        ret = callback.call(elem); 

        for (name in options) {
          elem.style[name] = old[name];
        }

        return ret;
      };

      var rboxStyle = new RegExp(cssExpand.join("|"), "i");

      (function () {
        function computeStyleTests() {
          if (!div) {
            return;
          }

          container.style.cssText = "position:absolute;left:-11111px;width:60px;" + "margin-top:1px;padding:0;border:0";
          div.style.cssText = "position:relative;display:block;box-sizing:border-box;overflow:scroll;" + "margin:auto;border:1px;padding:1px;" + "width:60%;top:1%";
          documentElement.appendChild(container).appendChild(div);
          var divStyle = window.getComputedStyle(div);
          pixelPositionVal = divStyle.top !== "1%"; 

          reliableMarginLeftVal = roundPixelMeasures(divStyle.marginLeft) === 12; 

          div.style.right = "60%";
          pixelBoxStylesVal = roundPixelMeasures(divStyle.right) === 36; 

          boxSizingReliableVal = roundPixelMeasures(divStyle.width) === 36; 

          div.style.position = "absolute";
          scrollboxSizeVal = roundPixelMeasures(div.offsetWidth / 3) === 12;
          documentElement.removeChild(container); 

          div = null;
        }

        function roundPixelMeasures(measure) {
          return Math.round(parseFloat(measure));
        }

        var pixelPositionVal,
            boxSizingReliableVal,
            scrollboxSizeVal,
            pixelBoxStylesVal,
            reliableTrDimensionsVal,
            reliableMarginLeftVal,
            container = document.createElement("div"),
            div = document.createElement("div"); 

        if (!div.style) {
          return;
        } 


        div.style.backgroundClip = "content-box";
        div.cloneNode(true).style.backgroundClip = "";
        support.clearCloneStyle = div.style.backgroundClip === "content-box";
        jQuery.extend(support, {
          boxSizingReliable: function boxSizingReliable() {
            computeStyleTests();
            return boxSizingReliableVal;
          },
          pixelBoxStyles: function pixelBoxStyles() {
            computeStyleTests();
            return pixelBoxStylesVal;
          },
          pixelPosition: function pixelPosition() {
            computeStyleTests();
            return pixelPositionVal;
          },
          reliableMarginLeft: function reliableMarginLeft() {
            computeStyleTests();
            return reliableMarginLeftVal;
          },
          scrollboxSize: function scrollboxSize() {
            computeStyleTests();
            return scrollboxSizeVal;
          },
          reliableTrDimensions: function reliableTrDimensions() {
            var table, tr, trChild, trStyle;

            if (reliableTrDimensionsVal == null) {
              table = document.createElement("table");
              tr = document.createElement("tr");
              trChild = document.createElement("div");
              table.style.cssText = "position:absolute;left:-11111px";
              tr.style.height = "1px";
              trChild.style.height = "9px";
              documentElement.appendChild(table).appendChild(tr).appendChild(trChild);
              trStyle = window.getComputedStyle(tr);
              reliableTrDimensionsVal = parseInt(trStyle.height) > 3;
              documentElement.removeChild(table);
            }

            return reliableTrDimensionsVal;
          }
        });
      })();

      function curCSS(elem, name, computed) {
        var width,
            minWidth,
            maxWidth,
            ret,
        style = elem.style;
        computed = computed || getStyles(elem); 

        if (computed) {
          ret = computed.getPropertyValue(name) || computed[name];

          if (ret === "" && !isAttached(elem)) {
            ret = jQuery.style(elem, name);
          } 


          if (!support.pixelBoxStyles() && rnumnonpx.test(ret) && rboxStyle.test(name)) {
            width = style.width;
            minWidth = style.minWidth;
            maxWidth = style.maxWidth; 

            style.minWidth = style.maxWidth = style.width = ret;
            ret = computed.width; 

            style.width = width;
            style.minWidth = minWidth;
            style.maxWidth = maxWidth;
          }
        }

        return ret !== undefined ? 
        ret + "" : ret;
      }

      function addGetHookIf(conditionFn, hookFn) {
        return {
          get: function get() {
            if (conditionFn()) {
              delete this.get;
              return;
            } 


            return (this.get = hookFn).apply(this, arguments);
          }
        };
      }

      var cssPrefixes = ["Webkit", "Moz", "ms"],
          emptyStyle = document.createElement("div").style,
          vendorProps = {}; 

      function vendorPropName(name) {
        var capName = name[0].toUpperCase() + name.slice(1),
            i = cssPrefixes.length;

        while (i--) {
          name = cssPrefixes[i] + capName;

          if (name in emptyStyle) {
            return name;
          }
        }
      } 


      function finalPropName(name) {
        var _final = jQuery.cssProps[name] || vendorProps[name];

        if (_final) {
          return _final;
        }

        if (name in emptyStyle) {
          return name;
        }

        return vendorProps[name] = vendorPropName(name) || name;
      }

      var 
      rdisplayswap = /^(none|table(?!-c[ea]).+)/,
          rcustomProp = /^--/,
          cssShow = {
        position: "absolute",
        visibility: "hidden",
        display: "block"
      },
          cssNormalTransform = {
        letterSpacing: "0",
        fontWeight: "400"
      };

      function setPositiveNumber(_elem, value, subtract) {
        var matches = rcssNum.exec(value);
        return matches ? 
        Math.max(0, matches[2] - (subtract || 0)) + (matches[3] || "px") : value;
      }

      function boxModelAdjustment(elem, dimension, box, isBorderBox, styles, computedVal) {
        var i = dimension === "width" ? 1 : 0,
            extra = 0,
            delta = 0; 

        if (box === (isBorderBox ? "border" : "content")) {
          return 0;
        }

        for (; i < 4; i += 2) {
          if (box === "margin") {
            delta += jQuery.css(elem, box + cssExpand[i], true, styles);
          } 


          if (!isBorderBox) {
            delta += jQuery.css(elem, "padding" + cssExpand[i], true, styles); 

            if (box !== "padding") {
              delta += jQuery.css(elem, "border" + cssExpand[i] + "Width", true, styles); 
            } else {
              extra += jQuery.css(elem, "border" + cssExpand[i] + "Width", true, styles);
            } 

          } else {
            if (box === "content") {
              delta -= jQuery.css(elem, "padding" + cssExpand[i], true, styles);
            } 


            if (box !== "margin") {
              delta -= jQuery.css(elem, "border" + cssExpand[i] + "Width", true, styles);
            }
          }
        } 


        if (!isBorderBox && computedVal >= 0) {
          delta += Math.max(0, Math.ceil(elem["offset" + dimension[0].toUpperCase() + dimension.slice(1)] - computedVal - delta - extra - 0.5 
          )) || 0;
        }

        return delta;
      }

      function getWidthOrHeight(elem, dimension, extra) {
        var styles = getStyles(elem),
        boxSizingNeeded = !support.boxSizingReliable() || extra,
            isBorderBox = boxSizingNeeded && jQuery.css(elem, "boxSizing", false, styles) === "border-box",
            valueIsBorderBox = isBorderBox,
            val = curCSS(elem, dimension, styles),
            offsetProp = "offset" + dimension[0].toUpperCase() + dimension.slice(1); 

        if (rnumnonpx.test(val)) {
          if (!extra) {
            return val;
          }

          val = "auto";
        } 


        if ((!support.boxSizingReliable() && isBorderBox || 
        !support.reliableTrDimensions() && nodeName(elem, "tr") || 
        val === "auto" || 
        !parseFloat(val) && jQuery.css(elem, "display", false, styles) === "inline") && 
        elem.getClientRects().length) {
          isBorderBox = jQuery.css(elem, "boxSizing", false, styles) === "border-box"; 

          valueIsBorderBox = offsetProp in elem;

          if (valueIsBorderBox) {
            val = elem[offsetProp];
          }
        } 


        val = parseFloat(val) || 0; 

        return val + boxModelAdjustment(elem, dimension, extra || (isBorderBox ? "border" : "content"), valueIsBorderBox, styles, 
        val) + "px";
      }

      jQuery.extend({
        cssHooks: {
          opacity: {
            get: function get(elem, computed) {
              if (computed) {
                var ret = curCSS(elem, "opacity");
                return ret === "" ? "1" : ret;
              }
            }
          }
        },
        cssNumber: {
          "animationIterationCount": true,
          "columnCount": true,
          "fillOpacity": true,
          "flexGrow": true,
          "flexShrink": true,
          "fontWeight": true,
          "gridArea": true,
          "gridColumn": true,
          "gridColumnEnd": true,
          "gridColumnStart": true,
          "gridRow": true,
          "gridRowEnd": true,
          "gridRowStart": true,
          "lineHeight": true,
          "opacity": true,
          "order": true,
          "orphans": true,
          "widows": true,
          "zIndex": true,
          "zoom": true
        },
        cssProps: {},
        style: function style(elem, name, value, extra) {
          if (!elem || elem.nodeType === 3 || elem.nodeType === 8 || !elem.style) {
            return;
          } 


          var ret,
              type,
              hooks,
              origName = camelCase(name),
              isCustomProp = rcustomProp.test(name),
              style = elem.style; 

          if (!isCustomProp) {
            name = finalPropName(origName);
          } 


          hooks = jQuery.cssHooks[name] || jQuery.cssHooks[origName]; 

          if (value !== undefined) {
            type = _typeof(value); 

            if (type === "string" && (ret = rcssNum.exec(value)) && ret[1]) {
              value = adjustCSS(elem, name, ret); 

              type = "number";
            } 


            if (value == null || value !== value) {
              return;
            } 


            if (type === "number" && !isCustomProp) {
              value += ret && ret[3] || (jQuery.cssNumber[origName] ? "" : "px");
            } 


            if (!support.clearCloneStyle && value === "" && name.indexOf("background") === 0) {
              style[name] = "inherit";
            } 


            if (!hooks || !("set" in hooks) || (value = hooks.set(elem, value, extra)) !== undefined) {
              if (isCustomProp) {
                style.setProperty(name, value);
              } else {
                style[name] = value;
              }
            }
          } else {
            if (hooks && "get" in hooks && (ret = hooks.get(elem, false, extra)) !== undefined) {
              return ret;
            } 


            return style[name];
          }
        },
        css: function css(elem, name, extra, styles) {
          var val,
              num,
              hooks,
              origName = camelCase(name),
              isCustomProp = rcustomProp.test(name); 

          if (!isCustomProp) {
            name = finalPropName(origName);
          } 


          hooks = jQuery.cssHooks[name] || jQuery.cssHooks[origName]; 

          if (hooks && "get" in hooks) {
            val = hooks.get(elem, true, extra);
          } 


          if (val === undefined) {
            val = curCSS(elem, name, styles);
          } 


          if (val === "normal" && name in cssNormalTransform) {
            val = cssNormalTransform[name];
          } 


          if (extra === "" || extra) {
            num = parseFloat(val);
            return extra === true || isFinite(num) ? num || 0 : val;
          }

          return val;
        }
      });
      jQuery.each(["height", "width"], function (_i, dimension) {
        jQuery.cssHooks[dimension] = {
          get: function get(elem, computed, extra) {
            if (computed) {
              return rdisplayswap.test(jQuery.css(elem, "display")) && ( 
              !elem.getClientRects().length || !elem.getBoundingClientRect().width) ? swap(elem, cssShow, function () {
                return getWidthOrHeight(elem, dimension, extra);
              }) : getWidthOrHeight(elem, dimension, extra);
            }
          },
          set: function set(elem, value, extra) {
            var matches,
                styles = getStyles(elem),
            scrollboxSizeBuggy = !support.scrollboxSize() && styles.position === "absolute",
            boxSizingNeeded = scrollboxSizeBuggy || extra,
                isBorderBox = boxSizingNeeded && jQuery.css(elem, "boxSizing", false, styles) === "border-box",
                subtract = extra ? boxModelAdjustment(elem, dimension, extra, isBorderBox, styles) : 0; 

            if (isBorderBox && scrollboxSizeBuggy) {
              subtract -= Math.ceil(elem["offset" + dimension[0].toUpperCase() + dimension.slice(1)] - parseFloat(styles[dimension]) - boxModelAdjustment(elem, dimension, "border", false, styles) - 0.5);
            } 


            if (subtract && (matches = rcssNum.exec(value)) && (matches[3] || "px") !== "px") {
              elem.style[dimension] = value;
              value = jQuery.css(elem, dimension);
            }

            return setPositiveNumber(elem, value, subtract);
          }
        };
      });
      jQuery.cssHooks.marginLeft = addGetHookIf(support.reliableMarginLeft, function (elem, computed) {
        if (computed) {
          return (parseFloat(curCSS(elem, "marginLeft")) || elem.getBoundingClientRect().left - swap(elem, {
            marginLeft: 0
          }, function () {
            return elem.getBoundingClientRect().left;
          })) + "px";
        }
      }); 

      jQuery.each({
        margin: "",
        padding: "",
        border: "Width"
      }, function (prefix, suffix) {
        jQuery.cssHooks[prefix + suffix] = {
          expand: function expand(value) {
            var i = 0,
                expanded = {},
            parts = typeof value === "string" ? value.split(" ") : [value];

            for (; i < 4; i++) {
              expanded[prefix + cssExpand[i] + suffix] = parts[i] || parts[i - 2] || parts[0];
            }

            return expanded;
          }
        };

        if (prefix !== "margin") {
          jQuery.cssHooks[prefix + suffix].set = setPositiveNumber;
        }
      });
      jQuery.fn.extend({
        css: function css(name, value) {
          return access(this, function (elem, name, value) {
            var styles,
                len,
                map = {},
                i = 0;

            if (Array.isArray(name)) {
              styles = getStyles(elem);
              len = name.length;

              for (; i < len; i++) {
                map[name[i]] = jQuery.css(elem, name[i], false, styles);
              }

              return map;
            }

            return value !== undefined ? jQuery.style(elem, name, value) : jQuery.css(elem, name);
          }, name, value, arguments.length > 1);
        }
      });

      function Tween(elem, options, prop, end, easing) {
        return new Tween.prototype.init(elem, options, prop, end, easing);
      }

      jQuery.Tween = Tween;
      Tween.prototype = {
        constructor: Tween,
        init: function init(elem, options, prop, end, easing, unit) {
          this.elem = elem;
          this.prop = prop;
          this.easing = easing || jQuery.easing._default;
          this.options = options;
          this.start = this.now = this.cur();
          this.end = end;
          this.unit = unit || (jQuery.cssNumber[prop] ? "" : "px");
        },
        cur: function cur() {
          var hooks = Tween.propHooks[this.prop];
          return hooks && hooks.get ? hooks.get(this) : Tween.propHooks._default.get(this);
        },
        run: function run(percent) {
          var eased,
              hooks = Tween.propHooks[this.prop];

          if (this.options.duration) {
            this.pos = eased = jQuery.easing[this.easing](percent, this.options.duration * percent, 0, 1, this.options.duration);
          } else {
            this.pos = eased = percent;
          }

          this.now = (this.end - this.start) * eased + this.start;

          if (this.options.step) {
            this.options.step.call(this.elem, this.now, this);
          }

          if (hooks && hooks.set) {
            hooks.set(this);
          } else {
            Tween.propHooks._default.set(this);
          }

          return this;
        }
      };
      Tween.prototype.init.prototype = Tween.prototype;
      Tween.propHooks = {
        _default: {
          get: function get(tween) {
            var result; 

            if (tween.elem.nodeType !== 1 || tween.elem[tween.prop] != null && tween.elem.style[tween.prop] == null) {
              return tween.elem[tween.prop];
            } 


            result = jQuery.css(tween.elem, tween.prop, ""); 

            return !result || result === "auto" ? 0 : result;
          },
          set: function set(tween) {
            if (jQuery.fx.step[tween.prop]) {
              jQuery.fx.step[tween.prop](tween);
            } else if (tween.elem.nodeType === 1 && (jQuery.cssHooks[tween.prop] || tween.elem.style[finalPropName(tween.prop)] != null)) {
              jQuery.style(tween.elem, tween.prop, tween.now + tween.unit);
            } else {
              tween.elem[tween.prop] = tween.now;
            }
          }
        }
      }; 

      Tween.propHooks.scrollTop = Tween.propHooks.scrollLeft = {
        set: function set(tween) {
          if (tween.elem.nodeType && tween.elem.parentNode) {
            tween.elem[tween.prop] = tween.now;
          }
        }
      };
      jQuery.easing = {
        linear: function linear(p) {
          return p;
        },
        swing: function swing(p) {
          return 0.5 - Math.cos(p * Math.PI) / 2;
        },
        _default: "swing"
      };
      jQuery.fx = Tween.prototype.init; 

      jQuery.fx.step = {};
      var fxNow,
          inProgress,
          rfxtypes = /^(?:toggle|show|hide)$/,
          rrun = /queueHooks$/;

      function schedule() {
        if (inProgress) {
          if (document.hidden === false && window.requestAnimationFrame) {
            window.requestAnimationFrame(schedule);
          } else {
            window.setTimeout(schedule, jQuery.fx.interval);
          }

          jQuery.fx.tick();
        }
      } 


      function createFxNow() {
        window.setTimeout(function () {
          fxNow = undefined;
        });
        return fxNow = Date.now();
      } 


      function genFx(type, includeWidth) {
        var which,
            i = 0,
            attrs = {
          height: type
        }; 

        includeWidth = includeWidth ? 1 : 0;

        for (; i < 4; i += 2 - includeWidth) {
          which = cssExpand[i];
          attrs["margin" + which] = attrs["padding" + which] = type;
        }

        if (includeWidth) {
          attrs.opacity = attrs.width = type;
        }

        return attrs;
      }

      function createTween(value, prop, animation) {
        var tween,
            collection = (Animation.tweeners[prop] || []).concat(Animation.tweeners["*"]),
            index = 0,
            length = collection.length;

        for (; index < length; index++) {
          if (tween = collection[index].call(animation, prop, value)) {
            return tween;
          }
        }
      }

      function defaultPrefilter(elem, props, opts) {
        var prop,
            value,
            toggle,
            hooks,
            oldfire,
            propTween,
            restoreDisplay,
            display,
            isBox = "width" in props || "height" in props,
            anim = this,
            orig = {},
            style = elem.style,
            hidden = elem.nodeType && isHiddenWithinTree(elem),
            dataShow = dataPriv.get(elem, "fxshow"); 

        if (!opts.queue) {
          hooks = jQuery._queueHooks(elem, "fx");

          if (hooks.unqueued == null) {
            hooks.unqueued = 0;
            oldfire = hooks.empty.fire;

            hooks.empty.fire = function () {
              if (!hooks.unqueued) {
                oldfire();
              }
            };
          }

          hooks.unqueued++;
          anim.always(function () {
            anim.always(function () {
              hooks.unqueued--;

              if (!jQuery.queue(elem, "fx").length) {
                hooks.empty.fire();
              }
            });
          });
        } 


        for (prop in props) {
          value = props[prop];

          if (rfxtypes.test(value)) {
            delete props[prop];
            toggle = toggle || value === "toggle";

            if (value === (hidden ? "hide" : "show")) {
              if (value === "show" && dataShow && dataShow[prop] !== undefined) {
                hidden = true; 
              } else {
                continue;
              }
            }

            orig[prop] = dataShow && dataShow[prop] || jQuery.style(elem, prop);
          }
        } 


        propTween = !jQuery.isEmptyObject(props);

        if (!propTween && jQuery.isEmptyObject(orig)) {
          return;
        } 


        if (isBox && elem.nodeType === 1) {
          opts.overflow = [style.overflow, style.overflowX, style.overflowY]; 

          restoreDisplay = dataShow && dataShow.display;

          if (restoreDisplay == null) {
            restoreDisplay = dataPriv.get(elem, "display");
          }

          display = jQuery.css(elem, "display");

          if (display === "none") {
            if (restoreDisplay) {
              display = restoreDisplay;
            } else {
              showHide([elem], true);
              restoreDisplay = elem.style.display || restoreDisplay;
              display = jQuery.css(elem, "display");
              showHide([elem]);
            }
          } 


          if (display === "inline" || display === "inline-block" && restoreDisplay != null) {
            if (jQuery.css(elem, "float") === "none") {
              if (!propTween) {
                anim.done(function () {
                  style.display = restoreDisplay;
                });

                if (restoreDisplay == null) {
                  display = style.display;
                  restoreDisplay = display === "none" ? "" : display;
                }
              }

              style.display = "inline-block";
            }
          }
        }

        if (opts.overflow) {
          style.overflow = "hidden";
          anim.always(function () {
            style.overflow = opts.overflow[0];
            style.overflowX = opts.overflow[1];
            style.overflowY = opts.overflow[2];
          });
        } 


        propTween = false;

        for (prop in orig) {
          if (!propTween) {
            if (dataShow) {
              if ("hidden" in dataShow) {
                hidden = dataShow.hidden;
              }
            } else {
              dataShow = dataPriv.access(elem, "fxshow", {
                display: restoreDisplay
              });
            } 


            if (toggle) {
              dataShow.hidden = !hidden;
            } 


            if (hidden) {
              showHide([elem], true);
            }


            anim.done(function () {
              if (!hidden) {
                showHide([elem]);
              }

              dataPriv.remove(elem, "fxshow");

              for (prop in orig) {
                jQuery.style(elem, prop, orig[prop]);
              }
            });
          } 


          propTween = createTween(hidden ? dataShow[prop] : 0, prop, anim);

          if (!(prop in dataShow)) {
            dataShow[prop] = propTween.start;

            if (hidden) {
              propTween.end = propTween.start;
              propTween.start = 0;
            }
          }
        }
      }

      function propFilter(props, specialEasing) {
        var index, name, easing, value, hooks; 

        for (index in props) {
          name = camelCase(index);
          easing = specialEasing[name];
          value = props[index];

          if (Array.isArray(value)) {
            easing = value[1];
            value = props[index] = value[0];
          }

          if (index !== name) {
            props[name] = value;
            delete props[index];
          }

          hooks = jQuery.cssHooks[name];

          if (hooks && "expand" in hooks) {
            value = hooks.expand(value);
            delete props[name]; 

            for (index in value) {
              if (!(index in props)) {
                props[index] = value[index];
                specialEasing[index] = easing;
              }
            }
          } else {
            specialEasing[name] = easing;
          }
        }
      }

      function Animation(elem, properties, options) {
        var result,
            stopped,
            index = 0,
            length = Animation.prefilters.length,
            deferred = jQuery.Deferred().always(function () {
          delete tick.elem;
        }),
            tick = function tick() {
          if (stopped) {
            return false;
          }

          var currentTime = fxNow || createFxNow(),
              remaining = Math.max(0, animation.startTime + animation.duration - currentTime),
          temp = remaining / animation.duration || 0,
              percent = 1 - temp,
              index = 0,
              length = animation.tweens.length;

          for (; index < length; index++) {
            animation.tweens[index].run(percent);
          }

          deferred.notifyWith(elem, [animation, percent, remaining]); 

          if (percent < 1 && length) {
            return remaining;
          } 


          if (!length) {
            deferred.notifyWith(elem, [animation, 1, 0]);
          } 


          deferred.resolveWith(elem, [animation]);
          return false;
        },
            animation = deferred.promise({
          elem: elem,
          props: jQuery.extend({}, properties),
          opts: jQuery.extend(true, {
            specialEasing: {},
            easing: jQuery.easing._default
          }, options),
          originalProperties: properties,
          originalOptions: options,
          startTime: fxNow || createFxNow(),
          duration: options.duration,
          tweens: [],
          createTween: function createTween(prop, end) {
            var tween = jQuery.Tween(elem, animation.opts, prop, end, animation.opts.specialEasing[prop] || animation.opts.easing);
            animation.tweens.push(tween);
            return tween;
          },
          stop: function stop(gotoEnd) {
            var index = 0,
            length = gotoEnd ? animation.tweens.length : 0;

            if (stopped) {
              return this;
            }

            stopped = true;

            for (; index < length; index++) {
              animation.tweens[index].run(1);
            } 


            if (gotoEnd) {
              deferred.notifyWith(elem, [animation, 1, 0]);
              deferred.resolveWith(elem, [animation, gotoEnd]);
            } else {
              deferred.rejectWith(elem, [animation, gotoEnd]);
            }

            return this;
          }
        }),
            props = animation.props;

        propFilter(props, animation.opts.specialEasing);

        for (; index < length; index++) {
          result = Animation.prefilters[index].call(animation, elem, props, animation.opts);

          if (result) {
            if (isFunction(result.stop)) {
              jQuery._queueHooks(animation.elem, animation.opts.queue).stop = result.stop.bind(result);
            }

            return result;
          }
        }

        jQuery.map(props, createTween, animation);

        if (isFunction(animation.opts.start)) {
          animation.opts.start.call(elem, animation);
        } 


        animation.progress(animation.opts.progress).done(animation.opts.done, animation.opts.complete).fail(animation.opts.fail).always(animation.opts.always);
        jQuery.fx.timer(jQuery.extend(tick, {
          elem: elem,
          anim: animation,
          queue: animation.opts.queue
        }));
        return animation;
      }

      jQuery.Animation = jQuery.extend(Animation, {
        tweeners: {
          "*": [function (prop, value) {
            var tween = this.createTween(prop, value);
            adjustCSS(tween.elem, prop, rcssNum.exec(value), tween);
            return tween;
          }]
        },
        tweener: function tweener(props, callback) {
          if (isFunction(props)) {
            callback = props;
            props = ["*"];
          } else {
            props = props.match(rnothtmlwhite);
          }

          var prop,
              index = 0,
              length = props.length;

          for (; index < length; index++) {
            prop = props[index];
            Animation.tweeners[prop] = Animation.tweeners[prop] || [];
            Animation.tweeners[prop].unshift(callback);
          }
        },
        prefilters: [defaultPrefilter],
        prefilter: function prefilter(callback, prepend) {
          if (prepend) {
            Animation.prefilters.unshift(callback);
          } else {
            Animation.prefilters.push(callback);
          }
        }
      });

      jQuery.speed = function (speed, easing, fn) {
        var opt = speed && _typeof(speed) === "object" ? jQuery.extend({}, speed) : {
          complete: fn || !fn && easing || isFunction(speed) && speed,
          duration: speed,
          easing: fn && easing || easing && !isFunction(easing) && easing
        }; 

        if (jQuery.fx.off) {
          opt.duration = 0;
        } else {
          if (typeof opt.duration !== "number") {
            if (opt.duration in jQuery.fx.speeds) {
              opt.duration = jQuery.fx.speeds[opt.duration];
            } else {
              opt.duration = jQuery.fx.speeds._default;
            }
          }
        } 


        if (opt.queue == null || opt.queue === true) {
          opt.queue = "fx";
        } 


        opt.old = opt.complete;

        opt.complete = function () {
          if (isFunction(opt.old)) {
            opt.old.call(this);
          }

          if (opt.queue) {
            jQuery.dequeue(this, opt.queue);
          }
        };

        return opt;
      };

      jQuery.fn.extend({
        fadeTo: function fadeTo(speed, to, easing, callback) {
          return this.filter(isHiddenWithinTree).css("opacity", 0).show() 
          .end().animate({
            opacity: to
          }, speed, easing, callback);
        },
        animate: function animate(prop, speed, easing, callback) {
          var empty = jQuery.isEmptyObject(prop),
              optall = jQuery.speed(speed, easing, callback),
              doAnimation = function doAnimation() {
            var anim = Animation(this, jQuery.extend({}, prop), optall); 

            if (empty || dataPriv.get(this, "finish")) {
              anim.stop(true);
            }
          };

          doAnimation.finish = doAnimation;
          return empty || optall.queue === false ? this.each(doAnimation) : this.queue(optall.queue, doAnimation);
        },
        stop: function stop(type, clearQueue, gotoEnd) {
          var stopQueue = function stopQueue(hooks) {
            var stop = hooks.stop;
            delete hooks.stop;
            stop(gotoEnd);
          };

          if (typeof type !== "string") {
            gotoEnd = clearQueue;
            clearQueue = type;
            type = undefined;
          }

          if (clearQueue) {
            this.queue(type || "fx", []);
          }

          return this.each(function () {
            var dequeue = true,
                index = type != null && type + "queueHooks",
                timers = jQuery.timers,
                data = dataPriv.get(this);

            if (index) {
              if (data[index] && data[index].stop) {
                stopQueue(data[index]);
              }
            } else {
              for (index in data) {
                if (data[index] && data[index].stop && rrun.test(index)) {
                  stopQueue(data[index]);
                }
              }
            }

            for (index = timers.length; index--;) {
              if (timers[index].elem === this && (type == null || timers[index].queue === type)) {
                timers[index].anim.stop(gotoEnd);
                dequeue = false;
                timers.splice(index, 1);
              }
            } 


            if (dequeue || !gotoEnd) {
              jQuery.dequeue(this, type);
            }
          });
        },
        finish: function finish(type) {
          if (type !== false) {
            type = type || "fx";
          }

          return this.each(function () {
            var index,
                data = dataPriv.get(this),
                queue = data[type + "queue"],
                hooks = data[type + "queueHooks"],
                timers = jQuery.timers,
                length = queue ? queue.length : 0; 

            data.finish = true; 

            jQuery.queue(this, type, []);

            if (hooks && hooks.stop) {
              hooks.stop.call(this, true);
            } 


            for (index = timers.length; index--;) {
              if (timers[index].elem === this && timers[index].queue === type) {
                timers[index].anim.stop(true);
                timers.splice(index, 1);
              }
            } 


            for (index = 0; index < length; index++) {
              if (queue[index] && queue[index].finish) {
                queue[index].finish.call(this);
              }
            } 


            delete data.finish;
          });
        }
      });
      jQuery.each(["toggle", "show", "hide"], function (_i, name) {
        var cssFn = jQuery.fn[name];

        jQuery.fn[name] = function (speed, easing, callback) {
          return speed == null || typeof speed === "boolean" ? cssFn.apply(this, arguments) : this.animate(genFx(name, true), speed, easing, callback);
        };
      }); 

      jQuery.each({
        slideDown: genFx("show"),
        slideUp: genFx("hide"),
        slideToggle: genFx("toggle"),
        fadeIn: {
          opacity: "show"
        },
        fadeOut: {
          opacity: "hide"
        },
        fadeToggle: {
          opacity: "toggle"
        }
      }, function (name, props) {
        jQuery.fn[name] = function (speed, easing, callback) {
          return this.animate(props, speed, easing, callback);
        };
      });
      jQuery.timers = [];

      jQuery.fx.tick = function () {
        var timer,
            i = 0,
            timers = jQuery.timers;
        fxNow = Date.now();

        for (; i < timers.length; i++) {
          timer = timers[i]; 

          if (!timer() && timers[i] === timer) {
            timers.splice(i--, 1);
          }
        }

        if (!timers.length) {
          jQuery.fx.stop();
        }

        fxNow = undefined;
      };

      jQuery.fx.timer = function (timer) {
        jQuery.timers.push(timer);
        jQuery.fx.start();
      };

      jQuery.fx.interval = 13;

      jQuery.fx.start = function () {
        if (inProgress) {
          return;
        }

        inProgress = true;
        schedule();
      };

      jQuery.fx.stop = function () {
        inProgress = null;
      };

      jQuery.fx.speeds = {
        slow: 600,
        fast: 200,
        _default: 400
      }; 

      jQuery.fn.delay = function (time, type) {
        time = jQuery.fx ? jQuery.fx.speeds[time] || time : time;
        type = type || "fx";
        return this.queue(type, function (next, hooks) {
          var timeout = window.setTimeout(next, time);

          hooks.stop = function () {
            window.clearTimeout(timeout);
          };
        });
      };

      (function () {
        var input = document.createElement("input"),
            select = document.createElement("select"),
            opt = select.appendChild(document.createElement("option"));
        input.type = "checkbox"; 

        support.checkOn = input.value !== ""; 

        support.optSelected = opt.selected; 

        input = document.createElement("input");
        input.value = "t";
        input.type = "radio";
        support.radioValue = input.value === "t";
      })();

      var boolHook,
          attrHandle = jQuery.expr.attrHandle;
      jQuery.fn.extend({
        attr: function attr(name, value) {
          return access(this, jQuery.attr, name, value, arguments.length > 1);
        },
        removeAttr: function removeAttr(name) {
          return this.each(function () {
            jQuery.removeAttr(this, name);
          });
        }
      });
      jQuery.extend({
        attr: function attr(elem, name, value) {
          var ret,
              hooks,
              nType = elem.nodeType; 

          if (nType === 3 || nType === 8 || nType === 2) {
            return;
          } 


          if (typeof elem.getAttribute === "undefined") {
            return jQuery.prop(elem, name, value);
          } 


          if (nType !== 1 || !jQuery.isXMLDoc(elem)) {
            hooks = jQuery.attrHooks[name.toLowerCase()] || (jQuery.expr.match.bool.test(name) ? boolHook : undefined);
          }

          if (value !== undefined) {
            if (value === null) {
              jQuery.removeAttr(elem, name);
              return;
            }

            if (hooks && "set" in hooks && (ret = hooks.set(elem, value, name)) !== undefined) {
              return ret;
            }

            elem.setAttribute(name, value + "");
            return value;
          }

          if (hooks && "get" in hooks && (ret = hooks.get(elem, name)) !== null) {
            return ret;
          }

          ret = jQuery.find.attr(elem, name); 

          return ret == null ? undefined : ret;
        },
        attrHooks: {
          type: {
            set: function set(elem, value) {
              if (!support.radioValue && value === "radio" && nodeName(elem, "input")) {
                var val = elem.value;
                elem.setAttribute("type", value);

                if (val) {
                  elem.value = val;
                }

                return value;
              }
            }
          }
        },
        removeAttr: function removeAttr(elem, value) {
          var name,
              i = 0,
          attrNames = value && value.match(rnothtmlwhite);

          if (attrNames && elem.nodeType === 1) {
            while (name = attrNames[i++]) {
              elem.removeAttribute(name);
            }
          }
        }
      }); 

      boolHook = {
        set: function set(elem, value, name) {
          if (value === false) {
            jQuery.removeAttr(elem, name);
          } else {
            elem.setAttribute(name, name);
          }

          return name;
        }
      };
      jQuery.each(jQuery.expr.match.bool.source.match(/\w+/g), function (_i, name) {
        var getter = attrHandle[name] || jQuery.find.attr;

        attrHandle[name] = function (elem, name, isXML) {
          var ret,
              handle,
              lowercaseName = name.toLowerCase();

          if (!isXML) {
            handle = attrHandle[lowercaseName];
            attrHandle[lowercaseName] = ret;
            ret = getter(elem, name, isXML) != null ? lowercaseName : null;
            attrHandle[lowercaseName] = handle;
          }

          return ret;
        };
      });
      var rfocusable = /^(?:input|select|textarea|button)$/i,
          rclickable = /^(?:a|area)$/i;
      jQuery.fn.extend({
        prop: function prop(name, value) {
          return access(this, jQuery.prop, name, value, arguments.length > 1);
        },
        removeProp: function removeProp(name) {
          return this.each(function () {
            delete this[jQuery.propFix[name] || name];
          });
        }
      });
      jQuery.extend({
        prop: function prop(elem, name, value) {
          var ret,
              hooks,
              nType = elem.nodeType; 

          if (nType === 3 || nType === 8 || nType === 2) {
            return;
          }

          if (nType !== 1 || !jQuery.isXMLDoc(elem)) {
            name = jQuery.propFix[name] || name;
            hooks = jQuery.propHooks[name];
          }

          if (value !== undefined) {
            if (hooks && "set" in hooks && (ret = hooks.set(elem, value, name)) !== undefined) {
              return ret;
            }

            return elem[name] = value;
          }

          if (hooks && "get" in hooks && (ret = hooks.get(elem, name)) !== null) {
            return ret;
          }

          return elem[name];
        },
        propHooks: {
          tabIndex: {
            get: function get(elem) {
              var tabindex = jQuery.find.attr(elem, "tabindex");

              if (tabindex) {
                return parseInt(tabindex, 10);
              }

              if (rfocusable.test(elem.nodeName) || rclickable.test(elem.nodeName) && elem.href) {
                return 0;
              }

              return -1;
            }
          }
        },
        propFix: {
          "for": "htmlFor",
          "class": "className"
        }
      }); 

      if (!support.optSelected) {
        jQuery.propHooks.selected = {
          get: function get(elem) {
            var parent = elem.parentNode;

            if (parent && parent.parentNode) {
              parent.parentNode.selectedIndex;
            }

            return null;
          },
          set: function set(elem) {
            var parent = elem.parentNode;

            if (parent) {
              parent.selectedIndex;

              if (parent.parentNode) {
                parent.parentNode.selectedIndex;
              }
            }
          }
        };
      }

      jQuery.each(["tabIndex", "readOnly", "maxLength", "cellSpacing", "cellPadding", "rowSpan", "colSpan", "useMap", "frameBorder", "contentEditable"], function () {
        jQuery.propFix[this.toLowerCase()] = this;
      }); 

      function stripAndCollapse(value) {
        var tokens = value.match(rnothtmlwhite) || [];
        return tokens.join(" ");
      }

      function getClass(elem) {
        return elem.getAttribute && elem.getAttribute("class") || "";
      }

      function classesToArray(value) {
        if (Array.isArray(value)) {
          return value;
        }

        if (typeof value === "string") {
          return value.match(rnothtmlwhite) || [];
        }

        return [];
      }

      jQuery.fn.extend({
        addClass: function addClass(value) {
          var classes,
              elem,
              cur,
              curValue,
              clazz,
              j,
              finalValue,
              i = 0;

          if (isFunction(value)) {
            return this.each(function (j) {
              jQuery(this).addClass(value.call(this, j, getClass(this)));
            });
          }

          classes = classesToArray(value);

          if (classes.length) {
            while (elem = this[i++]) {
              curValue = getClass(elem);
              cur = elem.nodeType === 1 && " " + stripAndCollapse(curValue) + " ";

              if (cur) {
                j = 0;

                while (clazz = classes[j++]) {
                  if (cur.indexOf(" " + clazz + " ") < 0) {
                    cur += clazz + " ";
                  }
                } 


                finalValue = stripAndCollapse(cur);

                if (curValue !== finalValue) {
                  elem.setAttribute("class", finalValue);
                }
              }
            }
          }

          return this;
        },
        removeClass: function removeClass(value) {
          var classes,
              elem,
              cur,
              curValue,
              clazz,
              j,
              finalValue,
              i = 0;

          if (isFunction(value)) {
            return this.each(function (j) {
              jQuery(this).removeClass(value.call(this, j, getClass(this)));
            });
          }

          if (!arguments.length) {
            return this.attr("class", "");
          }

          classes = classesToArray(value);

          if (classes.length) {
            while (elem = this[i++]) {
              curValue = getClass(elem); 

              cur = elem.nodeType === 1 && " " + stripAndCollapse(curValue) + " ";

              if (cur) {
                j = 0;

                while (clazz = classes[j++]) {
                  while (cur.indexOf(" " + clazz + " ") > -1) {
                    cur = cur.replace(" " + clazz + " ", " ");
                  }
                } 


                finalValue = stripAndCollapse(cur);

                if (curValue !== finalValue) {
                  elem.setAttribute("class", finalValue);
                }
              }
            }
          }

          return this;
        },
        toggleClass: function toggleClass(value, stateVal) {
          var type = _typeof(value),
              isValidValue = type === "string" || Array.isArray(value);

          if (typeof stateVal === "boolean" && isValidValue) {
            return stateVal ? this.addClass(value) : this.removeClass(value);
          }

          if (isFunction(value)) {
            return this.each(function (i) {
              jQuery(this).toggleClass(value.call(this, i, getClass(this), stateVal), stateVal);
            });
          }

          return this.each(function () {
            var className, i, self, classNames;

            if (isValidValue) {
              i = 0;
              self = jQuery(this);
              classNames = classesToArray(value);

              while (className = classNames[i++]) {
                if (self.hasClass(className)) {
                  self.removeClass(className);
                } else {
                  self.addClass(className);
                }
              } 

            } else if (value === undefined || type === "boolean") {
              className = getClass(this);

              if (className) {
                dataPriv.set(this, "__className__", className);
              } 


              if (this.setAttribute) {
                this.setAttribute("class", className || value === false ? "" : dataPriv.get(this, "__className__") || "");
              }
            }
          });
        },
        hasClass: function hasClass(selector) {
          var className,
              elem,
              i = 0;
          className = " " + selector + " ";

          while (elem = this[i++]) {
            if (elem.nodeType === 1 && (" " + stripAndCollapse(getClass(elem)) + " ").indexOf(className) > -1) {
              return true;
            }
          }

          return false;
        }
      });
      var rreturn = /\r/g;
      jQuery.fn.extend({
        val: function val(value) {
          var hooks,
              ret,
              valueIsFunction,
              elem = this[0];

          if (!arguments.length) {
            if (elem) {
              hooks = jQuery.valHooks[elem.type] || jQuery.valHooks[elem.nodeName.toLowerCase()];

              if (hooks && "get" in hooks && (ret = hooks.get(elem, "value")) !== undefined) {
                return ret;
              }

              ret = elem.value; 

              if (typeof ret === "string") {
                return ret.replace(rreturn, "");
              } 


              return ret == null ? "" : ret;
            }

            return;
          }

          valueIsFunction = isFunction(value);
          return this.each(function (i) {
            var val;

            if (this.nodeType !== 1) {
              return;
            }

            if (valueIsFunction) {
              val = value.call(this, i, jQuery(this).val());
            } else {
              val = value;
            } 


            if (val == null) {
              val = "";
            } else if (typeof val === "number") {
              val += "";
            } else if (Array.isArray(val)) {
              val = jQuery.map(val, function (value) {
                return value == null ? "" : value + "";
              });
            }

            hooks = jQuery.valHooks[this.type] || jQuery.valHooks[this.nodeName.toLowerCase()]; 

            if (!hooks || !("set" in hooks) || hooks.set(this, val, "value") === undefined) {
              this.value = val;
            }
          });
        }
      });
      jQuery.extend({
        valHooks: {
          option: {
            get: function get(elem) {
              var val = jQuery.find.attr(elem, "value");
              return val != null ? val : 
              stripAndCollapse(jQuery.text(elem));
            }
          },
          select: {
            get: function get(elem) {
              var value,
                  option,
                  i,
                  options = elem.options,
                  index = elem.selectedIndex,
                  one = elem.type === "select-one",
                  values = one ? null : [],
                  max = one ? index + 1 : options.length;

              if (index < 0) {
                i = max;
              } else {
                i = one ? index : 0;
              } 


              for (; i < max; i++) {
                option = options[i]; 

                if ((option.selected || i === index) && 
                !option.disabled && (!option.parentNode.disabled || !nodeName(option.parentNode, "optgroup"))) {
                  value = jQuery(option).val(); 

                  if (one) {
                    return value;
                  } 


                  values.push(value);
                }
              }

              return values;
            },
            set: function set(elem, value) {
              var optionSet,
                  option,
                  options = elem.options,
                  values = jQuery.makeArray(value),
                  i = options.length;

              while (i--) {
                option = options[i];

                if (option.selected = jQuery.inArray(jQuery.valHooks.option.get(option), values) > -1) {
                  optionSet = true;
                }

              } 


              if (!optionSet) {
                elem.selectedIndex = -1;
              }

              return values;
            }
          }
        }
      }); 

      jQuery.each(["radio", "checkbox"], function () {
        jQuery.valHooks[this] = {
          set: function set(elem, value) {
            if (Array.isArray(value)) {
              return elem.checked = jQuery.inArray(jQuery(elem).val(), value) > -1;
            }
          }
        };

        if (!support.checkOn) {
          jQuery.valHooks[this].get = function (elem) {
            return elem.getAttribute("value") === null ? "on" : elem.value;
          };
        }
      }); 

      support.focusin = "onfocusin" in window;

      var rfocusMorph = /^(?:focusinfocus|focusoutblur)$/,
          stopPropagationCallback = function stopPropagationCallback(e) {
        e.stopPropagation();
      };

      jQuery.extend(jQuery.event, {
        trigger: function trigger(event, data, elem, onlyHandlers) {
          var i,
              cur,
              tmp,
              bubbleType,
              ontype,
              handle,
              special,
              lastElement,
              eventPath = [elem || document],
              type = hasOwn.call(event, "type") ? event.type : event,
              namespaces = hasOwn.call(event, "namespace") ? event.namespace.split(".") : [];
          cur = lastElement = tmp = elem = elem || document; 

          if (elem.nodeType === 3 || elem.nodeType === 8) {
            return;
          } 


          if (rfocusMorph.test(type + jQuery.event.triggered)) {
            return;
          }

          if (type.indexOf(".") > -1) {
            namespaces = type.split(".");
            type = namespaces.shift();
            namespaces.sort();
          }

          ontype = type.indexOf(":") < 0 && "on" + type; 

          event = event[jQuery.expando] ? event : new jQuery.Event(type, _typeof(event) === "object" && event); 

          event.isTrigger = onlyHandlers ? 2 : 3;
          event.namespace = namespaces.join(".");
          event.rnamespace = event.namespace ? new RegExp("(^|\\.)" + namespaces.join("\\.(?:.*\\.|)") + "(\\.|$)") : null; 

          event.result = undefined;

          if (!event.target) {
            event.target = elem;
          } 


          data = data == null ? [event] : jQuery.makeArray(data, [event]); 

          special = jQuery.event.special[type] || {};

          if (!onlyHandlers && special.trigger && special.trigger.apply(elem, data) === false) {
            return;
          } 


          if (!onlyHandlers && !special.noBubble && !isWindow(elem)) {
            bubbleType = special.delegateType || type;

            if (!rfocusMorph.test(bubbleType + type)) {
              cur = cur.parentNode;
            }

            for (; cur; cur = cur.parentNode) {
              eventPath.push(cur);
              tmp = cur;
            } 


            if (tmp === (elem.ownerDocument || document)) {
              eventPath.push(tmp.defaultView || tmp.parentWindow || window);
            }
          } 


          i = 0;

          while ((cur = eventPath[i++]) && !event.isPropagationStopped()) {
            lastElement = cur;
            event.type = i > 1 ? bubbleType : special.bindType || type; 

            handle = (dataPriv.get(cur, "events") || Object.create(null))[event.type] && dataPriv.get(cur, "handle");

            if (handle) {
              handle.apply(cur, data);
            } 


            handle = ontype && cur[ontype];

            if (handle && handle.apply && acceptData(cur)) {
              event.result = handle.apply(cur, data);

              if (event.result === false) {
                event.preventDefault();
              }
            }
          }

          event.type = type; 

          if (!onlyHandlers && !event.isDefaultPrevented()) {
            if ((!special._default || special._default.apply(eventPath.pop(), data) === false) && acceptData(elem)) {
              if (ontype && isFunction(elem[type]) && !isWindow(elem)) {
                tmp = elem[ontype];

                if (tmp) {
                  elem[ontype] = null;
                } 


                jQuery.event.triggered = type;

                if (event.isPropagationStopped()) {
                  lastElement.addEventListener(type, stopPropagationCallback);
                }

                elem[type]();

                if (event.isPropagationStopped()) {
                  lastElement.removeEventListener(type, stopPropagationCallback);
                }

                jQuery.event.triggered = undefined;

                if (tmp) {
                  elem[ontype] = tmp;
                }
              }
            }
          }

          return event.result;
        },
        simulate: function simulate(type, elem, event) {
          var e = jQuery.extend(new jQuery.Event(), event, {
            type: type,
            isSimulated: true
          });
          jQuery.event.trigger(e, null, elem);
        }
      });
      jQuery.fn.extend({
        trigger: function trigger(type, data) {
          return this.each(function () {
            jQuery.event.trigger(type, data, this);
          });
        },
        triggerHandler: function triggerHandler(type, data) {
          var elem = this[0];

          if (elem) {
            return jQuery.event.trigger(type, data, elem, true);
          }
        }
      }); 

      if (!support.focusin) {
        jQuery.each({
          focus: "focusin",
          blur: "focusout"
        }, function (orig, fix) {
          var handler = function handler(event) {
            jQuery.event.simulate(fix, event.target, jQuery.event.fix(event));
          };

          jQuery.event.special[fix] = {
            setup: function setup() {
              var doc = this.ownerDocument || this.document || this,
                  attaches = dataPriv.access(doc, fix);

              if (!attaches) {
                doc.addEventListener(orig, handler, true);
              }

              dataPriv.access(doc, fix, (attaches || 0) + 1);
            },
            teardown: function teardown() {
              var doc = this.ownerDocument || this.document || this,
                  attaches = dataPriv.access(doc, fix) - 1;

              if (!attaches) {
                doc.removeEventListener(orig, handler, true);
                dataPriv.remove(doc, fix);
              } else {
                dataPriv.access(doc, fix, attaches);
              }
            }
          };
        });
      }

      var location = window.location;
      var nonce = {
        guid: Date.now()
      };
      var rquery = /\?/; 

      jQuery.parseXML = function (data) {
        var xml;

        if (!data || typeof data !== "string") {
          return null;
        } 


        try {
          xml = new window.DOMParser().parseFromString(data, "text/xml");
        } catch (e) {
          xml = undefined;
        }

        if (!xml || xml.getElementsByTagName("parsererror").length) {
          jQuery.error("Invalid XML: " + data);
        }

        return xml;
      };

      var rbracket = /\[\]$/,
          rCRLF = /\r?\n/g,
          rsubmitterTypes = /^(?:submit|button|image|reset|file)$/i,
          rsubmittable = /^(?:input|select|textarea|keygen)/i;

      function buildParams(prefix, obj, traditional, add) {
        var name;

        if (Array.isArray(obj)) {
          jQuery.each(obj, function (i, v) {
            if (traditional || rbracket.test(prefix)) {
              add(prefix, v);
            } else {
              buildParams(prefix + "[" + (_typeof(v) === "object" && v != null ? i : "") + "]", v, traditional, add);
            }
          });
        } else if (!traditional && toType(obj) === "object") {
          for (name in obj) {
            buildParams(prefix + "[" + name + "]", obj[name], traditional, add);
          }
        } else {
          add(prefix, obj);
        }
      } 


      jQuery.param = function (a, traditional) {
        var prefix,
            s = [],
            add = function add(key, valueOrFunction) {
          var value = isFunction(valueOrFunction) ? valueOrFunction() : valueOrFunction;
          s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value == null ? "" : value);
        };

        if (a == null) {
          return "";
        } 


        if (Array.isArray(a) || a.jquery && !jQuery.isPlainObject(a)) {
          jQuery.each(a, function () {
            add(this.name, this.value);
          });
        } else {
          for (prefix in a) {
            buildParams(prefix, a[prefix], traditional, add);
          }
        } 


        return s.join("&");
      };

      jQuery.fn.extend({
        serialize: function serialize() {
          return jQuery.param(this.serializeArray());
        },
        serializeArray: function serializeArray() {
          return this.map(function () {
            var elements = jQuery.prop(this, "elements");
            return elements ? jQuery.makeArray(elements) : this;
          }).filter(function () {
            var type = this.type; 

            return this.name && !jQuery(this).is(":disabled") && rsubmittable.test(this.nodeName) && !rsubmitterTypes.test(type) && (this.checked || !rcheckableType.test(type));
          }).map(function (_i, elem) {
            var val = jQuery(this).val();

            if (val == null) {
              return null;
            }

            if (Array.isArray(val)) {
              return jQuery.map(val, function (val) {
                return {
                  name: elem.name,
                  value: val.replace(rCRLF, "\r\n")
                };
              });
            }

            return {
              name: elem.name,
              value: val.replace(rCRLF, "\r\n")
            };
          }).get();
        }
      });
      var r20 = /%20/g,
          rhash = /#.*$/,
          rantiCache = /([?&])_=[^&]*/,
          rheaders = /^(.*?):[ \t]*([^\r\n]*)$/mg,
      rlocalProtocol = /^(?:about|app|app-storage|.+-extension|file|res|widget):$/,
          rnoContent = /^(?:GET|HEAD)$/,
          rprotocol = /^\/\//,

      prefilters = {},

      transports = {},
      allTypes = "*/".concat("*"),
      originAnchor = document.createElement("a");
      originAnchor.href = location.href; 

      function addToPrefiltersOrTransports(structure) {
        return function (dataTypeExpression, func) {
          if (typeof dataTypeExpression !== "string") {
            func = dataTypeExpression;
            dataTypeExpression = "*";
          }

          var dataType,
              i = 0,
              dataTypes = dataTypeExpression.toLowerCase().match(rnothtmlwhite) || [];

          if (isFunction(func)) {
            while (dataType = dataTypes[i++]) {
              if (dataType[0] === "+") {
                dataType = dataType.slice(1) || "*";
                (structure[dataType] = structure[dataType] || []).unshift(func); 
              } else {
                (structure[dataType] = structure[dataType] || []).push(func);
              }
            }
          }
        };
      } 


      function inspectPrefiltersOrTransports(structure, options, originalOptions, jqXHR) {
        var inspected = {},
            seekingTransport = structure === transports;

        function inspect(dataType) {
          var selected;
          inspected[dataType] = true;
          jQuery.each(structure[dataType] || [], function (_, prefilterOrFactory) {
            var dataTypeOrTransport = prefilterOrFactory(options, originalOptions, jqXHR);

            if (typeof dataTypeOrTransport === "string" && !seekingTransport && !inspected[dataTypeOrTransport]) {
              options.dataTypes.unshift(dataTypeOrTransport);
              inspect(dataTypeOrTransport);
              return false;
            } else if (seekingTransport) {
              return !(selected = dataTypeOrTransport);
            }
          });
          return selected;
        }

        return inspect(options.dataTypes[0]) || !inspected["*"] && inspect("*");
      } 


      function ajaxExtend(target, src) {
        var key,
            deep,
            flatOptions = jQuery.ajaxSettings.flatOptions || {};

        for (key in src) {
          if (src[key] !== undefined) {
            (flatOptions[key] ? target : deep || (deep = {}))[key] = src[key];
          }
        }

        if (deep) {
          jQuery.extend(true, target, deep);
        }

        return target;
      }


      function ajaxHandleResponses(s, jqXHR, responses) {
        var ct,
            type,
            finalDataType,
            firstDataType,
            contents = s.contents,
            dataTypes = s.dataTypes; 

        while (dataTypes[0] === "*") {
          dataTypes.shift();

          if (ct === undefined) {
            ct = s.mimeType || jqXHR.getResponseHeader("Content-Type");
          }
        } 


        if (ct) {
          for (type in contents) {
            if (contents[type] && contents[type].test(ct)) {
              dataTypes.unshift(type);
              break;
            }
          }
        } 


        if (dataTypes[0] in responses) {
          finalDataType = dataTypes[0];
        } else {
          for (type in responses) {
            if (!dataTypes[0] || s.converters[type + " " + dataTypes[0]]) {
              finalDataType = type;
              break;
            }

            if (!firstDataType) {
              firstDataType = type;
            }
          } 


          finalDataType = finalDataType || firstDataType;
        } 


        if (finalDataType) {
          if (finalDataType !== dataTypes[0]) {
            dataTypes.unshift(finalDataType);
          }

          return responses[finalDataType];
        }
      }


      function ajaxConvert(s, response, jqXHR, isSuccess) {
        var conv2,
            current,
            conv,
            tmp,
            prev,
            converters = {},
        dataTypes = s.dataTypes.slice(); 

        if (dataTypes[1]) {
          for (conv in s.converters) {
            converters[conv.toLowerCase()] = s.converters[conv];
          }
        }

        current = dataTypes.shift(); 

        while (current) {
          if (s.responseFields[current]) {
            jqXHR[s.responseFields[current]] = response;
          } 


          if (!prev && isSuccess && s.dataFilter) {
            response = s.dataFilter(response, s.dataType);
          }

          prev = current;
          current = dataTypes.shift();

          if (current) {
            if (current === "*") {
              current = prev; 
            } else if (prev !== "*" && prev !== current) {
              conv = converters[prev + " " + current] || converters["* " + current]; 

              if (!conv) {
                for (conv2 in converters) {
                  tmp = conv2.split(" ");

                  if (tmp[1] === current) {
                    conv = converters[prev + " " + tmp[0]] || converters["* " + tmp[0]];

                    if (conv) {
                      if (conv === true) {
                        conv = converters[conv2]; 
                      } else if (converters[conv2] !== true) {
                        current = tmp[0];
                        dataTypes.unshift(tmp[1]);
                      }

                      break;
                    }
                  }
                }
              } 


              if (conv !== true) {
                if (conv && s["throws"]) {
                  response = conv(response);
                } else {
                  try {
                    response = conv(response);
                  } catch (e) {
                    return {
                      state: "parsererror",
                      error: conv ? e : "No conversion from " + prev + " to " + current
                    };
                  }
                }
              }
            }
          }
        }

        return {
          state: "success",
          data: response
        };
      }

      jQuery.extend({
        active: 0,
        lastModified: {},
        etag: {},
        ajaxSettings: {
          url: location.href,
          type: "GET",
          isLocal: rlocalProtocol.test(location.protocol),
          global: true,
          processData: true,
          async: true,
          contentType: "application/x-www-form-urlencoded; charset=UTF-8",

          accepts: {
            "*": allTypes,
            text: "text/plain",
            html: "text/html",
            xml: "application/xml, text/xml",
            json: "application/json, text/javascript"
          },
          contents: {
            xml: /\bxml\b/,
            html: /\bhtml/,
            json: /\bjson\b/
          },
          responseFields: {
            xml: "responseXML",
            text: "responseText",
            json: "responseJSON"
          },
          converters: {
            "* text": String,
            "text html": true,
            "text json": JSON.parse,
            "text xml": jQuery.parseXML
          },
          flatOptions: {
            url: true,
            context: true
          }
        },
        ajaxSetup: function ajaxSetup(target, settings) {
          return settings ? 
          ajaxExtend(ajaxExtend(target, jQuery.ajaxSettings), settings) : 
          ajaxExtend(jQuery.ajaxSettings, target);
        },
        ajaxPrefilter: addToPrefiltersOrTransports(prefilters),
        ajaxTransport: addToPrefiltersOrTransports(transports),
        ajax: function ajax(url, options) {
          if (_typeof(url) === "object") {
            options = url;
            url = undefined;
          } 


          options = options || {};

          var transport,
          cacheURL,
          responseHeadersString,
              responseHeaders,
          timeoutTimer,
          urlAnchor,
          completed,
          fireGlobals,
          i,
          uncached,
          s = jQuery.ajaxSetup({}, options),
          callbackContext = s.context || s,
          globalEventContext = s.context && (callbackContext.nodeType || callbackContext.jquery) ? jQuery(callbackContext) : jQuery.event,
          deferred = jQuery.Deferred(),
              completeDeferred = jQuery.Callbacks("once memory"),
          _statusCode = s.statusCode || {},
          requestHeaders = {},
              requestHeadersNames = {},
          strAbort = "canceled",
          jqXHR = {
            readyState: 0,
            getResponseHeader: function getResponseHeader(key) {
              var match;

              if (completed) {
                if (!responseHeaders) {
                  responseHeaders = {};

                  while (match = rheaders.exec(responseHeadersString)) {
                    responseHeaders[match[1].toLowerCase() + " "] = (responseHeaders[match[1].toLowerCase() + " "] || []).concat(match[2]);
                  }
                }

                match = responseHeaders[key.toLowerCase() + " "];
              }

              return match == null ? null : match.join(", ");
            },
            getAllResponseHeaders: function getAllResponseHeaders() {
              return completed ? responseHeadersString : null;
            },
            setRequestHeader: function setRequestHeader(name, value) {
              if (completed == null) {
                name = requestHeadersNames[name.toLowerCase()] = requestHeadersNames[name.toLowerCase()] || name;
                requestHeaders[name] = value;
              }

              return this;
            },
            overrideMimeType: function overrideMimeType(type) {
              if (completed == null) {
                s.mimeType = type;
              }

              return this;
            },
            statusCode: function statusCode(map) {
              var code;

              if (map) {
                if (completed) {
                  jqXHR.always(map[jqXHR.status]);
                } else {
                  for (code in map) {
                    _statusCode[code] = [_statusCode[code], map[code]];
                  }
                }
              }

              return this;
            },
            abort: function abort(statusText) {
              var finalText = statusText || strAbort;

              if (transport) {
                transport.abort(finalText);
              }

              done(0, finalText);
              return this;
            }
          }; 


          deferred.promise(jqXHR); 

          s.url = ((url || s.url || location.href) + "").replace(rprotocol, location.protocol + "//"); 

          s.type = options.method || options.type || s.method || s.type; 

          s.dataTypes = (s.dataType || "*").toLowerCase().match(rnothtmlwhite) || [""]; 

          if (s.crossDomain == null) {
            urlAnchor = document.createElement("a"); 

            try {
              urlAnchor.href = s.url; 

              urlAnchor.href = urlAnchor.href;
              s.crossDomain = originAnchor.protocol + "//" + originAnchor.host !== urlAnchor.protocol + "//" + urlAnchor.host;
            } catch (e) {
              s.crossDomain = true;
            }
          } 


          if (s.data && s.processData && typeof s.data !== "string") {
            s.data = jQuery.param(s.data, s.traditional);
          } 


          inspectPrefiltersOrTransports(prefilters, s, options, jqXHR); 

          if (completed) {
            return jqXHR;
          } 


          fireGlobals = jQuery.event && s.global; 

          if (fireGlobals && jQuery.active++ === 0) {
            jQuery.event.trigger("ajaxStart");
          } 


          s.type = s.type.toUpperCase(); 

          s.hasContent = !rnoContent.test(s.type); 

          cacheURL = s.url.replace(rhash, ""); 

          if (!s.hasContent) {
            uncached = s.url.slice(cacheURL.length); 

            if (s.data && (s.processData || typeof s.data === "string")) {
              cacheURL += (rquery.test(cacheURL) ? "&" : "?") + s.data; 

              delete s.data;
            } 


            if (s.cache === false) {
              cacheURL = cacheURL.replace(rantiCache, "$1");
              uncached = (rquery.test(cacheURL) ? "&" : "?") + "_=" + nonce.guid++ + uncached;
            } 


            s.url = cacheURL + uncached; 
          } else if (s.data && s.processData && (s.contentType || "").indexOf("application/x-www-form-urlencoded") === 0) {
            s.data = s.data.replace(r20, "+");
          } 


          if (s.ifModified) {
            if (jQuery.lastModified[cacheURL]) {
              jqXHR.setRequestHeader("If-Modified-Since", jQuery.lastModified[cacheURL]);
            }

            if (jQuery.etag[cacheURL]) {
              jqXHR.setRequestHeader("If-None-Match", jQuery.etag[cacheURL]);
            }
          } 


          if (s.data && s.hasContent && s.contentType !== false || options.contentType) {
            jqXHR.setRequestHeader("Content-Type", s.contentType);
          } 


          jqXHR.setRequestHeader("Accept", s.dataTypes[0] && s.accepts[s.dataTypes[0]] ? s.accepts[s.dataTypes[0]] + (s.dataTypes[0] !== "*" ? ", " + allTypes + "; q=0.01" : "") : s.accepts["*"]); 

          for (i in s.headers) {
            jqXHR.setRequestHeader(i, s.headers[i]);
          } 


          if (s.beforeSend && (s.beforeSend.call(callbackContext, jqXHR, s) === false || completed)) {
            return jqXHR.abort();
          } 


          strAbort = "abort"; 

          completeDeferred.add(s.complete);
          jqXHR.done(s.success);
          jqXHR.fail(s.error); 

          transport = inspectPrefiltersOrTransports(transports, s, options, jqXHR); 

          if (!transport) {
            done(-1, "No Transport");
          } else {
            jqXHR.readyState = 1; 

            if (fireGlobals) {
              globalEventContext.trigger("ajaxSend", [jqXHR, s]);
            } 


            if (completed) {
              return jqXHR;
            } 


            if (s.async && s.timeout > 0) {
              timeoutTimer = window.setTimeout(function () {
                jqXHR.abort("timeout");
              }, s.timeout);
            }

            try {
              completed = false;
              transport.send(requestHeaders, done);
            } catch (e) {
              if (completed) {
                throw e;
              } 


              done(-1, e);
            }
          } 


          function done(status, nativeStatusText, responses, headers) {
            var isSuccess,
                success,
                error,
                response,
                modified,
                statusText = nativeStatusText; 

            if (completed) {
              return;
            }

            completed = true; 

            if (timeoutTimer) {
              window.clearTimeout(timeoutTimer);
            } 


            transport = undefined; 

            responseHeadersString = headers || ""; 

            jqXHR.readyState = status > 0 ? 4 : 0; 

            isSuccess = status >= 200 && status < 300 || status === 304; 

            if (responses) {
              response = ajaxHandleResponses(s, jqXHR, responses);
            } 


            if (!isSuccess && jQuery.inArray("script", s.dataTypes) > -1) {
              s.converters["text script"] = function () {};
            } 


            response = ajaxConvert(s, response, jqXHR, isSuccess); 

            if (isSuccess) {
              if (s.ifModified) {
                modified = jqXHR.getResponseHeader("Last-Modified");

                if (modified) {
                  jQuery.lastModified[cacheURL] = modified;
                }

                modified = jqXHR.getResponseHeader("etag");

                if (modified) {
                  jQuery.etag[cacheURL] = modified;
                }
              } 


              if (status === 204 || s.type === "HEAD") {
                statusText = "nocontent"; 
              } else if (status === 304) {
                statusText = "notmodified"; 
              } else {
                statusText = response.state;
                success = response.data;
                error = response.error;
                isSuccess = !error;
              }
            } else {
              error = statusText;

              if (status || !statusText) {
                statusText = "error";

                if (status < 0) {
                  status = 0;
                }
              }
            } 


            jqXHR.status = status;
            jqXHR.statusText = (nativeStatusText || statusText) + ""; 

            if (isSuccess) {
              deferred.resolveWith(callbackContext, [success, statusText, jqXHR]);
            } else {
              deferred.rejectWith(callbackContext, [jqXHR, statusText, error]);
            } 


            jqXHR.statusCode(_statusCode);
            _statusCode = undefined;

            if (fireGlobals) {
              globalEventContext.trigger(isSuccess ? "ajaxSuccess" : "ajaxError", [jqXHR, s, isSuccess ? success : error]);
            } 


            completeDeferred.fireWith(callbackContext, [jqXHR, statusText]);

            if (fireGlobals) {
              globalEventContext.trigger("ajaxComplete", [jqXHR, s]); 

              if (! --jQuery.active) {
                jQuery.event.trigger("ajaxStop");
              }
            }
          }

          return jqXHR;
        },
        getJSON: function getJSON(url, data, callback) {
          return jQuery.get(url, data, callback, "json");
        },
        getScript: function getScript(url, callback) {
          return jQuery.get(url, undefined, callback, "script");
        }
      });
      jQuery.each(["get", "post"], function (_i, method) {
        jQuery[method] = function (url, data, callback, type) {
          if (isFunction(data)) {
            type = type || callback;
            callback = data;
            data = undefined;
          } 


          return jQuery.ajax(jQuery.extend({
            url: url,
            type: method,
            dataType: type,
            data: data,
            success: callback
          }, jQuery.isPlainObject(url) && url));
        };
      });
      jQuery.ajaxPrefilter(function (s) {
        var i;

        for (i in s.headers) {
          if (i.toLowerCase() === "content-type") {
            s.contentType = s.headers[i] || "";
          }
        }
      });

      jQuery._evalUrl = function (url, options, doc) {
        return jQuery.ajax({
          url: url,
          type: "GET",
          dataType: "script",
          cache: true,
          async: false,
          global: false,
          converters: {
            "text script": function textScript() {}
          },
          dataFilter: function dataFilter(response) {
            jQuery.globalEval(response, options, doc);
          }
        });
      };

      jQuery.fn.extend({
        wrapAll: function wrapAll(html) {
          var wrap;

          if (this[0]) {
            if (isFunction(html)) {
              html = html.call(this[0]);
            } 


            wrap = jQuery(html, this[0].ownerDocument).eq(0).clone(true);

            if (this[0].parentNode) {
              wrap.insertBefore(this[0]);
            }

            wrap.map(function () {
              var elem = this;

              while (elem.firstElementChild) {
                elem = elem.firstElementChild;
              }

              return elem;
            }).append(this);
          }

          return this;
        },
        wrapInner: function wrapInner(html) {
          if (isFunction(html)) {
            return this.each(function (i) {
              jQuery(this).wrapInner(html.call(this, i));
            });
          }

          return this.each(function () {
            var self = jQuery(this),
                contents = self.contents();

            if (contents.length) {
              contents.wrapAll(html);
            } else {
              self.append(html);
            }
          });
        },
        wrap: function wrap(html) {
          var htmlIsFunction = isFunction(html);
          return this.each(function (i) {
            jQuery(this).wrapAll(htmlIsFunction ? html.call(this, i) : html);
          });
        },
        unwrap: function unwrap(selector) {
          this.parent(selector).not("body").each(function () {
            jQuery(this).replaceWith(this.childNodes);
          });
          return this;
        }
      });

      jQuery.expr.pseudos.hidden = function (elem) {
        return !jQuery.expr.pseudos.visible(elem);
      };

      jQuery.expr.pseudos.visible = function (elem) {
        return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      };

      jQuery.ajaxSettings.xhr = function () {
        try {
          return new window.XMLHttpRequest();
        } catch (e) {}
      };

      var xhrSuccessStatus = {
        0: 200,
        1223: 204
      },
          xhrSupported = jQuery.ajaxSettings.xhr();
      support.cors = !!xhrSupported && "withCredentials" in xhrSupported;
      support.ajax = xhrSupported = !!xhrSupported;
      jQuery.ajaxTransport(function (options) {
        var _callback, errorCallback; 


        if (support.cors || xhrSupported && !options.crossDomain) {
          return {
            send: function send(headers, complete) {
              var i,
                  xhr = options.xhr();
              xhr.open(options.type, options.url, options.async, options.username, options.password); 

              if (options.xhrFields) {
                for (i in options.xhrFields) {
                  xhr[i] = options.xhrFields[i];
                }
              } 


              if (options.mimeType && xhr.overrideMimeType) {
                xhr.overrideMimeType(options.mimeType);
              } 


              if (!options.crossDomain && !headers["X-Requested-With"]) {
                headers["X-Requested-With"] = "XMLHttpRequest";
              } 


              for (i in headers) {
                xhr.setRequestHeader(i, headers[i]);
              } 


              _callback = function callback(type) {
                return function () {
                  if (_callback) {
                    _callback = errorCallback = xhr.onload = xhr.onerror = xhr.onabort = xhr.ontimeout = xhr.onreadystatechange = null;

                    if (type === "abort") {
                      xhr.abort();
                    } else if (type === "error") {
                      if (typeof xhr.status !== "number") {
                        complete(0, "error");
                      } else {
                        complete( 
                        xhr.status, xhr.statusText);
                      }
                    } else {
                      complete(xhrSuccessStatus[xhr.status] || xhr.status, xhr.statusText, 
                      (xhr.responseType || "text") !== "text" || typeof xhr.responseText !== "string" ? {
                        binary: xhr.response
                      } : {
                        text: xhr.responseText
                      }, xhr.getAllResponseHeaders());
                    }
                  }
                };
              }; 


              xhr.onload = _callback();
              errorCallback = xhr.onerror = xhr.ontimeout = _callback("error"); 

              if (xhr.onabort !== undefined) {
                xhr.onabort = errorCallback;
              } else {
                xhr.onreadystatechange = function () {
                  if (xhr.readyState === 4) {
                    window.setTimeout(function () {
                      if (_callback) {
                        errorCallback();
                      }
                    });
                  }
                };
              } 


              _callback = _callback("abort");

              try {
                xhr.send(options.hasContent && options.data || null);
              } catch (e) {
                if (_callback) {
                  throw e;
                }
              }
            },
            abort: function abort() {
              if (_callback) {
                _callback();
              }
            }
          };
        }
      }); 

      jQuery.ajaxPrefilter(function (s) {
        if (s.crossDomain) {
          s.contents.script = false;
        }
      }); 

      jQuery.ajaxSetup({
        accepts: {
          script: "text/javascript, application/javascript, " + "application/ecmascript, application/x-ecmascript"
        },
        contents: {
          script: /\b(?:java|ecma)script\b/
        },
        converters: {
          "text script": function textScript(text) {
            jQuery.globalEval(text);
            return text;
          }
        }
      }); 

      jQuery.ajaxPrefilter("script", function (s) {
        if (s.cache === undefined) {
          s.cache = false;
        }

        if (s.crossDomain) {
          s.type = "GET";
        }
      }); 

      jQuery.ajaxTransport("script", function (s) {
        if (s.crossDomain || s.scriptAttrs) {
          var script, _callback2;

          return {
            send: function send(_, complete) {
              script = jQuery("<script>").attr(s.scriptAttrs || {}).prop({
                charset: s.scriptCharset,
                src: s.url
              }).on("load error", _callback2 = function callback(evt) {
                script.remove();
                _callback2 = null;

                if (evt) {
                  complete(evt.type === "error" ? 404 : 200, evt.type);
                }
              }); 

              document.head.appendChild(script[0]);
            },
            abort: function abort() {
              if (_callback2) {
                _callback2();
              }
            }
          };
        }
      });
      var oldCallbacks = [],
          rjsonp = /(=)\?(?=&|$)|\?\?/; 

      jQuery.ajaxSetup({
        jsonp: "callback",
        jsonpCallback: function jsonpCallback() {
          var callback = oldCallbacks.pop() || jQuery.expando + "_" + nonce.guid++;
          this[callback] = true;
          return callback;
        }
      }); 

      jQuery.ajaxPrefilter("json jsonp", function (s, originalSettings, jqXHR) {
        var callbackName,
            overwritten,
            responseContainer,
            jsonProp = s.jsonp !== false && (rjsonp.test(s.url) ? "url" : typeof s.data === "string" && (s.contentType || "").indexOf("application/x-www-form-urlencoded") === 0 && rjsonp.test(s.data) && "data"); 

        if (jsonProp || s.dataTypes[0] === "jsonp") {
          callbackName = s.jsonpCallback = isFunction(s.jsonpCallback) ? s.jsonpCallback() : s.jsonpCallback; 

          if (jsonProp) {
            s[jsonProp] = s[jsonProp].replace(rjsonp, "$1" + callbackName);
          } else if (s.jsonp !== false) {
            s.url += (rquery.test(s.url) ? "&" : "?") + s.jsonp + "=" + callbackName;
          } 


          s.converters["script json"] = function () {
            if (!responseContainer) {
              jQuery.error(callbackName + " was not called");
            }

            return responseContainer[0];
          }; 


          s.dataTypes[0] = "json"; 

          overwritten = window[callbackName];

          window[callbackName] = function () {
            responseContainer = arguments;
          }; 


          jqXHR.always(function () {
            if (overwritten === undefined) {
              jQuery(window).removeProp(callbackName); 
            } else {
              window[callbackName] = overwritten;
            } 


            if (s[callbackName]) {
              s.jsonpCallback = originalSettings.jsonpCallback; 

              oldCallbacks.push(callbackName);
            } 


            if (responseContainer && isFunction(overwritten)) {
              overwritten(responseContainer[0]);
            }

            responseContainer = overwritten = undefined;
          }); 

          return "script";
        }
      }); 

      support.createHTMLDocument = function () {
        var body = document.implementation.createHTMLDocument("").body;
        body.innerHTML = "<form></form><form></form>";
        return body.childNodes.length === 2;
      }(); 


      jQuery.parseHTML = function (data, context, keepScripts) {
        if (typeof data !== "string") {
          return [];
        }

        if (typeof context === "boolean") {
          keepScripts = context;
          context = false;
        }

        var base, parsed, scripts;

        if (!context) {
          if (support.createHTMLDocument) {
            context = document.implementation.createHTMLDocument(""); 

            base = context.createElement("base");
            base.href = document.location.href;
            context.head.appendChild(base);
          } else {
            context = document;
          }
        }

        parsed = rsingleTag.exec(data);
        scripts = !keepScripts && []; 

        if (parsed) {
          return [context.createElement(parsed[1])];
        }

        parsed = buildFragment([data], context, scripts);

        if (scripts && scripts.length) {
          jQuery(scripts).remove();
        }

        return jQuery.merge([], parsed.childNodes);
      };
      /**
       * Load a url into a page
       */


      jQuery.fn.load = function (url, params, callback) {
        var selector,
            type,
            response,
            self = this,
            off = url.indexOf(" ");

        if (off > -1) {
          selector = stripAndCollapse(url.slice(off));
          url = url.slice(0, off);
        } 


        if (isFunction(params)) {
          callback = params;
          params = undefined; 
        } else if (params && _typeof(params) === "object") {
          type = "POST";
        } 


        if (self.length > 0) {
          jQuery.ajax({
            url: url,
            type: type || "GET",
            dataType: "html",
            data: params
          }).done(function (responseText) {
            response = arguments;
            self.html(selector ? 
            jQuery("<div>").append(jQuery.parseHTML(responseText)).find(selector) : 
            responseText); 
          }).always(callback && function (jqXHR, status) {
            self.each(function () {
              callback.apply(this, response || [jqXHR.responseText, status, jqXHR]);
            });
          });
        }

        return this;
      };

      jQuery.expr.pseudos.animated = function (elem) {
        return jQuery.grep(jQuery.timers, function (fn) {
          return elem === fn.elem;
        }).length;
      };

      jQuery.offset = {
        setOffset: function setOffset(elem, options, i) {
          var curPosition,
              curLeft,
              curCSSTop,
              curTop,
              curOffset,
              curCSSLeft,
              calculatePosition,
              position = jQuery.css(elem, "position"),
              curElem = jQuery(elem),
              props = {}; 

          if (position === "static") {
            elem.style.position = "relative";
          }

          curOffset = curElem.offset();
          curCSSTop = jQuery.css(elem, "top");
          curCSSLeft = jQuery.css(elem, "left");
          calculatePosition = (position === "absolute" || position === "fixed") && (curCSSTop + curCSSLeft).indexOf("auto") > -1; 

          if (calculatePosition) {
            curPosition = curElem.position();
            curTop = curPosition.top;
            curLeft = curPosition.left;
          } else {
            curTop = parseFloat(curCSSTop) || 0;
            curLeft = parseFloat(curCSSLeft) || 0;
          }

          if (isFunction(options)) {
            options = options.call(elem, i, jQuery.extend({}, curOffset));
          }

          if (options.top != null) {
            props.top = options.top - curOffset.top + curTop;
          }

          if (options.left != null) {
            props.left = options.left - curOffset.left + curLeft;
          }

          if ("using" in options) {
            options.using.call(elem, props);
          } else {
            if (typeof props.top === "number") {
              props.top += "px";
            }

            if (typeof props.left === "number") {
              props.left += "px";
            }

            curElem.css(props);
          }
        }
      };
      jQuery.fn.extend({
        offset: function offset(options) {
          if (arguments.length) {
            return options === undefined ? this : this.each(function (i) {
              jQuery.offset.setOffset(this, options, i);
            });
          }

          var rect,
              win,
              elem = this[0];

          if (!elem) {
            return;
          } 


          if (!elem.getClientRects().length) {
            return {
              top: 0,
              left: 0
            };
          } 


          rect = elem.getBoundingClientRect();
          win = elem.ownerDocument.defaultView;
          return {
            top: rect.top + win.pageYOffset,
            left: rect.left + win.pageXOffset
          };
        },
        position: function position() {
          if (!this[0]) {
            return;
          }

          var offsetParent,
              offset,
              doc,
              elem = this[0],
              parentOffset = {
            top: 0,
            left: 0
          }; 

          if (jQuery.css(elem, "position") === "fixed") {
            offset = elem.getBoundingClientRect();
          } else {
            offset = this.offset(); 

            doc = elem.ownerDocument;
            offsetParent = elem.offsetParent || doc.documentElement;

            while (offsetParent && (offsetParent === doc.body || offsetParent === doc.documentElement) && jQuery.css(offsetParent, "position") === "static") {
              offsetParent = offsetParent.parentNode;
            }

            if (offsetParent && offsetParent !== elem && offsetParent.nodeType === 1) {
              parentOffset = jQuery(offsetParent).offset();
              parentOffset.top += jQuery.css(offsetParent, "borderTopWidth", true);
              parentOffset.left += jQuery.css(offsetParent, "borderLeftWidth", true);
            }
          } 


          return {
            top: offset.top - parentOffset.top - jQuery.css(elem, "marginTop", true),
            left: offset.left - parentOffset.left - jQuery.css(elem, "marginLeft", true)
          };
        },
        offsetParent: function offsetParent() {
          return this.map(function () {
            var offsetParent = this.offsetParent;

            while (offsetParent && jQuery.css(offsetParent, "position") === "static") {
              offsetParent = offsetParent.offsetParent;
            }

            return offsetParent || documentElement;
          });
        }
      }); 

      jQuery.each({
        scrollLeft: "pageXOffset",
        scrollTop: "pageYOffset"
      }, function (method, prop) {
        var top = "pageYOffset" === prop;

        jQuery.fn[method] = function (val) {
          return access(this, function (elem, method, val) {
            var win;

            if (isWindow(elem)) {
              win = elem;
            } else if (elem.nodeType === 9) {
              win = elem.defaultView;
            }

            if (val === undefined) {
              return win ? win[prop] : elem[method];
            }

            if (win) {
              win.scrollTo(!top ? val : win.pageXOffset, top ? val : win.pageYOffset);
            } else {
              elem[method] = val;
            }
          }, method, val, arguments.length);
        };
      }); 

      jQuery.each(["top", "left"], function (_i, prop) {
        jQuery.cssHooks[prop] = addGetHookIf(support.pixelPosition, function (elem, computed) {
          if (computed) {
            computed = curCSS(elem, prop); 

            return rnumnonpx.test(computed) ? jQuery(elem).position()[prop] + "px" : computed;
          }
        });
      }); 

      jQuery.each({
        Height: "height",
        Width: "width"
      }, function (name, type) {
        jQuery.each({
          padding: "inner" + name,
          content: type,
          "": "outer" + name
        }, function (defaultExtra, funcName) {
          jQuery.fn[funcName] = function (margin, value) {
            var chainable = arguments.length && (defaultExtra || typeof margin !== "boolean"),
                extra = defaultExtra || (margin === true || value === true ? "margin" : "border");
            return access(this, function (elem, type, value) {
              var doc;

              if (isWindow(elem)) {
                return funcName.indexOf("outer") === 0 ? elem["inner" + name] : elem.document.documentElement["client" + name];
              } 


              if (elem.nodeType === 9) {
                doc = elem.documentElement; 

                return Math.max(elem.body["scroll" + name], doc["scroll" + name], elem.body["offset" + name], doc["offset" + name], doc["client" + name]);
              }

              return value === undefined ? 
              jQuery.css(elem, type, extra) : 
              jQuery.style(elem, type, value, extra);
            }, type, chainable ? margin : undefined, chainable);
          };
        });
      });
      jQuery.each(["ajaxStart", "ajaxStop", "ajaxComplete", "ajaxError", "ajaxSuccess", "ajaxSend"], function (_i, type) {
        jQuery.fn[type] = function (fn) {
          return this.on(type, fn);
        };
      });
      jQuery.fn.extend({
        bind: function bind(types, data, fn) {
          return this.on(types, null, data, fn);
        },
        unbind: function unbind(types, fn) {
          return this.off(types, null, fn);
        },
        delegate: function delegate(selector, types, data, fn) {
          return this.on(types, selector, data, fn);
        },
        undelegate: function undelegate(selector, types, fn) {
          return arguments.length === 1 ? this.off(selector, "**") : this.off(types, selector || "**", fn);
        },
        hover: function hover(fnOver, fnOut) {
          return this.mouseenter(fnOver).mouseleave(fnOut || fnOver);
        }
      });
      jQuery.each(("blur focus focusin focusout resize scroll click dblclick " + "mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave " + "change select submit keydown keypress keyup contextmenu").split(" "), function (_i, name) {
        jQuery.fn[name] = function (data, fn) {
          return arguments.length > 0 ? this.on(name, null, data, fn) : this.trigger(name);
        };
      }); 

      var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g; 

      jQuery.proxy = function (fn, context) {
        var tmp, args, proxy;

        if (typeof context === "string") {
          tmp = fn[context];
          context = fn;
          fn = tmp;
        } 


        if (!isFunction(fn)) {
          return undefined;
        } 


        args = _slice.call(arguments, 2);

        proxy = function proxy() {
          return fn.apply(context || this, args.concat(_slice.call(arguments)));
        }; 


        proxy.guid = fn.guid = fn.guid || jQuery.guid++;
        return proxy;
      };

      jQuery.holdReady = function (hold) {
        if (hold) {
          jQuery.readyWait++;
        } else {
          jQuery.ready(true);
        }
      };

      jQuery.isArray = Array.isArray;
      jQuery.parseJSON = JSON.parse;
      jQuery.nodeName = nodeName;
      jQuery.isFunction = isFunction;
      jQuery.isWindow = isWindow;
      jQuery.camelCase = camelCase;
      jQuery.type = toType;
      jQuery.now = Date.now;

      jQuery.isNumeric = function (obj) {
        var type = jQuery.type(obj);
        return (type === "number" || type === "string") && 
        !isNaN(obj - parseFloat(obj));
      };

      jQuery.trim = function (text) {
        return text == null ? "" : (text + "").replace(rtrim, "");
      }; 


      if (typeof define === "function" && define.amd) {
        define("jquery", [], function () {
          return jQuery;
        });
      }

      var 
      _jQuery = window.jQuery,
      _$ = window.$;

      jQuery.noConflict = function (deep) {
        if (window.$ === jQuery) {
          window.$ = _$;
        }

        if (deep && window.jQuery === jQuery) {
          window.jQuery = _jQuery;
        }

        return jQuery;
      }; 


      if (typeof noGlobal === "undefined") {
        window.jQuery = window.$ = jQuery;
      }

      return jQuery;
    });
  }, {}],
  3: [function (require, module, exports) {

    ;

    (function (factory) {
      'use strict';

      if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
      } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
      } else {
        factory(jQuery);
      }
    })(function ($) {
      'use strict';

      var Slick = window.Slick || {};

      Slick = function () {
        var instanceUid = 0;

        function Slick(element, settings) {
          var _ = this,
              dataSettings;

          _.defaults = {
            accessibility: true,
            adaptiveHeight: false,
            appendArrows: $(element),
            appendDots: $(element),
            arrows: true,
            asNavFor: null,
            prevArrow: '<button class="slick-prev" aria-label="Previous" type="button">Previous</button>',
            nextArrow: '<button class="slick-next" aria-label="Next" type="button">Next</button>',
            autoplay: false,
            autoplaySpeed: 3000,
            centerMode: false,
            centerPadding: '50px',
            cssEase: 'ease',
            customPaging: function customPaging(slider, i) {
              return $('<button type="button" />').text(i + 1);
            },
            dots: false,
            dotsClass: 'slick-dots',
            draggable: true,
            easing: 'linear',
            edgeFriction: 0.35,
            fade: false,
            focusOnSelect: false,
            focusOnChange: false,
            infinite: true,
            initialSlide: 0,
            lazyLoad: 'ondemand',
            mobileFirst: false,
            pauseOnHover: true,
            pauseOnFocus: true,
            pauseOnDotsHover: false,
            respondTo: 'window',
            responsive: null,
            rows: 1,
            rtl: false,
            slide: '',
            slidesPerRow: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            swipe: true,
            swipeToSlide: false,
            touchMove: true,
            touchThreshold: 5,
            useCSS: true,
            useTransform: true,
            variableWidth: false,
            vertical: false,
            verticalSwiping: false,
            waitForAnimate: true,
            zIndex: 1000
          };
          _.initials = {
            animating: false,
            dragging: false,
            autoPlayTimer: null,
            currentDirection: 0,
            currentLeft: null,
            currentSlide: 0,
            direction: 1,
            $dots: null,
            listWidth: null,
            listHeight: null,
            loadIndex: 0,
            $nextArrow: null,
            $prevArrow: null,
            scrolling: false,
            slideCount: null,
            slideWidth: null,
            $slideTrack: null,
            $slides: null,
            sliding: false,
            slideOffset: 0,
            swipeLeft: null,
            swiping: false,
            $list: null,
            touchObject: {},
            transformsEnabled: false,
            unslicked: false
          };
          $.extend(_, _.initials);
          _.activeBreakpoint = null;
          _.animType = null;
          _.animProp = null;
          _.breakpoints = [];
          _.breakpointSettings = [];
          _.cssTransitions = false;
          _.focussed = false;
          _.interrupted = false;
          _.hidden = 'hidden';
          _.paused = true;
          _.positionProp = null;
          _.respondTo = null;
          _.rowCount = 1;
          _.shouldClick = true;
          _.$slider = $(element);
          _.$slidesCache = null;
          _.transformType = null;
          _.transitionType = null;
          _.visibilityChange = 'visibilitychange';
          _.windowWidth = 0;
          _.windowTimer = null;
          dataSettings = $(element).data('slick') || {};
          _.options = $.extend({}, _.defaults, settings, dataSettings);
          _.currentSlide = _.options.initialSlide;
          _.originalSettings = _.options;

          if (typeof document.mozHidden !== 'undefined') {
            _.hidden = 'mozHidden';
            _.visibilityChange = 'mozvisibilitychange';
          } else if (typeof document.webkitHidden !== 'undefined') {
            _.hidden = 'webkitHidden';
            _.visibilityChange = 'webkitvisibilitychange';
          }

          _.autoPlay = $.proxy(_.autoPlay, _);
          _.autoPlayClear = $.proxy(_.autoPlayClear, _);
          _.autoPlayIterator = $.proxy(_.autoPlayIterator, _);
          _.changeSlide = $.proxy(_.changeSlide, _);
          _.clickHandler = $.proxy(_.clickHandler, _);
          _.selectHandler = $.proxy(_.selectHandler, _);
          _.setPosition = $.proxy(_.setPosition, _);
          _.swipeHandler = $.proxy(_.swipeHandler, _);
          _.dragHandler = $.proxy(_.dragHandler, _);
          _.keyHandler = $.proxy(_.keyHandler, _);
          _.instanceUid = instanceUid++; 

          _.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/;

          _.registerBreakpoints();

          _.init(true);
        }

        return Slick;
      }();

      Slick.prototype.activateADA = function () {
        var _ = this;

        _.$slideTrack.find('.slick-active').attr({
          'aria-hidden': 'false'
        }).find('a, input, button, select').attr({
          'tabindex': '0'
        });
      };

      Slick.prototype.addSlide = Slick.prototype.slickAdd = function (markup, index, addBefore) {
        var _ = this;

        if (typeof index === 'boolean') {
          addBefore = index;
          index = null;
        } else if (index < 0 || index >= _.slideCount) {
          return false;
        }

        _.unload();

        if (typeof index === 'number') {
          if (index === 0 && _.$slides.length === 0) {
            $(markup).appendTo(_.$slideTrack);
          } else if (addBefore) {
            $(markup).insertBefore(_.$slides.eq(index));
          } else {
            $(markup).insertAfter(_.$slides.eq(index));
          }
        } else {
          if (addBefore === true) {
            $(markup).prependTo(_.$slideTrack);
          } else {
            $(markup).appendTo(_.$slideTrack);
          }
        }

        _.$slides = _.$slideTrack.children(this.options.slide);

        _.$slideTrack.children(this.options.slide).detach();

        _.$slideTrack.append(_.$slides);

        _.$slides.each(function (index, element) {
          $(element).attr('data-slick-index', index);
        });

        _.$slidesCache = _.$slides;

        _.reinit();
      };

      Slick.prototype.animateHeight = function () {
        var _ = this;

        if (_.options.slidesToShow === 1 && _.options.adaptiveHeight === true && _.options.vertical === false) {
          var targetHeight = _.$slides.eq(_.currentSlide).outerHeight(true);

          _.$list.animate({
            height: targetHeight
          }, _.options.speed);
        }
      };

      Slick.prototype.animateSlide = function (targetLeft, callback) {
        var animProps = {},
            _ = this;

        _.animateHeight();

        if (_.options.rtl === true && _.options.vertical === false) {
          targetLeft = -targetLeft;
        }

        if (_.transformsEnabled === false) {
          if (_.options.vertical === false) {
            _.$slideTrack.animate({
              left: targetLeft
            }, _.options.speed, _.options.easing, callback);
          } else {
            _.$slideTrack.animate({
              top: targetLeft
            }, _.options.speed, _.options.easing, callback);
          }
        } else {
          if (_.cssTransitions === false) {
            if (_.options.rtl === true) {
              _.currentLeft = -_.currentLeft;
            }

            $({
              animStart: _.currentLeft
            }).animate({
              animStart: targetLeft
            }, {
              duration: _.options.speed,
              easing: _.options.easing,
              step: function step(now) {
                now = Math.ceil(now);

                if (_.options.vertical === false) {
                  animProps[_.animType] = 'translate(' + now + 'px, 0px)';

                  _.$slideTrack.css(animProps);
                } else {
                  animProps[_.animType] = 'translate(0px,' + now + 'px)';

                  _.$slideTrack.css(animProps);
                }
              },
              complete: function complete() {
                if (callback) {
                  callback.call();
                }
              }
            });
          } else {
            _.applyTransition();

            targetLeft = Math.ceil(targetLeft);

            if (_.options.vertical === false) {
              animProps[_.animType] = 'translate3d(' + targetLeft + 'px, 0px, 0px)';
            } else {
              animProps[_.animType] = 'translate3d(0px,' + targetLeft + 'px, 0px)';
            }

            _.$slideTrack.css(animProps);

            if (callback) {
              setTimeout(function () {
                _.disableTransition();

                callback.call();
              }, _.options.speed);
            }
          }
        }
      };

      Slick.prototype.getNavTarget = function () {
        var _ = this,
            asNavFor = _.options.asNavFor;

        if (asNavFor && asNavFor !== null) {
          asNavFor = $(asNavFor).not(_.$slider);
        }

        return asNavFor;
      };

      Slick.prototype.asNavFor = function (index) {
        var _ = this,
            asNavFor = _.getNavTarget();

        if (asNavFor !== null && _typeof(asNavFor) === 'object') {
          asNavFor.each(function () {
            var target = $(this).slick('getSlick');

            if (!target.unslicked) {
              target.slideHandler(index, true);
            }
          });
        }
      };

      Slick.prototype.applyTransition = function (slide) {
        var _ = this,
            transition = {};

        if (_.options.fade === false) {
          transition[_.transitionType] = _.transformType + ' ' + _.options.speed + 'ms ' + _.options.cssEase;
        } else {
          transition[_.transitionType] = 'opacity ' + _.options.speed + 'ms ' + _.options.cssEase;
        }

        if (_.options.fade === false) {
          _.$slideTrack.css(transition);
        } else {
          _.$slides.eq(slide).css(transition);
        }
      };

      Slick.prototype.autoPlay = function () {
        var _ = this;

        _.autoPlayClear();

        if (_.slideCount > _.options.slidesToShow) {
          _.autoPlayTimer = setInterval(_.autoPlayIterator, _.options.autoplaySpeed);
        }
      };

      Slick.prototype.autoPlayClear = function () {
        var _ = this;

        if (_.autoPlayTimer) {
          clearInterval(_.autoPlayTimer);
        }
      };

      Slick.prototype.autoPlayIterator = function () {
        var _ = this,
            slideTo = _.currentSlide + _.options.slidesToScroll;

        if (!_.paused && !_.interrupted && !_.focussed) {
          if (_.options.infinite === false) {
            if (_.direction === 1 && _.currentSlide + 1 === _.slideCount - 1) {
              _.direction = 0;
            } else if (_.direction === 0) {
              slideTo = _.currentSlide - _.options.slidesToScroll;

              if (_.currentSlide - 1 === 0) {
                _.direction = 1;
              }
            }
          }

          _.slideHandler(slideTo);
        }
      };

      Slick.prototype.buildArrows = function () {
        var _ = this;

        if (_.options.arrows === true) {
          _.$prevArrow = $(_.options.prevArrow).addClass('slick-arrow');
          _.$nextArrow = $(_.options.nextArrow).addClass('slick-arrow');

          if (_.slideCount > _.options.slidesToShow) {
            _.$prevArrow.removeClass('slick-hidden').removeAttr('aria-hidden tabindex');

            _.$nextArrow.removeClass('slick-hidden').removeAttr('aria-hidden tabindex');

            if (_.htmlExpr.test(_.options.prevArrow)) {
              _.$prevArrow.prependTo(_.options.appendArrows);
            }

            if (_.htmlExpr.test(_.options.nextArrow)) {
              _.$nextArrow.appendTo(_.options.appendArrows);
            }

            if (_.options.infinite !== true) {
              _.$prevArrow.addClass('slick-disabled').attr('aria-disabled', 'true');
            }
          } else {
            _.$prevArrow.add(_.$nextArrow).addClass('slick-hidden').attr({
              'aria-disabled': 'true',
              'tabindex': '-1'
            });
          }
        }
      };

      Slick.prototype.buildDots = function () {
        var _ = this,
            i,
            dot;

        if (_.options.dots === true && _.slideCount > _.options.slidesToShow) {
          _.$slider.addClass('slick-dotted');

          dot = $('<ul />').addClass(_.options.dotsClass);

          for (i = 0; i <= _.getDotCount(); i += 1) {
            dot.append($('<li />').append(_.options.customPaging.call(this, _, i)));
          }

          _.$dots = dot.appendTo(_.options.appendDots);

          _.$dots.find('li').first().addClass('slick-active');
        }
      };

      Slick.prototype.buildOut = function () {
        var _ = this;

        _.$slides = _.$slider.children(_.options.slide + ':not(.slick-cloned)').addClass('slick-slide');
        _.slideCount = _.$slides.length;

        _.$slides.each(function (index, element) {
          $(element).attr('data-slick-index', index).data('originalStyling', $(element).attr('style') || '');
        });

        _.$slider.addClass('slick-slider');

        _.$slideTrack = _.slideCount === 0 ? $('<div class="slick-track"/>').appendTo(_.$slider) : _.$slides.wrapAll('<div class="slick-track"/>').parent();
        _.$list = _.$slideTrack.wrap('<div class="slick-list"/>').parent();

        _.$slideTrack.css('opacity', 0);

        if (_.options.centerMode === true || _.options.swipeToSlide === true) {
          _.options.slidesToScroll = 1;
        }

        $('img[data-lazy]', _.$slider).not('[src]').addClass('slick-loading');

        _.setupInfinite();

        _.buildArrows();

        _.buildDots();

        _.updateDots();

        _.setSlideClasses(typeof _.currentSlide === 'number' ? _.currentSlide : 0);

        if (_.options.draggable === true) {
          _.$list.addClass('draggable');
        }
      };

      Slick.prototype.buildRows = function () {
        var _ = this,
            a,
            b,
            c,
            newSlides,
            numOfSlides,
            originalSlides,
            slidesPerSection;

        newSlides = document.createDocumentFragment();
        originalSlides = _.$slider.children();

        if (_.options.rows > 0) {
          slidesPerSection = _.options.slidesPerRow * _.options.rows;
          numOfSlides = Math.ceil(originalSlides.length / slidesPerSection);

          for (a = 0; a < numOfSlides; a++) {
            var slide = document.createElement('div');

            for (b = 0; b < _.options.rows; b++) {
              var row = document.createElement('div');

              for (c = 0; c < _.options.slidesPerRow; c++) {
                var target = a * slidesPerSection + (b * _.options.slidesPerRow + c);

                if (originalSlides.get(target)) {
                  row.appendChild(originalSlides.get(target));
                }
              }

              slide.appendChild(row);
            }

            newSlides.appendChild(slide);
          }

          _.$slider.empty().append(newSlides);

          _.$slider.children().children().children().css({
            'width': 100 / _.options.slidesPerRow + '%',
            'display': 'inline-block'
          });
        }
      };

      Slick.prototype.checkResponsive = function (initial, forceUpdate) {
        var _ = this,
            breakpoint,
            targetBreakpoint,
            respondToWidth,
            triggerBreakpoint = false;

        var sliderWidth = _.$slider.width();

        var windowWidth = window.innerWidth || $(window).width();

        if (_.respondTo === 'window') {
          respondToWidth = windowWidth;
        } else if (_.respondTo === 'slider') {
          respondToWidth = sliderWidth;
        } else if (_.respondTo === 'min') {
          respondToWidth = Math.min(windowWidth, sliderWidth);
        }

        if (_.options.responsive && _.options.responsive.length && _.options.responsive !== null) {
          targetBreakpoint = null;

          for (breakpoint in _.breakpoints) {
            if (_.breakpoints.hasOwnProperty(breakpoint)) {
              if (_.originalSettings.mobileFirst === false) {
                if (respondToWidth < _.breakpoints[breakpoint]) {
                  targetBreakpoint = _.breakpoints[breakpoint];
                }
              } else {
                if (respondToWidth > _.breakpoints[breakpoint]) {
                  targetBreakpoint = _.breakpoints[breakpoint];
                }
              }
            }
          }

          if (targetBreakpoint !== null) {
            if (_.activeBreakpoint !== null) {
              if (targetBreakpoint !== _.activeBreakpoint || forceUpdate) {
                _.activeBreakpoint = targetBreakpoint;

                if (_.breakpointSettings[targetBreakpoint] === 'unslick') {
                  _.unslick(targetBreakpoint);
                } else {
                  _.options = $.extend({}, _.originalSettings, _.breakpointSettings[targetBreakpoint]);

                  if (initial === true) {
                    _.currentSlide = _.options.initialSlide;
                  }

                  _.refresh(initial);
                }

                triggerBreakpoint = targetBreakpoint;
              }
            } else {
              _.activeBreakpoint = targetBreakpoint;

              if (_.breakpointSettings[targetBreakpoint] === 'unslick') {
                _.unslick(targetBreakpoint);
              } else {
                _.options = $.extend({}, _.originalSettings, _.breakpointSettings[targetBreakpoint]);

                if (initial === true) {
                  _.currentSlide = _.options.initialSlide;
                }

                _.refresh(initial);
              }

              triggerBreakpoint = targetBreakpoint;
            }
          } else {
            if (_.activeBreakpoint !== null) {
              _.activeBreakpoint = null;
              _.options = _.originalSettings;

              if (initial === true) {
                _.currentSlide = _.options.initialSlide;
              }

              _.refresh(initial);

              triggerBreakpoint = targetBreakpoint;
            }
          } 


          if (!initial && triggerBreakpoint !== false) {
            _.$slider.trigger('breakpoint', [_, triggerBreakpoint]);
          }
        }
      };

      Slick.prototype.changeSlide = function (event, dontAnimate) {
        var _ = this,
            $target = $(event.currentTarget),
            indexOffset,
            slideOffset,
            unevenOffset; 


        if ($target.is('a')) {
          event.preventDefault();
        } 


        if (!$target.is('li')) {
          $target = $target.closest('li');
        }

        unevenOffset = _.slideCount % _.options.slidesToScroll !== 0;
        indexOffset = unevenOffset ? 0 : (_.slideCount - _.currentSlide) % _.options.slidesToScroll;

        switch (event.data.message) {
          case 'previous':
            slideOffset = indexOffset === 0 ? _.options.slidesToScroll : _.options.slidesToShow - indexOffset;

            if (_.slideCount > _.options.slidesToShow) {
              _.slideHandler(_.currentSlide - slideOffset, false, dontAnimate);
            }

            break;

          case 'next':
            slideOffset = indexOffset === 0 ? _.options.slidesToScroll : indexOffset;

            if (_.slideCount > _.options.slidesToShow) {
              _.slideHandler(_.currentSlide + slideOffset, false, dontAnimate);
            }

            break;

          case 'index':
            var index = event.data.index === 0 ? 0 : event.data.index || $target.index() * _.options.slidesToScroll;

            _.slideHandler(_.checkNavigable(index), false, dontAnimate);

            $target.children().trigger('focus');
            break;

          default:
            return;
        }
      };

      Slick.prototype.checkNavigable = function (index) {
        var _ = this,
            navigables,
            prevNavigable;

        navigables = _.getNavigableIndexes();
        prevNavigable = 0;

        if (index > navigables[navigables.length - 1]) {
          index = navigables[navigables.length - 1];
        } else {
          for (var n in navigables) {
            if (index < navigables[n]) {
              index = prevNavigable;
              break;
            }

            prevNavigable = navigables[n];
          }
        }

        return index;
      };

      Slick.prototype.cleanUpEvents = function () {
        var _ = this;

        if (_.options.dots && _.$dots !== null) {
          $('li', _.$dots).off('click.slick', _.changeSlide).off('mouseenter.slick', $.proxy(_.interrupt, _, true)).off('mouseleave.slick', $.proxy(_.interrupt, _, false));

          if (_.options.accessibility === true) {
            _.$dots.off('keydown.slick', _.keyHandler);
          }
        }

        _.$slider.off('focus.slick blur.slick');

        if (_.options.arrows === true && _.slideCount > _.options.slidesToShow) {
          _.$prevArrow && _.$prevArrow.off('click.slick', _.changeSlide);
          _.$nextArrow && _.$nextArrow.off('click.slick', _.changeSlide);

          if (_.options.accessibility === true) {
            _.$prevArrow && _.$prevArrow.off('keydown.slick', _.keyHandler);
            _.$nextArrow && _.$nextArrow.off('keydown.slick', _.keyHandler);
          }
        }

        _.$list.off('touchstart.slick mousedown.slick', _.swipeHandler);

        _.$list.off('touchmove.slick mousemove.slick', _.swipeHandler);

        _.$list.off('touchend.slick mouseup.slick', _.swipeHandler);

        _.$list.off('touchcancel.slick mouseleave.slick', _.swipeHandler);

        _.$list.off('click.slick', _.clickHandler);

        $(document).off(_.visibilityChange, _.visibility);

        _.cleanUpSlideEvents();

        if (_.options.accessibility === true) {
          _.$list.off('keydown.slick', _.keyHandler);
        }

        if (_.options.focusOnSelect === true) {
          $(_.$slideTrack).children().off('click.slick', _.selectHandler);
        }

        $(window).off('orientationchange.slick.slick-' + _.instanceUid, _.orientationChange);
        $(window).off('resize.slick.slick-' + _.instanceUid, _.resize);
        $('[draggable!=true]', _.$slideTrack).off('dragstart', _.preventDefault);
        $(window).off('load.slick.slick-' + _.instanceUid, _.setPosition);
      };

      Slick.prototype.cleanUpSlideEvents = function () {
        var _ = this;

        _.$list.off('mouseenter.slick', $.proxy(_.interrupt, _, true));

        _.$list.off('mouseleave.slick', $.proxy(_.interrupt, _, false));
      };

      Slick.prototype.cleanUpRows = function () {
        var _ = this,
            originalSlides;

        if (_.options.rows > 0) {
          originalSlides = _.$slides.children().children();
          originalSlides.removeAttr('style');

          _.$slider.empty().append(originalSlides);
        }
      };

      Slick.prototype.clickHandler = function (event) {
        var _ = this;

        if (_.shouldClick === false) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();
        }
      };

      Slick.prototype.destroy = function (refresh) {
        var _ = this;

        _.autoPlayClear();

        _.touchObject = {};

        _.cleanUpEvents();

        $('.slick-cloned', _.$slider).detach();

        if (_.$dots) {
          _.$dots.remove();
        }

        if (_.$prevArrow && _.$prevArrow.length) {
          _.$prevArrow.removeClass('slick-disabled slick-arrow slick-hidden').removeAttr('aria-hidden aria-disabled tabindex').css('display', '');

          if (_.htmlExpr.test(_.options.prevArrow)) {
            _.$prevArrow.remove();
          }
        }

        if (_.$nextArrow && _.$nextArrow.length) {
          _.$nextArrow.removeClass('slick-disabled slick-arrow slick-hidden').removeAttr('aria-hidden aria-disabled tabindex').css('display', '');

          if (_.htmlExpr.test(_.options.nextArrow)) {
            _.$nextArrow.remove();
          }
        }

        if (_.$slides) {
          _.$slides.removeClass('slick-slide slick-active slick-center slick-visible slick-current').removeAttr('aria-hidden').removeAttr('data-slick-index').each(function () {
            $(this).attr('style', $(this).data('originalStyling'));
          });

          _.$slideTrack.children(this.options.slide).detach();

          _.$slideTrack.detach();

          _.$list.detach();

          _.$slider.append(_.$slides);
        }

        _.cleanUpRows();

        _.$slider.removeClass('slick-slider');

        _.$slider.removeClass('slick-initialized');

        _.$slider.removeClass('slick-dotted');

        _.unslicked = true;

        if (!refresh) {
          _.$slider.trigger('destroy', [_]);
        }
      };

      Slick.prototype.disableTransition = function (slide) {
        var _ = this,
            transition = {};

        transition[_.transitionType] = '';

        if (_.options.fade === false) {
          _.$slideTrack.css(transition);
        } else {
          _.$slides.eq(slide).css(transition);
        }
      };

      Slick.prototype.fadeSlide = function (slideIndex, callback) {
        var _ = this;

        if (_.cssTransitions === false) {
          _.$slides.eq(slideIndex).css({
            zIndex: _.options.zIndex
          });

          _.$slides.eq(slideIndex).animate({
            opacity: 1
          }, _.options.speed, _.options.easing, callback);
        } else {
          _.applyTransition(slideIndex);

          _.$slides.eq(slideIndex).css({
            opacity: 1,
            zIndex: _.options.zIndex
          });

          if (callback) {
            setTimeout(function () {
              _.disableTransition(slideIndex);

              callback.call();
            }, _.options.speed);
          }
        }
      };

      Slick.prototype.fadeSlideOut = function (slideIndex) {
        var _ = this;

        if (_.cssTransitions === false) {
          _.$slides.eq(slideIndex).animate({
            opacity: 0,
            zIndex: _.options.zIndex - 2
          }, _.options.speed, _.options.easing);
        } else {
          _.applyTransition(slideIndex);

          _.$slides.eq(slideIndex).css({
            opacity: 0,
            zIndex: _.options.zIndex - 2
          });
        }
      };

      Slick.prototype.filterSlides = Slick.prototype.slickFilter = function (filter) {
        var _ = this;

        if (filter !== null) {
          _.$slidesCache = _.$slides;

          _.unload();

          _.$slideTrack.children(this.options.slide).detach();

          _.$slidesCache.filter(filter).appendTo(_.$slideTrack);

          _.reinit();
        }
      };

      Slick.prototype.focusHandler = function () {
        var _ = this;

        _.$slider.off('focus.slick blur.slick').on('focus.slick blur.slick', '*', function (event) {
          event.stopImmediatePropagation();
          var $sf = $(this);
          setTimeout(function () {
            if (_.options.pauseOnFocus) {
              _.focussed = $sf.is(':focus');

              _.autoPlay();
            }
          }, 0);
        });
      };

      Slick.prototype.getCurrent = Slick.prototype.slickCurrentSlide = function () {
        var _ = this;

        return _.currentSlide;
      };

      Slick.prototype.getDotCount = function () {
        var _ = this;

        var breakPoint = 0;
        var counter = 0;
        var pagerQty = 0;

        if (_.options.infinite === true) {
          if (_.slideCount <= _.options.slidesToShow) {
            ++pagerQty;
          } else {
            while (breakPoint < _.slideCount) {
              ++pagerQty;
              breakPoint = counter + _.options.slidesToScroll;
              counter += _.options.slidesToScroll <= _.options.slidesToShow ? _.options.slidesToScroll : _.options.slidesToShow;
            }
          }
        } else if (_.options.centerMode === true) {
          pagerQty = _.slideCount;
        } else if (!_.options.asNavFor) {
          pagerQty = 1 + Math.ceil((_.slideCount - _.options.slidesToShow) / _.options.slidesToScroll);
        } else {
          while (breakPoint < _.slideCount) {
            ++pagerQty;
            breakPoint = counter + _.options.slidesToScroll;
            counter += _.options.slidesToScroll <= _.options.slidesToShow ? _.options.slidesToScroll : _.options.slidesToShow;
          }
        }

        return pagerQty - 1;
      };

      Slick.prototype.getLeft = function (slideIndex) {
        var _ = this,
            targetLeft,
            verticalHeight,
            verticalOffset = 0,
            targetSlide,
            coef;

        _.slideOffset = 0;
        verticalHeight = _.$slides.first().outerHeight(true);

        if (_.options.infinite === true) {
          if (_.slideCount > _.options.slidesToShow) {
            _.slideOffset = _.slideWidth * _.options.slidesToShow * -1;
            coef = -1;

            if (_.options.vertical === true && _.options.centerMode === true) {
              if (_.options.slidesToShow === 2) {
                coef = -1.5;
              } else if (_.options.slidesToShow === 1) {
                coef = -2;
              }
            }

            verticalOffset = verticalHeight * _.options.slidesToShow * coef;
          }

          if (_.slideCount % _.options.slidesToScroll !== 0) {
            if (slideIndex + _.options.slidesToScroll > _.slideCount && _.slideCount > _.options.slidesToShow) {
              if (slideIndex > _.slideCount) {
                _.slideOffset = (_.options.slidesToShow - (slideIndex - _.slideCount)) * _.slideWidth * -1;
                verticalOffset = (_.options.slidesToShow - (slideIndex - _.slideCount)) * verticalHeight * -1;
              } else {
                _.slideOffset = _.slideCount % _.options.slidesToScroll * _.slideWidth * -1;
                verticalOffset = _.slideCount % _.options.slidesToScroll * verticalHeight * -1;
              }
            }
          }
        } else {
          if (slideIndex + _.options.slidesToShow > _.slideCount) {
            _.slideOffset = (slideIndex + _.options.slidesToShow - _.slideCount) * _.slideWidth;
            verticalOffset = (slideIndex + _.options.slidesToShow - _.slideCount) * verticalHeight;
          }
        }

        if (_.slideCount <= _.options.slidesToShow) {
          _.slideOffset = 0;
          verticalOffset = 0;
        }

        if (_.options.centerMode === true && _.slideCount <= _.options.slidesToShow) {
          _.slideOffset = _.slideWidth * Math.floor(_.options.slidesToShow) / 2 - _.slideWidth * _.slideCount / 2;
        } else if (_.options.centerMode === true && _.options.infinite === true) {
          _.slideOffset += _.slideWidth * Math.floor(_.options.slidesToShow / 2) - _.slideWidth;
        } else if (_.options.centerMode === true) {
          _.slideOffset = 0;
          _.slideOffset += _.slideWidth * Math.floor(_.options.slidesToShow / 2);
        }

        if (_.options.vertical === false) {
          targetLeft = slideIndex * _.slideWidth * -1 + _.slideOffset;
        } else {
          targetLeft = slideIndex * verticalHeight * -1 + verticalOffset;
        }

        if (_.options.variableWidth === true) {
          if (_.slideCount <= _.options.slidesToShow || _.options.infinite === false) {
            targetSlide = _.$slideTrack.children('.slick-slide').eq(slideIndex);
          } else {
            targetSlide = _.$slideTrack.children('.slick-slide').eq(slideIndex + _.options.slidesToShow);
          }

          if (_.options.rtl === true) {
            if (targetSlide[0]) {
              targetLeft = (_.$slideTrack.width() - targetSlide[0].offsetLeft - targetSlide.width()) * -1;
            } else {
              targetLeft = 0;
            }
          } else {
            targetLeft = targetSlide[0] ? targetSlide[0].offsetLeft * -1 : 0;
          }

          if (_.options.centerMode === true) {
            if (_.slideCount <= _.options.slidesToShow || _.options.infinite === false) {
              targetSlide = _.$slideTrack.children('.slick-slide').eq(slideIndex);
            } else {
              targetSlide = _.$slideTrack.children('.slick-slide').eq(slideIndex + _.options.slidesToShow + 1);
            }

            if (_.options.rtl === true) {
              if (targetSlide[0]) {
                targetLeft = (_.$slideTrack.width() - targetSlide[0].offsetLeft - targetSlide.width()) * -1;
              } else {
                targetLeft = 0;
              }
            } else {
              targetLeft = targetSlide[0] ? targetSlide[0].offsetLeft * -1 : 0;
            }

            targetLeft += (_.$list.width() - targetSlide.outerWidth()) / 2;
          }
        }

        return targetLeft;
      };

      Slick.prototype.getOption = Slick.prototype.slickGetOption = function (option) {
        var _ = this;

        return _.options[option];
      };

      Slick.prototype.getNavigableIndexes = function () {
        var _ = this,
            breakPoint = 0,
            counter = 0,
            indexes = [],
            max;

        if (_.options.infinite === false) {
          max = _.slideCount;
        } else {
          breakPoint = _.options.slidesToScroll * -1;
          counter = _.options.slidesToScroll * -1;
          max = _.slideCount * 2;
        }

        while (breakPoint < max) {
          indexes.push(breakPoint);
          breakPoint = counter + _.options.slidesToScroll;
          counter += _.options.slidesToScroll <= _.options.slidesToShow ? _.options.slidesToScroll : _.options.slidesToShow;
        }

        return indexes;
      };

      Slick.prototype.getSlick = function () {
        return this;
      };

      Slick.prototype.getSlideCount = function () {
        var _ = this,
            slidesTraversed,
            swipedSlide,
            centerOffset;

        centerOffset = _.options.centerMode === true ? _.slideWidth * Math.floor(_.options.slidesToShow / 2) : 0;

        if (_.options.swipeToSlide === true) {
          _.$slideTrack.find('.slick-slide').each(function (index, slide) {
            if (slide.offsetLeft - centerOffset + $(slide).outerWidth() / 2 > _.swipeLeft * -1) {
              swipedSlide = slide;
              return false;
            }
          });

          slidesTraversed = Math.abs($(swipedSlide).attr('data-slick-index') - _.currentSlide) || 1;
          return slidesTraversed;
        } else {
          return _.options.slidesToScroll;
        }
      };

      Slick.prototype.goTo = Slick.prototype.slickGoTo = function (slide, dontAnimate) {
        var _ = this;

        _.changeSlide({
          data: {
            message: 'index',
            index: parseInt(slide)
          }
        }, dontAnimate);
      };

      Slick.prototype.init = function (creation) {
        var _ = this;

        if (!$(_.$slider).hasClass('slick-initialized')) {
          $(_.$slider).addClass('slick-initialized');

          _.buildRows();

          _.buildOut();

          _.setProps();

          _.startLoad();

          _.loadSlider();

          _.initializeEvents();

          _.updateArrows();

          _.updateDots();

          _.checkResponsive(true);

          _.focusHandler();
        }

        if (creation) {
          _.$slider.trigger('init', [_]);
        }

        if (_.options.accessibility === true) {
          _.initADA();
        }

        if (_.options.autoplay) {
          _.paused = false;

          _.autoPlay();
        }
      };

      Slick.prototype.initADA = function () {
        var _ = this,
            numDotGroups = Math.ceil(_.slideCount / _.options.slidesToShow),
            tabControlIndexes = _.getNavigableIndexes().filter(function (val) {
          return val >= 0 && val < _.slideCount;
        });

        _.$slides.add(_.$slideTrack.find('.slick-cloned')).attr({
          'aria-hidden': 'true',
          'tabindex': '-1'
        }).find('a, input, button, select').attr({
          'tabindex': '-1'
        });

        if (_.$dots !== null) {
          _.$slides.not(_.$slideTrack.find('.slick-cloned')).each(function (i) {
            var slideControlIndex = tabControlIndexes.indexOf(i);
            $(this).attr({
              'role': 'tabpanel',
              'id': 'slick-slide' + _.instanceUid + i,
              'tabindex': -1
            });

            if (slideControlIndex !== -1) {
              var ariaButtonControl = 'slick-slide-control' + _.instanceUid + slideControlIndex;

              if ($('#' + ariaButtonControl).length) {
                $(this).attr({
                  'aria-describedby': ariaButtonControl
                });
              }
            }
          });

          _.$dots.attr('role', 'tablist').find('li').each(function (i) {
            var mappedSlideIndex = tabControlIndexes[i];
            $(this).attr({
              'role': 'presentation'
            });
            $(this).find('button').first().attr({
              'role': 'tab',
              'id': 'slick-slide-control' + _.instanceUid + i,
              'aria-controls': 'slick-slide' + _.instanceUid + mappedSlideIndex,
              'aria-label': i + 1 + ' of ' + numDotGroups,
              'aria-selected': null,
              'tabindex': '-1'
            });
          }).eq(_.currentSlide).find('button').attr({
            'aria-selected': 'true',
            'tabindex': '0'
          }).end();
        }

        for (var i = _.currentSlide, max = i + _.options.slidesToShow; i < max; i++) {
          if (_.options.focusOnChange) {
            _.$slides.eq(i).attr({
              'tabindex': '0'
            });
          } else {
            _.$slides.eq(i).removeAttr('tabindex');
          }
        }

        _.activateADA();
      };

      Slick.prototype.initArrowEvents = function () {
        var _ = this;

        if (_.options.arrows === true && _.slideCount > _.options.slidesToShow) {
          _.$prevArrow.off('click.slick').on('click.slick', {
            message: 'previous'
          }, _.changeSlide);

          _.$nextArrow.off('click.slick').on('click.slick', {
            message: 'next'
          }, _.changeSlide);

          if (_.options.accessibility === true) {
            _.$prevArrow.on('keydown.slick', _.keyHandler);

            _.$nextArrow.on('keydown.slick', _.keyHandler);
          }
        }
      };

      Slick.prototype.initDotEvents = function () {
        var _ = this;

        if (_.options.dots === true && _.slideCount > _.options.slidesToShow) {
          $('li', _.$dots).on('click.slick', {
            message: 'index'
          }, _.changeSlide);

          if (_.options.accessibility === true) {
            _.$dots.on('keydown.slick', _.keyHandler);
          }
        }

        if (_.options.dots === true && _.options.pauseOnDotsHover === true && _.slideCount > _.options.slidesToShow) {
          $('li', _.$dots).on('mouseenter.slick', $.proxy(_.interrupt, _, true)).on('mouseleave.slick', $.proxy(_.interrupt, _, false));
        }
      };

      Slick.prototype.initSlideEvents = function () {
        var _ = this;

        if (_.options.pauseOnHover) {
          _.$list.on('mouseenter.slick', $.proxy(_.interrupt, _, true));

          _.$list.on('mouseleave.slick', $.proxy(_.interrupt, _, false));
        }
      };

      Slick.prototype.initializeEvents = function () {
        var _ = this;

        _.initArrowEvents();

        _.initDotEvents();

        _.initSlideEvents();

        _.$list.on('touchstart.slick mousedown.slick', {
          action: 'start'
        }, _.swipeHandler);

        _.$list.on('touchmove.slick mousemove.slick', {
          action: 'move'
        }, _.swipeHandler);

        _.$list.on('touchend.slick mouseup.slick', {
          action: 'end'
        }, _.swipeHandler);

        _.$list.on('touchcancel.slick mouseleave.slick', {
          action: 'end'
        }, _.swipeHandler);

        _.$list.on('click.slick', _.clickHandler);

        $(document).on(_.visibilityChange, $.proxy(_.visibility, _));

        if (_.options.accessibility === true) {
          _.$list.on('keydown.slick', _.keyHandler);
        }

        if (_.options.focusOnSelect === true) {
          $(_.$slideTrack).children().on('click.slick', _.selectHandler);
        }

        $(window).on('orientationchange.slick.slick-' + _.instanceUid, $.proxy(_.orientationChange, _));
        $(window).on('resize.slick.slick-' + _.instanceUid, $.proxy(_.resize, _));
        $('[draggable!=true]', _.$slideTrack).on('dragstart', _.preventDefault);
        $(window).on('load.slick.slick-' + _.instanceUid, _.setPosition);
        $(_.setPosition);
      };

      Slick.prototype.initUI = function () {
        var _ = this;

        if (_.options.arrows === true && _.slideCount > _.options.slidesToShow) {
          _.$prevArrow.show();

          _.$nextArrow.show();
        }

        if (_.options.dots === true && _.slideCount > _.options.slidesToShow) {
          _.$dots.show();
        }
      };

      Slick.prototype.keyHandler = function (event) {
        var _ = this; 


        if (!event.target.tagName.match('TEXTAREA|INPUT|SELECT')) {
          if (event.keyCode === 37 && _.options.accessibility === true) {
            _.changeSlide({
              data: {
                message: _.options.rtl === true ? 'next' : 'previous'
              }
            });
          } else if (event.keyCode === 39 && _.options.accessibility === true) {
            _.changeSlide({
              data: {
                message: _.options.rtl === true ? 'previous' : 'next'
              }
            });
          }
        }
      };

      Slick.prototype.lazyLoad = function () {
        var _ = this,
            loadRange,
            cloneRange,
            rangeStart,
            rangeEnd;

        function loadImages(imagesScope) {
          $('img[data-lazy]', imagesScope).each(function () {
            var image = $(this),
                imageSource = $(this).attr('data-lazy'),
                imageSrcSet = $(this).attr('data-srcset'),
                imageSizes = $(this).attr('data-sizes') || _.$slider.attr('data-sizes'),
                imageToLoad = document.createElement('img');

            imageToLoad.onload = function () {
              image.animate({
                opacity: 0
              }, 100, function () {
                if (imageSrcSet) {
                  image.attr('srcset', imageSrcSet);

                  if (imageSizes) {
                    image.attr('sizes', imageSizes);
                  }
                }

                image.attr('src', imageSource).animate({
                  opacity: 1
                }, 200, function () {
                  image.removeAttr('data-lazy data-srcset data-sizes').removeClass('slick-loading');
                });

                _.$slider.trigger('lazyLoaded', [_, image, imageSource]);
              });
            };

            imageToLoad.onerror = function () {
              image.removeAttr('data-lazy').removeClass('slick-loading').addClass('slick-lazyload-error');

              _.$slider.trigger('lazyLoadError', [_, image, imageSource]);
            };

            imageToLoad.src = imageSource;
          });
        }

        if (_.options.centerMode === true) {
          if (_.options.infinite === true) {
            rangeStart = _.currentSlide + (_.options.slidesToShow / 2 + 1);
            rangeEnd = rangeStart + _.options.slidesToShow + 2;
          } else {
            rangeStart = Math.max(0, _.currentSlide - (_.options.slidesToShow / 2 + 1));
            rangeEnd = 2 + (_.options.slidesToShow / 2 + 1) + _.currentSlide;
          }
        } else {
          rangeStart = _.options.infinite ? _.options.slidesToShow + _.currentSlide : _.currentSlide;
          rangeEnd = Math.ceil(rangeStart + _.options.slidesToShow);

          if (_.options.fade === true) {
            if (rangeStart > 0) rangeStart--;
            if (rangeEnd <= _.slideCount) rangeEnd++;
          }
        }

        loadRange = _.$slider.find('.slick-slide').slice(rangeStart, rangeEnd);

        if (_.options.lazyLoad === 'anticipated') {
          var prevSlide = rangeStart - 1,
              nextSlide = rangeEnd,
              $slides = _.$slider.find('.slick-slide');

          for (var i = 0; i < _.options.slidesToScroll; i++) {
            if (prevSlide < 0) prevSlide = _.slideCount - 1;
            loadRange = loadRange.add($slides.eq(prevSlide));
            loadRange = loadRange.add($slides.eq(nextSlide));
            prevSlide--;
            nextSlide++;
          }
        }

        loadImages(loadRange);

        if (_.slideCount <= _.options.slidesToShow) {
          cloneRange = _.$slider.find('.slick-slide');
          loadImages(cloneRange);
        } else if (_.currentSlide >= _.slideCount - _.options.slidesToShow) {
          cloneRange = _.$slider.find('.slick-cloned').slice(0, _.options.slidesToShow);
          loadImages(cloneRange);
        } else if (_.currentSlide === 0) {
          cloneRange = _.$slider.find('.slick-cloned').slice(_.options.slidesToShow * -1);
          loadImages(cloneRange);
        }
      };

      Slick.prototype.loadSlider = function () {
        var _ = this;

        _.setPosition();

        _.$slideTrack.css({
          opacity: 1
        });

        _.$slider.removeClass('slick-loading');

        _.initUI();

        if (_.options.lazyLoad === 'progressive') {
          _.progressiveLazyLoad();
        }
      };

      Slick.prototype.next = Slick.prototype.slickNext = function () {
        var _ = this;

        _.changeSlide({
          data: {
            message: 'next'
          }
        });
      };

      Slick.prototype.orientationChange = function () {
        var _ = this;

        _.checkResponsive();

        _.setPosition();
      };

      Slick.prototype.pause = Slick.prototype.slickPause = function () {
        var _ = this;

        _.autoPlayClear();

        _.paused = true;
      };

      Slick.prototype.play = Slick.prototype.slickPlay = function () {
        var _ = this;

        _.autoPlay();

        _.options.autoplay = true;
        _.paused = false;
        _.focussed = false;
        _.interrupted = false;
      };

      Slick.prototype.postSlide = function (index) {
        var _ = this;

        if (!_.unslicked) {
          _.$slider.trigger('afterChange', [_, index]);

          _.animating = false;

          if (_.slideCount > _.options.slidesToShow) {
            _.setPosition();
          }

          _.swipeLeft = null;

          if (_.options.autoplay) {
            _.autoPlay();
          }

          if (_.options.accessibility === true) {
            _.initADA();

            if (_.options.focusOnChange) {
              var $currentSlide = $(_.$slides.get(_.currentSlide));
              $currentSlide.attr('tabindex', 0).focus();
            }
          }
        }
      };

      Slick.prototype.prev = Slick.prototype.slickPrev = function () {
        var _ = this;

        _.changeSlide({
          data: {
            message: 'previous'
          }
        });
      };

      Slick.prototype.preventDefault = function (event) {
        event.preventDefault();
      };

      Slick.prototype.progressiveLazyLoad = function (tryCount) {
        tryCount = tryCount || 1;

        var _ = this,
            $imgsToLoad = $('img[data-lazy]', _.$slider),
            image,
            imageSource,
            imageSrcSet,
            imageSizes,
            imageToLoad;

        if ($imgsToLoad.length) {
          image = $imgsToLoad.first();
          imageSource = image.attr('data-lazy');
          imageSrcSet = image.attr('data-srcset');
          imageSizes = image.attr('data-sizes') || _.$slider.attr('data-sizes');
          imageToLoad = document.createElement('img');

          imageToLoad.onload = function () {
            if (imageSrcSet) {
              image.attr('srcset', imageSrcSet);

              if (imageSizes) {
                image.attr('sizes', imageSizes);
              }
            }

            image.attr('src', imageSource).removeAttr('data-lazy data-srcset data-sizes').removeClass('slick-loading');

            if (_.options.adaptiveHeight === true) {
              _.setPosition();
            }

            _.$slider.trigger('lazyLoaded', [_, image, imageSource]);

            _.progressiveLazyLoad();
          };

          imageToLoad.onerror = function () {
            if (tryCount < 3) {
              /**
               * try to load the image 3 times,
               * leave a slight delay so we don't get
               * servers blocking the request.
               */
              setTimeout(function () {
                _.progressiveLazyLoad(tryCount + 1);
              }, 500);
            } else {
              image.removeAttr('data-lazy').removeClass('slick-loading').addClass('slick-lazyload-error');

              _.$slider.trigger('lazyLoadError', [_, image, imageSource]);

              _.progressiveLazyLoad();
            }
          };

          imageToLoad.src = imageSource;
        } else {
          _.$slider.trigger('allImagesLoaded', [_]);
        }
      };

      Slick.prototype.refresh = function (initializing) {
        var _ = this,
            currentSlide,
            lastVisibleIndex;

        lastVisibleIndex = _.slideCount - _.options.slidesToShow; 

        if (!_.options.infinite && _.currentSlide > lastVisibleIndex) {
          _.currentSlide = lastVisibleIndex;
        } 


        if (_.slideCount <= _.options.slidesToShow) {
          _.currentSlide = 0;
        }

        currentSlide = _.currentSlide;

        _.destroy(true);

        $.extend(_, _.initials, {
          currentSlide: currentSlide
        });

        _.init();

        if (!initializing) {
          _.changeSlide({
            data: {
              message: 'index',
              index: currentSlide
            }
          }, false);
        }
      };

      Slick.prototype.registerBreakpoints = function () {
        var _ = this,
            breakpoint,
            currentBreakpoint,
            l,
            responsiveSettings = _.options.responsive || null;

        if ($.type(responsiveSettings) === 'array' && responsiveSettings.length) {
          _.respondTo = _.options.respondTo || 'window';

          for (breakpoint in responsiveSettings) {
            l = _.breakpoints.length - 1;

            if (responsiveSettings.hasOwnProperty(breakpoint)) {
              currentBreakpoint = responsiveSettings[breakpoint].breakpoint; 

              while (l >= 0) {
                if (_.breakpoints[l] && _.breakpoints[l] === currentBreakpoint) {
                  _.breakpoints.splice(l, 1);
                }

                l--;
              }

              _.breakpoints.push(currentBreakpoint);

              _.breakpointSettings[currentBreakpoint] = responsiveSettings[breakpoint].settings;
            }
          }

          _.breakpoints.sort(function (a, b) {
            return _.options.mobileFirst ? a - b : b - a;
          });
        }
      };

      Slick.prototype.reinit = function () {
        var _ = this;

        _.$slides = _.$slideTrack.children(_.options.slide).addClass('slick-slide');
        _.slideCount = _.$slides.length;

        if (_.currentSlide >= _.slideCount && _.currentSlide !== 0) {
          _.currentSlide = _.currentSlide - _.options.slidesToScroll;
        }

        if (_.slideCount <= _.options.slidesToShow) {
          _.currentSlide = 0;
        }

        _.registerBreakpoints();

        _.setProps();

        _.setupInfinite();

        _.buildArrows();

        _.updateArrows();

        _.initArrowEvents();

        _.buildDots();

        _.updateDots();

        _.initDotEvents();

        _.cleanUpSlideEvents();

        _.initSlideEvents();

        _.checkResponsive(false, true);

        if (_.options.focusOnSelect === true) {
          $(_.$slideTrack).children().on('click.slick', _.selectHandler);
        }

        _.setSlideClasses(typeof _.currentSlide === 'number' ? _.currentSlide : 0);

        _.setPosition();

        _.focusHandler();

        _.paused = !_.options.autoplay;

        _.autoPlay();

        _.$slider.trigger('reInit', [_]);
      };

      Slick.prototype.resize = function () {
        var _ = this;

        if ($(window).width() !== _.windowWidth) {
          clearTimeout(_.windowDelay);
          _.windowDelay = window.setTimeout(function () {
            _.windowWidth = $(window).width();

            _.checkResponsive();

            if (!_.unslicked) {
              _.setPosition();
            }
          }, 50);
        }
      };

      Slick.prototype.removeSlide = Slick.prototype.slickRemove = function (index, removeBefore, removeAll) {
        var _ = this;

        if (typeof index === 'boolean') {
          removeBefore = index;
          index = removeBefore === true ? 0 : _.slideCount - 1;
        } else {
          index = removeBefore === true ? --index : index;
        }

        if (_.slideCount < 1 || index < 0 || index > _.slideCount - 1) {
          return false;
        }

        _.unload();

        if (removeAll === true) {
          _.$slideTrack.children().remove();
        } else {
          _.$slideTrack.children(this.options.slide).eq(index).remove();
        }

        _.$slides = _.$slideTrack.children(this.options.slide);

        _.$slideTrack.children(this.options.slide).detach();

        _.$slideTrack.append(_.$slides);

        _.$slidesCache = _.$slides;

        _.reinit();
      };

      Slick.prototype.setCSS = function (position) {
        var _ = this,
            positionProps = {},
            x,
            y;

        if (_.options.rtl === true) {
          position = -position;
        }

        x = _.positionProp == 'left' ? Math.ceil(position) + 'px' : '0px';
        y = _.positionProp == 'top' ? Math.ceil(position) + 'px' : '0px';
        positionProps[_.positionProp] = position;

        if (_.transformsEnabled === false) {
          _.$slideTrack.css(positionProps);
        } else {
          positionProps = {};

          if (_.cssTransitions === false) {
            positionProps[_.animType] = 'translate(' + x + ', ' + y + ')';

            _.$slideTrack.css(positionProps);
          } else {
            positionProps[_.animType] = 'translate3d(' + x + ', ' + y + ', 0px)';

            _.$slideTrack.css(positionProps);
          }
        }
      };

      Slick.prototype.setDimensions = function () {
        var _ = this;

        if (_.options.vertical === false) {
          if (_.options.centerMode === true) {
            _.$list.css({
              padding: '0px ' + _.options.centerPadding
            });
          }
        } else {
          _.$list.height(_.$slides.first().outerHeight(true) * _.options.slidesToShow);

          if (_.options.centerMode === true) {
            _.$list.css({
              padding: _.options.centerPadding + ' 0px'
            });
          }
        }

        _.listWidth = _.$list.width();
        _.listHeight = _.$list.height();

        if (_.options.vertical === false && _.options.variableWidth === false) {
          _.slideWidth = Math.ceil(_.listWidth / _.options.slidesToShow);

          _.$slideTrack.width(Math.ceil(_.slideWidth * _.$slideTrack.children('.slick-slide').length));
        } else if (_.options.variableWidth === true) {
          _.$slideTrack.width(5000 * _.slideCount);
        } else {
          _.slideWidth = Math.ceil(_.listWidth);

          _.$slideTrack.height(Math.ceil(_.$slides.first().outerHeight(true) * _.$slideTrack.children('.slick-slide').length));
        }

        var offset = _.$slides.first().outerWidth(true) - _.$slides.first().width();

        if (_.options.variableWidth === false) _.$slideTrack.children('.slick-slide').width(_.slideWidth - offset);
      };

      Slick.prototype.setFade = function () {
        var _ = this,
            targetLeft;

        _.$slides.each(function (index, element) {
          targetLeft = _.slideWidth * index * -1;

          if (_.options.rtl === true) {
            $(element).css({
              position: 'relative',
              right: targetLeft,
              top: 0,
              zIndex: _.options.zIndex - 2,
              opacity: 0
            });
          } else {
            $(element).css({
              position: 'relative',
              left: targetLeft,
              top: 0,
              zIndex: _.options.zIndex - 2,
              opacity: 0
            });
          }
        });

        _.$slides.eq(_.currentSlide).css({
          zIndex: _.options.zIndex - 1,
          opacity: 1
        });
      };

      Slick.prototype.setHeight = function () {
        var _ = this;

        if (_.options.slidesToShow === 1 && _.options.adaptiveHeight === true && _.options.vertical === false) {
          var targetHeight = _.$slides.eq(_.currentSlide).outerHeight(true);

          _.$list.css('height', targetHeight);
        }
      };

      Slick.prototype.setOption = Slick.prototype.slickSetOption = function () {
        /**
         * accepts arguments in format of:
         *
         *  - for changing a single option's value:
         *     .slick("setOption", option, value, refresh )
         *
         *  - for changing a set of responsive options:
         *     .slick("setOption", 'responsive', [{}, ...], refresh )
         *
         *  - for updating multiple values at once (not responsive)
         *     .slick("setOption", { 'option': value, ... }, refresh )
         */
        var _ = this,
            l,
            item,
            option,
            value,
            refresh = false,
            type;

        if ($.type(arguments[0]) === 'object') {
          option = arguments[0];
          refresh = arguments[1];
          type = 'multiple';
        } else if ($.type(arguments[0]) === 'string') {
          option = arguments[0];
          value = arguments[1];
          refresh = arguments[2];

          if (arguments[0] === 'responsive' && $.type(arguments[1]) === 'array') {
            type = 'responsive';
          } else if (typeof arguments[1] !== 'undefined') {
            type = 'single';
          }
        }

        if (type === 'single') {
          _.options[option] = value;
        } else if (type === 'multiple') {
          $.each(option, function (opt, val) {
            _.options[opt] = val;
          });
        } else if (type === 'responsive') {
          for (item in value) {
            if ($.type(_.options.responsive) !== 'array') {
              _.options.responsive = [value[item]];
            } else {
              l = _.options.responsive.length - 1; 

              while (l >= 0) {
                if (_.options.responsive[l].breakpoint === value[item].breakpoint) {
                  _.options.responsive.splice(l, 1);
                }

                l--;
              }

              _.options.responsive.push(value[item]);
            }
          }
        }

        if (refresh) {
          _.unload();

          _.reinit();
        }
      };

      Slick.prototype.setPosition = function () {
        var _ = this;

        _.setDimensions();

        _.setHeight();

        if (_.options.fade === false) {
          _.setCSS(_.getLeft(_.currentSlide));
        } else {
          _.setFade();
        }

        _.$slider.trigger('setPosition', [_]);
      };

      Slick.prototype.setProps = function () {
        var _ = this,
            bodyStyle = document.body.style;

        _.positionProp = _.options.vertical === true ? 'top' : 'left';

        if (_.positionProp === 'top') {
          _.$slider.addClass('slick-vertical');
        } else {
          _.$slider.removeClass('slick-vertical');
        }

        if (bodyStyle.WebkitTransition !== undefined || bodyStyle.MozTransition !== undefined || bodyStyle.msTransition !== undefined) {
          if (_.options.useCSS === true) {
            _.cssTransitions = true;
          }
        }

        if (_.options.fade) {
          if (typeof _.options.zIndex === 'number') {
            if (_.options.zIndex < 3) {
              _.options.zIndex = 3;
            }
          } else {
            _.options.zIndex = _.defaults.zIndex;
          }
        }

        if (bodyStyle.OTransform !== undefined) {
          _.animType = 'OTransform';
          _.transformType = '-o-transform';
          _.transitionType = 'OTransition';
          if (bodyStyle.perspectiveProperty === undefined && bodyStyle.webkitPerspective === undefined) _.animType = false;
        }

        if (bodyStyle.MozTransform !== undefined) {
          _.animType = 'MozTransform';
          _.transformType = '-moz-transform';
          _.transitionType = 'MozTransition';
          if (bodyStyle.perspectiveProperty === undefined && bodyStyle.MozPerspective === undefined) _.animType = false;
        }

        if (bodyStyle.webkitTransform !== undefined) {
          _.animType = 'webkitTransform';
          _.transformType = '-webkit-transform';
          _.transitionType = 'webkitTransition';
          if (bodyStyle.perspectiveProperty === undefined && bodyStyle.webkitPerspective === undefined) _.animType = false;
        }

        if (bodyStyle.msTransform !== undefined) {
          _.animType = 'msTransform';
          _.transformType = '-ms-transform';
          _.transitionType = 'msTransition';
          if (bodyStyle.msTransform === undefined) _.animType = false;
        }

        if (bodyStyle.transform !== undefined && _.animType !== false) {
          _.animType = 'transform';
          _.transformType = 'transform';
          _.transitionType = 'transition';
        }

        _.transformsEnabled = _.options.useTransform && _.animType !== null && _.animType !== false;
      };

      Slick.prototype.setSlideClasses = function (index) {
        var _ = this,
            centerOffset,
            allSlides,
            indexOffset,
            remainder;

        allSlides = _.$slider.find('.slick-slide').removeClass('slick-active slick-center slick-current').attr('aria-hidden', 'true');

        _.$slides.eq(index).addClass('slick-current');

        if (_.options.centerMode === true) {
          var evenCoef = _.options.slidesToShow % 2 === 0 ? 1 : 0;
          centerOffset = Math.floor(_.options.slidesToShow / 2);

          if (_.options.infinite === true) {
            if (index >= centerOffset && index <= _.slideCount - 1 - centerOffset) {
              _.$slides.slice(index - centerOffset + evenCoef, index + centerOffset + 1).addClass('slick-active').attr('aria-hidden', 'false');
            } else {
              indexOffset = _.options.slidesToShow + index;
              allSlides.slice(indexOffset - centerOffset + 1 + evenCoef, indexOffset + centerOffset + 2).addClass('slick-active').attr('aria-hidden', 'false');
            }

            if (index === 0) {
              allSlides.eq(allSlides.length - 1 - _.options.slidesToShow).addClass('slick-center');
            } else if (index === _.slideCount - 1) {
              allSlides.eq(_.options.slidesToShow).addClass('slick-center');
            }
          }

          _.$slides.eq(index).addClass('slick-center');
        } else {
          if (index >= 0 && index <= _.slideCount - _.options.slidesToShow) {
            _.$slides.slice(index, index + _.options.slidesToShow).addClass('slick-active').attr('aria-hidden', 'false');
          } else if (allSlides.length <= _.options.slidesToShow) {
            allSlides.addClass('slick-active').attr('aria-hidden', 'false');
          } else {
            remainder = _.slideCount % _.options.slidesToShow;
            indexOffset = _.options.infinite === true ? _.options.slidesToShow + index : index;

            if (_.options.slidesToShow == _.options.slidesToScroll && _.slideCount - index < _.options.slidesToShow) {
              allSlides.slice(indexOffset - (_.options.slidesToShow - remainder), indexOffset + remainder).addClass('slick-active').attr('aria-hidden', 'false');
            } else {
              allSlides.slice(indexOffset, indexOffset + _.options.slidesToShow).addClass('slick-active').attr('aria-hidden', 'false');
            }
          }
        }

        if (_.options.lazyLoad === 'ondemand' || _.options.lazyLoad === 'anticipated') {
          _.lazyLoad();
        }
      };

      Slick.prototype.setupInfinite = function () {
        var _ = this,
            i,
            slideIndex,
            infiniteCount;

        if (_.options.fade === true) {
          _.options.centerMode = false;
        }

        if (_.options.infinite === true && _.options.fade === false) {
          slideIndex = null;

          if (_.slideCount > _.options.slidesToShow) {
            if (_.options.centerMode === true) {
              infiniteCount = _.options.slidesToShow + 1;
            } else {
              infiniteCount = _.options.slidesToShow;
            }

            for (i = _.slideCount; i > _.slideCount - infiniteCount; i -= 1) {
              slideIndex = i - 1;
              $(_.$slides[slideIndex]).clone(true).attr('id', '').attr('data-slick-index', slideIndex - _.slideCount).prependTo(_.$slideTrack).addClass('slick-cloned');
            }

            for (i = 0; i < infiniteCount + _.slideCount; i += 1) {
              slideIndex = i;
              $(_.$slides[slideIndex]).clone(true).attr('id', '').attr('data-slick-index', slideIndex + _.slideCount).appendTo(_.$slideTrack).addClass('slick-cloned');
            }

            _.$slideTrack.find('.slick-cloned').find('[id]').each(function () {
              $(this).attr('id', '');
            });
          }
        }
      };

      Slick.prototype.interrupt = function (toggle) {
        var _ = this;

        if (!toggle) {
          _.autoPlay();
        }

        _.interrupted = toggle;
      };

      Slick.prototype.selectHandler = function (event) {
        var _ = this;

        var targetElement = $(event.target).is('.slick-slide') ? $(event.target) : $(event.target).parents('.slick-slide');
        var index = parseInt(targetElement.attr('data-slick-index'));
        if (!index) index = 0;

        if (_.slideCount <= _.options.slidesToShow) {
          _.slideHandler(index, false, true);

          return;
        }

        _.slideHandler(index);
      };

      Slick.prototype.slideHandler = function (index, sync, dontAnimate) {
        var targetSlide,
            animSlide,
            oldSlide,
            slideLeft,
            targetLeft = null,
            _ = this,
            navTarget;

        sync = sync || false;

        if (_.animating === true && _.options.waitForAnimate === true) {
          return;
        }

        if (_.options.fade === true && _.currentSlide === index) {
          return;
        }

        if (sync === false) {
          _.asNavFor(index);
        }

        targetSlide = index;
        targetLeft = _.getLeft(targetSlide);
        slideLeft = _.getLeft(_.currentSlide);
        _.currentLeft = _.swipeLeft === null ? slideLeft : _.swipeLeft;

        if (_.options.infinite === false && _.options.centerMode === false && (index < 0 || index > _.getDotCount() * _.options.slidesToScroll)) {
          if (_.options.fade === false) {
            targetSlide = _.currentSlide;

            if (dontAnimate !== true && _.slideCount > _.options.slidesToShow) {
              _.animateSlide(slideLeft, function () {
                _.postSlide(targetSlide);
              });
            } else {
              _.postSlide(targetSlide);
            }
          }

          return;
        } else if (_.options.infinite === false && _.options.centerMode === true && (index < 0 || index > _.slideCount - _.options.slidesToScroll)) {
          if (_.options.fade === false) {
            targetSlide = _.currentSlide;

            if (dontAnimate !== true && _.slideCount > _.options.slidesToShow) {
              _.animateSlide(slideLeft, function () {
                _.postSlide(targetSlide);
              });
            } else {
              _.postSlide(targetSlide);
            }
          }

          return;
        }

        if (_.options.autoplay) {
          clearInterval(_.autoPlayTimer);
        }

        if (targetSlide < 0) {
          if (_.slideCount % _.options.slidesToScroll !== 0) {
            animSlide = _.slideCount - _.slideCount % _.options.slidesToScroll;
          } else {
            animSlide = _.slideCount + targetSlide;
          }
        } else if (targetSlide >= _.slideCount) {
          if (_.slideCount % _.options.slidesToScroll !== 0) {
            animSlide = 0;
          } else {
            animSlide = targetSlide - _.slideCount;
          }
        } else {
          animSlide = targetSlide;
        }

        _.animating = true;

        _.$slider.trigger('beforeChange', [_, _.currentSlide, animSlide]);

        oldSlide = _.currentSlide;
        _.currentSlide = animSlide;

        _.setSlideClasses(_.currentSlide);

        if (_.options.asNavFor) {
          navTarget = _.getNavTarget();
          navTarget = navTarget.slick('getSlick');

          if (navTarget.slideCount <= navTarget.options.slidesToShow) {
            navTarget.setSlideClasses(_.currentSlide);
          }
        }

        _.updateDots();

        _.updateArrows();

        if (_.options.fade === true) {
          if (dontAnimate !== true) {
            _.fadeSlideOut(oldSlide);

            _.fadeSlide(animSlide, function () {
              _.postSlide(animSlide);
            });
          } else {
            _.postSlide(animSlide);
          }

          _.animateHeight();

          return;
        }

        if (dontAnimate !== true && _.slideCount > _.options.slidesToShow) {
          _.animateSlide(targetLeft, function () {
            _.postSlide(animSlide);
          });
        } else {
          _.postSlide(animSlide);
        }
      };

      Slick.prototype.startLoad = function () {
        var _ = this;

        if (_.options.arrows === true && _.slideCount > _.options.slidesToShow) {
          _.$prevArrow.hide();

          _.$nextArrow.hide();
        }

        if (_.options.dots === true && _.slideCount > _.options.slidesToShow) {
          _.$dots.hide();
        }

        _.$slider.addClass('slick-loading');
      };

      Slick.prototype.swipeDirection = function () {
        var xDist,
            yDist,
            r,
            swipeAngle,
            _ = this;

        xDist = _.touchObject.startX - _.touchObject.curX;
        yDist = _.touchObject.startY - _.touchObject.curY;
        r = Math.atan2(yDist, xDist);
        swipeAngle = Math.round(r * 180 / Math.PI);

        if (swipeAngle < 0) {
          swipeAngle = 360 - Math.abs(swipeAngle);
        }

        if (swipeAngle <= 45 && swipeAngle >= 0) {
          return _.options.rtl === false ? 'left' : 'right';
        }

        if (swipeAngle <= 360 && swipeAngle >= 315) {
          return _.options.rtl === false ? 'left' : 'right';
        }

        if (swipeAngle >= 135 && swipeAngle <= 225) {
          return _.options.rtl === false ? 'right' : 'left';
        }

        if (_.options.verticalSwiping === true) {
          if (swipeAngle >= 35 && swipeAngle <= 135) {
            return 'down';
          } else {
            return 'up';
          }
        }

        return 'vertical';
      };

      Slick.prototype.swipeEnd = function (event) {
        var _ = this,
            slideCount,
            direction;

        _.dragging = false;
        _.swiping = false;

        if (_.scrolling) {
          _.scrolling = false;
          return false;
        }

        _.interrupted = false;
        _.shouldClick = _.touchObject.swipeLength > 10 ? false : true;

        if (_.touchObject.curX === undefined) {
          return false;
        }

        if (_.touchObject.edgeHit === true) {
          _.$slider.trigger('edge', [_, _.swipeDirection()]);
        }

        if (_.touchObject.swipeLength >= _.touchObject.minSwipe) {
          direction = _.swipeDirection();

          switch (direction) {
            case 'left':
            case 'down':
              slideCount = _.options.swipeToSlide ? _.checkNavigable(_.currentSlide + _.getSlideCount()) : _.currentSlide + _.getSlideCount();
              _.currentDirection = 0;
              break;

            case 'right':
            case 'up':
              slideCount = _.options.swipeToSlide ? _.checkNavigable(_.currentSlide - _.getSlideCount()) : _.currentSlide - _.getSlideCount();
              _.currentDirection = 1;
              break;

            default:
          }

          if (direction != 'vertical') {
            _.slideHandler(slideCount);

            _.touchObject = {};

            _.$slider.trigger('swipe', [_, direction]);
          }
        } else {
          if (_.touchObject.startX !== _.touchObject.curX) {
            _.slideHandler(_.currentSlide);

            _.touchObject = {};
          }
        }
      };

      Slick.prototype.swipeHandler = function (event) {
        var _ = this;

        if (_.options.swipe === false || 'ontouchend' in document && _.options.swipe === false) {
          return;
        } else if (_.options.draggable === false && event.type.indexOf('mouse') !== -1) {
          return;
        }

        _.touchObject.fingerCount = event.originalEvent && event.originalEvent.touches !== undefined ? event.originalEvent.touches.length : 1;
        _.touchObject.minSwipe = _.listWidth / _.options.touchThreshold;

        if (_.options.verticalSwiping === true) {
          _.touchObject.minSwipe = _.listHeight / _.options.touchThreshold;
        }

        switch (event.data.action) {
          case 'start':
            _.swipeStart(event);

            break;

          case 'move':
            _.swipeMove(event);

            break;

          case 'end':
            _.swipeEnd(event);

            break;
        }
      };

      Slick.prototype.swipeMove = function (event) {
        var _ = this,
            edgeWasHit = false,
            curLeft,
            swipeDirection,
            swipeLength,
            positionOffset,
            touches,
            verticalSwipeLength;

        touches = event.originalEvent !== undefined ? event.originalEvent.touches : null;

        if (!_.dragging || _.scrolling || touches && touches.length !== 1) {
          return false;
        }

        curLeft = _.getLeft(_.currentSlide);
        _.touchObject.curX = touches !== undefined ? touches[0].pageX : event.clientX;
        _.touchObject.curY = touches !== undefined ? touches[0].pageY : event.clientY;
        _.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(_.touchObject.curX - _.touchObject.startX, 2)));
        verticalSwipeLength = Math.round(Math.sqrt(Math.pow(_.touchObject.curY - _.touchObject.startY, 2)));

        if (!_.options.verticalSwiping && !_.swiping && verticalSwipeLength > 4) {
          _.scrolling = true;
          return false;
        }

        if (_.options.verticalSwiping === true) {
          _.touchObject.swipeLength = verticalSwipeLength;
        }

        swipeDirection = _.swipeDirection();

        if (event.originalEvent !== undefined && _.touchObject.swipeLength > 4) {
          _.swiping = true;
          event.preventDefault();
        }

        positionOffset = (_.options.rtl === false ? 1 : -1) * (_.touchObject.curX > _.touchObject.startX ? 1 : -1);

        if (_.options.verticalSwiping === true) {
          positionOffset = _.touchObject.curY > _.touchObject.startY ? 1 : -1;
        }

        swipeLength = _.touchObject.swipeLength;
        _.touchObject.edgeHit = false;

        if (_.options.infinite === false) {
          if (_.currentSlide === 0 && swipeDirection === 'right' || _.currentSlide >= _.getDotCount() && swipeDirection === 'left') {
            swipeLength = _.touchObject.swipeLength * _.options.edgeFriction;
            _.touchObject.edgeHit = true;
          }
        }

        if (_.options.vertical === false) {
          _.swipeLeft = curLeft + swipeLength * positionOffset;
        } else {
          _.swipeLeft = curLeft + swipeLength * (_.$list.height() / _.listWidth) * positionOffset;
        }

        if (_.options.verticalSwiping === true) {
          _.swipeLeft = curLeft + swipeLength * positionOffset;
        }

        if (_.options.fade === true || _.options.touchMove === false) {
          return false;
        }

        if (_.animating === true) {
          _.swipeLeft = null;
          return false;
        }

        _.setCSS(_.swipeLeft);
      };

      Slick.prototype.swipeStart = function (event) {
        var _ = this,
            touches;

        _.interrupted = true;

        if (_.touchObject.fingerCount !== 1 || _.slideCount <= _.options.slidesToShow) {
          _.touchObject = {};
          return false;
        }

        if (event.originalEvent !== undefined && event.originalEvent.touches !== undefined) {
          touches = event.originalEvent.touches[0];
        }

        _.touchObject.startX = _.touchObject.curX = touches !== undefined ? touches.pageX : event.clientX;
        _.touchObject.startY = _.touchObject.curY = touches !== undefined ? touches.pageY : event.clientY;
        _.dragging = true;
      };

      Slick.prototype.unfilterSlides = Slick.prototype.slickUnfilter = function () {
        var _ = this;

        if (_.$slidesCache !== null) {
          _.unload();

          _.$slideTrack.children(this.options.slide).detach();

          _.$slidesCache.appendTo(_.$slideTrack);

          _.reinit();
        }
      };

      Slick.prototype.unload = function () {
        var _ = this;

        $('.slick-cloned', _.$slider).remove();

        if (_.$dots) {
          _.$dots.remove();
        }

        if (_.$prevArrow && _.htmlExpr.test(_.options.prevArrow)) {
          _.$prevArrow.remove();
        }

        if (_.$nextArrow && _.htmlExpr.test(_.options.nextArrow)) {
          _.$nextArrow.remove();
        }

        _.$slides.removeClass('slick-slide slick-active slick-visible slick-current').attr('aria-hidden', 'true').css('width', '');
      };

      Slick.prototype.unslick = function (fromBreakpoint) {
        var _ = this;

        _.$slider.trigger('unslick', [_, fromBreakpoint]);

        _.destroy();
      };

      Slick.prototype.updateArrows = function () {
        var _ = this,
            centerOffset;

        centerOffset = Math.floor(_.options.slidesToShow / 2);

        if (_.options.arrows === true && _.slideCount > _.options.slidesToShow && !_.options.infinite) {
          _.$prevArrow.removeClass('slick-disabled').attr('aria-disabled', 'false');

          _.$nextArrow.removeClass('slick-disabled').attr('aria-disabled', 'false');

          if (_.currentSlide === 0) {
            _.$prevArrow.addClass('slick-disabled').attr('aria-disabled', 'true');

            _.$nextArrow.removeClass('slick-disabled').attr('aria-disabled', 'false');
          } else if (_.currentSlide >= _.slideCount - _.options.slidesToShow && _.options.centerMode === false) {
            _.$nextArrow.addClass('slick-disabled').attr('aria-disabled', 'true');

            _.$prevArrow.removeClass('slick-disabled').attr('aria-disabled', 'false');
          } else if (_.currentSlide >= _.slideCount - 1 && _.options.centerMode === true) {
            _.$nextArrow.addClass('slick-disabled').attr('aria-disabled', 'true');

            _.$prevArrow.removeClass('slick-disabled').attr('aria-disabled', 'false');
          }
        }
      };

      Slick.prototype.updateDots = function () {
        var _ = this;

        if (_.$dots !== null) {
          _.$dots.find('li').removeClass('slick-active').end();

          _.$dots.find('li').eq(Math.floor(_.currentSlide / _.options.slidesToScroll)).addClass('slick-active');
        }
      };

      Slick.prototype.visibility = function () {
        var _ = this;

        if (_.options.autoplay) {
          if (document[_.hidden]) {
            _.interrupted = true;
          } else {
            _.interrupted = false;
          }
        }
      };

      $.fn.slick = function () {
        var _ = this,
            opt = arguments[0],
            args = Array.prototype.slice.call(arguments, 1),
            l = _.length,
            i,
            ret;

        for (i = 0; i < l; i++) {
          if (_typeof(opt) == 'object' || typeof opt == 'undefined') _[i].slick = new Slick(_[i], opt);else ret = _[i].slick[opt].apply(_[i].slick, args);
          if (typeof ret != 'undefined') return ret;
        }

        return _;
      };
    });
  }, {
    "jquery": 2
  }],
  4: [function (require, module, exports) {
    !function (a, b) {
      "function" == typeof define && define.amd ? define(["jquery"], function (a) {
        return b(a);
      }) : "object" == _typeof(exports) ? module.exports = b(require("jquery")) : b(jQuery);
    }(this, function (a) {
      function b(a) {
        this.$container, this.constraints = null, this.__$tooltip, this.__init(a);
      }

      function c(b, c) {
        var d = !0;
        return a.each(b, function (a, e) {
          return void 0 === c[a] || b[a] !== c[a] ? (d = !1, !1) : void 0;
        }), d;
      }

      function d(b) {
        var c = b.attr("id"),
            d = c ? h.window.document.getElementById(c) : null;
        return d ? d === b[0] : a.contains(h.window.document.body, b[0]);
      }

      function e() {
        if (!g) return !1;
        var a = g.document.body || g.document.documentElement,
            b = a.style,
            c = "transition",
            d = ["Moz", "Webkit", "Khtml", "O", "ms"];
        if ("string" == typeof b[c]) return !0;
        c = c.charAt(0).toUpperCase() + c.substr(1);

        for (var e = 0; e < d.length; e++) {
          if ("string" == typeof b[d[e] + c]) return !0;
        }

        return !1;
      }

      var f = {
        animation: "fade",
        animationDuration: 350,
        content: null,
        contentAsHTML: !1,
        contentCloning: !1,
        debug: !0,
        delay: 300,
        delayTouch: [300, 500],
        functionInit: null,
        functionBefore: null,
        functionReady: null,
        functionAfter: null,
        functionFormat: null,
        IEmin: 6,
        interactive: !1,
        multiple: !1,
        parent: null,
        plugins: ["sideTip"],
        repositionOnScroll: !1,
        restoration: "none",
        selfDestruction: !0,
        theme: [],
        timer: 0,
        trackerInterval: 500,
        trackOrigin: !1,
        trackTooltip: !1,
        trigger: "hover",
        triggerClose: {
          click: !1,
          mouseleave: !1,
          originClick: !1,
          scroll: !1,
          tap: !1,
          touchleave: !1
        },
        triggerOpen: {
          click: !1,
          mouseenter: !1,
          tap: !1,
          touchstart: !1
        },
        updateAnimation: "rotate",
        zIndex: 9999999
      },
          g = "undefined" != typeof window ? window : null,
          h = {
        hasTouchCapability: !(!g || !("ontouchstart" in g || g.DocumentTouch && g.document instanceof g.DocumentTouch || g.navigator.maxTouchPoints)),
        hasTransitions: e(),
        IE: !1,
        semVer: "4.2.7",
        window: g
      },
          i = function i() {
        this.__$emitterPrivate = a({}), this.__$emitterPublic = a({}), this.__instancesLatestArr = [], this.__plugins = {}, this._env = h;
      };

      i.prototype = {
        __bridge: function __bridge(b, c, d) {
          if (!c[d]) {
            var e = function e() {};

            e.prototype = b;
            var g = new e();
            g.__init && g.__init(c), a.each(b, function (a, b) {
              0 != a.indexOf("__") && (c[a] ? f.debug && console.log("The " + a + " method of the " + d + " plugin conflicts with another plugin or native methods") : (c[a] = function () {
                return g[a].apply(g, Array.prototype.slice.apply(arguments));
              }, c[a].bridged = g));
            }), c[d] = g;
          }

          return this;
        },
        __setWindow: function __setWindow(a) {
          return h.window = a, this;
        },
        _getRuler: function _getRuler(a) {
          return new b(a);
        },
        _off: function _off() {
          return this.__$emitterPrivate.off.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)), this;
        },
        _on: function _on() {
          return this.__$emitterPrivate.on.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)), this;
        },
        _one: function _one() {
          return this.__$emitterPrivate.one.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)), this;
        },
        _plugin: function _plugin(b) {
          var c = this;

          if ("string" == typeof b) {
            var d = b,
                e = null;
            return d.indexOf(".") > 0 ? e = c.__plugins[d] : a.each(c.__plugins, function (a, b) {
              return b.name.substring(b.name.length - d.length - 1) == "." + d ? (e = b, !1) : void 0;
            }), e;
          }

          if (b.name.indexOf(".") < 0) throw new Error("Plugins must be namespaced");
          return c.__plugins[b.name] = b, b.core && c.__bridge(b.core, c, b.name), this;
        },
        _trigger: function _trigger() {
          var a = Array.prototype.slice.apply(arguments);
          return "string" == typeof a[0] && (a[0] = {
            type: a[0]
          }), this.__$emitterPrivate.trigger.apply(this.__$emitterPrivate, a), this.__$emitterPublic.trigger.apply(this.__$emitterPublic, a), this;
        },
        instances: function instances(b) {
          var c = [],
              d = b || ".tooltipstered";
          return a(d).each(function () {
            var b = a(this),
                d = b.data("tooltipster-ns");
            d && a.each(d, function (a, d) {
              c.push(b.data(d));
            });
          }), c;
        },
        instancesLatest: function instancesLatest() {
          return this.__instancesLatestArr;
        },
        off: function off() {
          return this.__$emitterPublic.off.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        },
        on: function on() {
          return this.__$emitterPublic.on.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        },
        one: function one() {
          return this.__$emitterPublic.one.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        },
        origins: function origins(b) {
          var c = b ? b + " " : "";
          return a(c + ".tooltipstered").toArray();
        },
        setDefaults: function setDefaults(b) {
          return a.extend(f, b), this;
        },
        triggerHandler: function triggerHandler() {
          return this.__$emitterPublic.triggerHandler.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        }
      }, a.tooltipster = new i(), a.Tooltipster = function (b, c) {
        this.__callbacks = {
          close: [],
          open: []
        }, this.__closingTime, this.__Content, this.__contentBcr, this.__destroyed = !1, this.__$emitterPrivate = a({}), this.__$emitterPublic = a({}), this.__enabled = !0, this.__garbageCollector, this.__Geometry, this.__lastPosition, this.__namespace = "tooltipster-" + Math.round(1e6 * Math.random()), this.__options, this.__$originParents, this.__pointerIsOverOrigin = !1, this.__previousThemes = [], this.__state = "closed", this.__timeouts = {
          close: [],
          open: null
        }, this.__touchEvents = [], this.__tracker = null, this._$origin, this._$tooltip, this.__init(b, c);
      }, a.Tooltipster.prototype = {
        __init: function __init(b, c) {
          var d = this;

          if (d._$origin = a(b), d.__options = a.extend(!0, {}, f, c), d.__optionsFormat(), !h.IE || h.IE >= d.__options.IEmin) {
            var e = null;
            if (void 0 === d._$origin.data("tooltipster-initialTitle") && (e = d._$origin.attr("title"), void 0 === e && (e = null), d._$origin.data("tooltipster-initialTitle", e)), null !== d.__options.content) d.__contentSet(d.__options.content);else {
              var g,
                  i = d._$origin.attr("data-tooltip-content");

              i && (g = a(i)), g && g[0] ? d.__contentSet(g.first()) : d.__contentSet(e);
            }
            d._$origin.removeAttr("title").addClass("tooltipstered"), d.__prepareOrigin(), d.__prepareGC(), a.each(d.__options.plugins, function (a, b) {
              d._plug(b);
            }), h.hasTouchCapability && a(h.window.document.body).on("touchmove." + d.__namespace + "-triggerOpen", function (a) {
              d._touchRecordEvent(a);
            }), d._on("created", function () {
              d.__prepareTooltip();
            })._on("repositioned", function (a) {
              d.__lastPosition = a.position;
            });
          } else d.__options.disabled = !0;
        },
        __contentInsert: function __contentInsert() {
          var a = this,
              b = a._$tooltip.find(".tooltipster-content"),
              c = a.__Content,
              d = function d(a) {
            c = a;
          };

          return a._trigger({
            type: "format",
            content: a.__Content,
            format: d
          }), a.__options.functionFormat && (c = a.__options.functionFormat.call(a, a, {
            origin: a._$origin[0]
          }, a.__Content)), "string" != typeof c || a.__options.contentAsHTML ? b.empty().append(c) : b.text(c), a;
        },
        __contentSet: function __contentSet(b) {
          return b instanceof a && this.__options.contentCloning && (b = b.clone(!0)), this.__Content = b, this._trigger({
            type: "updated",
            content: b
          }), this;
        },
        __destroyError: function __destroyError() {
          throw new Error("This tooltip has been destroyed and cannot execute your method call.");
        },
        __geometry: function __geometry() {
          var b = this,
              c = b._$origin,
              d = b._$origin.is("area");

          if (d) {
            var e = b._$origin.parent().attr("name");

            c = a('img[usemap="#' + e + '"]');
          }

          var f = c[0].getBoundingClientRect(),
              g = a(h.window.document),
              i = a(h.window),
              j = c,
              k = {
            available: {
              document: null,
              window: null
            },
            document: {
              size: {
                height: g.height(),
                width: g.width()
              }
            },
            window: {
              scroll: {
                left: h.window.scrollX || h.window.document.documentElement.scrollLeft,
                top: h.window.scrollY || h.window.document.documentElement.scrollTop
              },
              size: {
                height: i.height(),
                width: i.width()
              }
            },
            origin: {
              fixedLineage: !1,
              offset: {},
              size: {
                height: f.bottom - f.top,
                width: f.right - f.left
              },
              usemapImage: d ? c[0] : null,
              windowOffset: {
                bottom: f.bottom,
                left: f.left,
                right: f.right,
                top: f.top
              }
            }
          };

          if (d) {
            var l = b._$origin.attr("shape"),
                m = b._$origin.attr("coords");

            if (m && (m = m.split(","), a.map(m, function (a, b) {
              m[b] = parseInt(a);
            })), "default" != l) switch (l) {
              case "circle":
                var n = m[0],
                    o = m[1],
                    p = m[2],
                    q = o - p,
                    r = n - p;
                k.origin.size.height = 2 * p, k.origin.size.width = k.origin.size.height, k.origin.windowOffset.left += r, k.origin.windowOffset.top += q;
                break;

              case "rect":
                var s = m[0],
                    t = m[1],
                    u = m[2],
                    v = m[3];
                k.origin.size.height = v - t, k.origin.size.width = u - s, k.origin.windowOffset.left += s, k.origin.windowOffset.top += t;
                break;

              case "poly":
                for (var w = 0, x = 0, y = 0, z = 0, A = "even", B = 0; B < m.length; B++) {
                  var C = m[B];
                  "even" == A ? (C > y && (y = C, 0 === B && (w = y)), w > C && (w = C), A = "odd") : (C > z && (z = C, 1 == B && (x = z)), x > C && (x = C), A = "even");
                }

                k.origin.size.height = z - x, k.origin.size.width = y - w, k.origin.windowOffset.left += w, k.origin.windowOffset.top += x;
            }
          }

          var D = function D(a) {
            k.origin.size.height = a.height, k.origin.windowOffset.left = a.left, k.origin.windowOffset.top = a.top, k.origin.size.width = a.width;
          };

          for (b._trigger({
            type: "geometry",
            edit: D,
            geometry: {
              height: k.origin.size.height,
              left: k.origin.windowOffset.left,
              top: k.origin.windowOffset.top,
              width: k.origin.size.width
            }
          }), k.origin.windowOffset.right = k.origin.windowOffset.left + k.origin.size.width, k.origin.windowOffset.bottom = k.origin.windowOffset.top + k.origin.size.height, k.origin.offset.left = k.origin.windowOffset.left + k.window.scroll.left, k.origin.offset.top = k.origin.windowOffset.top + k.window.scroll.top, k.origin.offset.bottom = k.origin.offset.top + k.origin.size.height, k.origin.offset.right = k.origin.offset.left + k.origin.size.width, k.available.document = {
            bottom: {
              height: k.document.size.height - k.origin.offset.bottom,
              width: k.document.size.width
            },
            left: {
              height: k.document.size.height,
              width: k.origin.offset.left
            },
            right: {
              height: k.document.size.height,
              width: k.document.size.width - k.origin.offset.right
            },
            top: {
              height: k.origin.offset.top,
              width: k.document.size.width
            }
          }, k.available.window = {
            bottom: {
              height: Math.max(k.window.size.height - Math.max(k.origin.windowOffset.bottom, 0), 0),
              width: k.window.size.width
            },
            left: {
              height: k.window.size.height,
              width: Math.max(k.origin.windowOffset.left, 0)
            },
            right: {
              height: k.window.size.height,
              width: Math.max(k.window.size.width - Math.max(k.origin.windowOffset.right, 0), 0)
            },
            top: {
              height: Math.max(k.origin.windowOffset.top, 0),
              width: k.window.size.width
            }
          }; "html" != j[0].tagName.toLowerCase();) {
            if ("fixed" == j.css("position")) {
              k.origin.fixedLineage = !0;
              break;
            }

            j = j.parent();
          }

          return k;
        },
        __optionsFormat: function __optionsFormat() {
          return "number" == typeof this.__options.animationDuration && (this.__options.animationDuration = [this.__options.animationDuration, this.__options.animationDuration]), "number" == typeof this.__options.delay && (this.__options.delay = [this.__options.delay, this.__options.delay]), "number" == typeof this.__options.delayTouch && (this.__options.delayTouch = [this.__options.delayTouch, this.__options.delayTouch]), "string" == typeof this.__options.theme && (this.__options.theme = [this.__options.theme]), null === this.__options.parent ? this.__options.parent = a(h.window.document.body) : "string" == typeof this.__options.parent && (this.__options.parent = a(this.__options.parent)), "hover" == this.__options.trigger ? (this.__options.triggerOpen = {
            mouseenter: !0,
            touchstart: !0
          }, this.__options.triggerClose = {
            mouseleave: !0,
            originClick: !0,
            touchleave: !0
          }) : "click" == this.__options.trigger && (this.__options.triggerOpen = {
            click: !0,
            tap: !0
          }, this.__options.triggerClose = {
            click: !0,
            tap: !0
          }), this._trigger("options"), this;
        },
        __prepareGC: function __prepareGC() {
          var b = this;
          return b.__options.selfDestruction ? b.__garbageCollector = setInterval(function () {
            var c = new Date().getTime();
            b.__touchEvents = a.grep(b.__touchEvents, function (a, b) {
              return c - a.time > 6e4;
            }), d(b._$origin) || b.close(function () {
              b.destroy();
            });
          }, 2e4) : clearInterval(b.__garbageCollector), b;
        },
        __prepareOrigin: function __prepareOrigin() {
          var a = this;

          if (a._$origin.off("." + a.__namespace + "-triggerOpen"), h.hasTouchCapability && a._$origin.on("touchstart." + a.__namespace + "-triggerOpen touchend." + a.__namespace + "-triggerOpen touchcancel." + a.__namespace + "-triggerOpen", function (b) {
            a._touchRecordEvent(b);
          }), a.__options.triggerOpen.click || a.__options.triggerOpen.tap && h.hasTouchCapability) {
            var b = "";
            a.__options.triggerOpen.click && (b += "click." + a.__namespace + "-triggerOpen "), a.__options.triggerOpen.tap && h.hasTouchCapability && (b += "touchend." + a.__namespace + "-triggerOpen"), a._$origin.on(b, function (b) {
              a._touchIsMeaningfulEvent(b) && a._open(b);
            });
          }

          if (a.__options.triggerOpen.mouseenter || a.__options.triggerOpen.touchstart && h.hasTouchCapability) {
            var b = "";
            a.__options.triggerOpen.mouseenter && (b += "mouseenter." + a.__namespace + "-triggerOpen "), a.__options.triggerOpen.touchstart && h.hasTouchCapability && (b += "touchstart." + a.__namespace + "-triggerOpen"), a._$origin.on(b, function (b) {
              !a._touchIsTouchEvent(b) && a._touchIsEmulatedEvent(b) || (a.__pointerIsOverOrigin = !0, a._openShortly(b));
            });
          }

          if (a.__options.triggerClose.mouseleave || a.__options.triggerClose.touchleave && h.hasTouchCapability) {
            var b = "";
            a.__options.triggerClose.mouseleave && (b += "mouseleave." + a.__namespace + "-triggerOpen "), a.__options.triggerClose.touchleave && h.hasTouchCapability && (b += "touchend." + a.__namespace + "-triggerOpen touchcancel." + a.__namespace + "-triggerOpen"), a._$origin.on(b, function (b) {
              a._touchIsMeaningfulEvent(b) && (a.__pointerIsOverOrigin = !1);
            });
          }

          return a;
        },
        __prepareTooltip: function __prepareTooltip() {
          var b = this,
              c = b.__options.interactive ? "auto" : "";
          return b._$tooltip.attr("id", b.__namespace).css({
            "pointer-events": c,
            zIndex: b.__options.zIndex
          }), a.each(b.__previousThemes, function (a, c) {
            b._$tooltip.removeClass(c);
          }), a.each(b.__options.theme, function (a, c) {
            b._$tooltip.addClass(c);
          }), b.__previousThemes = a.merge([], b.__options.theme), b;
        },
        __scrollHandler: function __scrollHandler(b) {
          var c = this;
          if (c.__options.triggerClose.scroll) c._close(b);else if (d(c._$origin) && d(c._$tooltip)) {
            var e = null;
            if (b.target === h.window.document) c.__Geometry.origin.fixedLineage || c.__options.repositionOnScroll && c.reposition(b);else {
              e = c.__geometry();
              var f = !1;
              if ("fixed" != c._$origin.css("position") && c.__$originParents.each(function (b, c) {
                var d = a(c),
                    g = d.css("overflow-x"),
                    h = d.css("overflow-y");

                if ("visible" != g || "visible" != h) {
                  var i = c.getBoundingClientRect();
                  if ("visible" != g && (e.origin.windowOffset.left < i.left || e.origin.windowOffset.right > i.right)) return f = !0, !1;
                  if ("visible" != h && (e.origin.windowOffset.top < i.top || e.origin.windowOffset.bottom > i.bottom)) return f = !0, !1;
                }

                return "fixed" == d.css("position") ? !1 : void 0;
              }), f) c._$tooltip.css("visibility", "hidden");else if (c._$tooltip.css("visibility", "visible"), c.__options.repositionOnScroll) c.reposition(b);else {
                var g = e.origin.offset.left - c.__Geometry.origin.offset.left,
                    i = e.origin.offset.top - c.__Geometry.origin.offset.top;

                c._$tooltip.css({
                  left: c.__lastPosition.coord.left + g,
                  top: c.__lastPosition.coord.top + i
                });
              }
            }

            c._trigger({
              type: "scroll",
              event: b,
              geo: e
            });
          }
          return c;
        },
        __stateSet: function __stateSet(a) {
          return this.__state = a, this._trigger({
            type: "state",
            state: a
          }), this;
        },
        __timeoutsClear: function __timeoutsClear() {
          return clearTimeout(this.__timeouts.open), this.__timeouts.open = null, a.each(this.__timeouts.close, function (a, b) {
            clearTimeout(b);
          }), this.__timeouts.close = [], this;
        },
        __trackerStart: function __trackerStart() {
          var a = this,
              b = a._$tooltip.find(".tooltipster-content");

          return a.__options.trackTooltip && (a.__contentBcr = b[0].getBoundingClientRect()), a.__tracker = setInterval(function () {
            if (d(a._$origin) && d(a._$tooltip)) {
              if (a.__options.trackOrigin) {
                var e = a.__geometry(),
                    f = !1;

                c(e.origin.size, a.__Geometry.origin.size) && (a.__Geometry.origin.fixedLineage ? c(e.origin.windowOffset, a.__Geometry.origin.windowOffset) && (f = !0) : c(e.origin.offset, a.__Geometry.origin.offset) && (f = !0)), f || (a.__options.triggerClose.mouseleave ? a._close() : a.reposition());
              }

              if (a.__options.trackTooltip) {
                var g = b[0].getBoundingClientRect();
                g.height === a.__contentBcr.height && g.width === a.__contentBcr.width || (a.reposition(), a.__contentBcr = g);
              }
            } else a._close();
          }, a.__options.trackerInterval), a;
        },
        _close: function _close(b, c, d) {
          var e = this,
              f = !0;

          if (e._trigger({
            type: "close",
            event: b,
            stop: function stop() {
              f = !1;
            }
          }), f || d) {
            c && e.__callbacks.close.push(c), e.__callbacks.open = [], e.__timeoutsClear();

            var g = function g() {
              a.each(e.__callbacks.close, function (a, c) {
                c.call(e, e, {
                  event: b,
                  origin: e._$origin[0]
                });
              }), e.__callbacks.close = [];
            };

            if ("closed" != e.__state) {
              var i = !0,
                  j = new Date(),
                  k = j.getTime(),
                  l = k + e.__options.animationDuration[1];

              if ("disappearing" == e.__state && l > e.__closingTime && e.__options.animationDuration[1] > 0 && (i = !1), i) {
                e.__closingTime = l, "disappearing" != e.__state && e.__stateSet("disappearing");

                var m = function m() {
                  clearInterval(e.__tracker), e._trigger({
                    type: "closing",
                    event: b
                  }), e._$tooltip.off("." + e.__namespace + "-triggerClose").removeClass("tooltipster-dying"), a(h.window).off("." + e.__namespace + "-triggerClose"), e.__$originParents.each(function (b, c) {
                    a(c).off("scroll." + e.__namespace + "-triggerClose");
                  }), e.__$originParents = null, a(h.window.document.body).off("." + e.__namespace + "-triggerClose"), e._$origin.off("." + e.__namespace + "-triggerClose"), e._off("dismissable"), e.__stateSet("closed"), e._trigger({
                    type: "after",
                    event: b
                  }), e.__options.functionAfter && e.__options.functionAfter.call(e, e, {
                    event: b,
                    origin: e._$origin[0]
                  }), g();
                };

                h.hasTransitions ? (e._$tooltip.css({
                  "-moz-animation-duration": e.__options.animationDuration[1] + "ms",
                  "-ms-animation-duration": e.__options.animationDuration[1] + "ms",
                  "-o-animation-duration": e.__options.animationDuration[1] + "ms",
                  "-webkit-animation-duration": e.__options.animationDuration[1] + "ms",
                  "animation-duration": e.__options.animationDuration[1] + "ms",
                  "transition-duration": e.__options.animationDuration[1] + "ms"
                }), e._$tooltip.clearQueue().removeClass("tooltipster-show").addClass("tooltipster-dying"), e.__options.animationDuration[1] > 0 && e._$tooltip.delay(e.__options.animationDuration[1]), e._$tooltip.queue(m)) : e._$tooltip.stop().fadeOut(e.__options.animationDuration[1], m);
              }
            } else g();
          }

          return e;
        },
        _off: function _off() {
          return this.__$emitterPrivate.off.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)), this;
        },
        _on: function _on() {
          return this.__$emitterPrivate.on.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)), this;
        },
        _one: function _one() {
          return this.__$emitterPrivate.one.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)), this;
        },
        _open: function _open(b, c) {
          var e = this;

          if (!e.__destroying && d(e._$origin) && e.__enabled) {
            var f = !0;

            if ("closed" == e.__state && (e._trigger({
              type: "before",
              event: b,
              stop: function stop() {
                f = !1;
              }
            }), f && e.__options.functionBefore && (f = e.__options.functionBefore.call(e, e, {
              event: b,
              origin: e._$origin[0]
            }))), f !== !1 && null !== e.__Content) {
              c && e.__callbacks.open.push(c), e.__callbacks.close = [], e.__timeoutsClear();

              var g,
                  i = function i() {
                "stable" != e.__state && e.__stateSet("stable"), a.each(e.__callbacks.open, function (a, b) {
                  b.call(e, e, {
                    origin: e._$origin[0],
                    tooltip: e._$tooltip[0]
                  });
                }), e.__callbacks.open = [];
              };

              if ("closed" !== e.__state) g = 0, "disappearing" === e.__state ? (e.__stateSet("appearing"), h.hasTransitions ? (e._$tooltip.clearQueue().removeClass("tooltipster-dying").addClass("tooltipster-show"), e.__options.animationDuration[0] > 0 && e._$tooltip.delay(e.__options.animationDuration[0]), e._$tooltip.queue(i)) : e._$tooltip.stop().fadeIn(i)) : "stable" == e.__state && i();else {
                if (e.__stateSet("appearing"), g = e.__options.animationDuration[0], e.__contentInsert(), e.reposition(b, !0), h.hasTransitions ? (e._$tooltip.addClass("tooltipster-" + e.__options.animation).addClass("tooltipster-initial").css({
                  "-moz-animation-duration": e.__options.animationDuration[0] + "ms",
                  "-ms-animation-duration": e.__options.animationDuration[0] + "ms",
                  "-o-animation-duration": e.__options.animationDuration[0] + "ms",
                  "-webkit-animation-duration": e.__options.animationDuration[0] + "ms",
                  "animation-duration": e.__options.animationDuration[0] + "ms",
                  "transition-duration": e.__options.animationDuration[0] + "ms"
                }), setTimeout(function () {
                  "closed" != e.__state && (e._$tooltip.addClass("tooltipster-show").removeClass("tooltipster-initial"), e.__options.animationDuration[0] > 0 && e._$tooltip.delay(e.__options.animationDuration[0]), e._$tooltip.queue(i));
                }, 0)) : e._$tooltip.css("display", "none").fadeIn(e.__options.animationDuration[0], i), e.__trackerStart(), a(h.window).on("resize." + e.__namespace + "-triggerClose", function (b) {
                  var c = a(document.activeElement);
                  (c.is("input") || c.is("textarea")) && a.contains(e._$tooltip[0], c[0]) || e.reposition(b);
                }).on("scroll." + e.__namespace + "-triggerClose", function (a) {
                  e.__scrollHandler(a);
                }), e.__$originParents = e._$origin.parents(), e.__$originParents.each(function (b, c) {
                  a(c).on("scroll." + e.__namespace + "-triggerClose", function (a) {
                    e.__scrollHandler(a);
                  });
                }), e.__options.triggerClose.mouseleave || e.__options.triggerClose.touchleave && h.hasTouchCapability) {
                  e._on("dismissable", function (a) {
                    a.dismissable ? a.delay ? (m = setTimeout(function () {
                      e._close(a.event);
                    }, a.delay), e.__timeouts.close.push(m)) : e._close(a) : clearTimeout(m);
                  });

                  var j = e._$origin,
                      k = "",
                      l = "",
                      m = null;
                  e.__options.interactive && (j = j.add(e._$tooltip)), e.__options.triggerClose.mouseleave && (k += "mouseenter." + e.__namespace + "-triggerClose ", l += "mouseleave." + e.__namespace + "-triggerClose "), e.__options.triggerClose.touchleave && h.hasTouchCapability && (k += "touchstart." + e.__namespace + "-triggerClose", l += "touchend." + e.__namespace + "-triggerClose touchcancel." + e.__namespace + "-triggerClose"), j.on(l, function (a) {
                    if (e._touchIsTouchEvent(a) || !e._touchIsEmulatedEvent(a)) {
                      var b = "mouseleave" == a.type ? e.__options.delay : e.__options.delayTouch;

                      e._trigger({
                        delay: b[1],
                        dismissable: !0,
                        event: a,
                        type: "dismissable"
                      });
                    }
                  }).on(k, function (a) {
                    !e._touchIsTouchEvent(a) && e._touchIsEmulatedEvent(a) || e._trigger({
                      dismissable: !1,
                      event: a,
                      type: "dismissable"
                    });
                  });
                }

                e.__options.triggerClose.originClick && e._$origin.on("click." + e.__namespace + "-triggerClose", function (a) {
                  e._touchIsTouchEvent(a) || e._touchIsEmulatedEvent(a) || e._close(a);
                }), (e.__options.triggerClose.click || e.__options.triggerClose.tap && h.hasTouchCapability) && setTimeout(function () {
                  if ("closed" != e.__state) {
                    var b = "",
                        c = a(h.window.document.body);
                    e.__options.triggerClose.click && (b += "click." + e.__namespace + "-triggerClose "), e.__options.triggerClose.tap && h.hasTouchCapability && (b += "touchend." + e.__namespace + "-triggerClose"), c.on(b, function (b) {
                      e._touchIsMeaningfulEvent(b) && (e._touchRecordEvent(b), e.__options.interactive && a.contains(e._$tooltip[0], b.target) || e._close(b));
                    }), e.__options.triggerClose.tap && h.hasTouchCapability && c.on("touchstart." + e.__namespace + "-triggerClose", function (a) {
                      e._touchRecordEvent(a);
                    });
                  }
                }, 0), e._trigger("ready"), e.__options.functionReady && e.__options.functionReady.call(e, e, {
                  origin: e._$origin[0],
                  tooltip: e._$tooltip[0]
                });
              }

              if (e.__options.timer > 0) {
                var m = setTimeout(function () {
                  e._close();
                }, e.__options.timer + g);

                e.__timeouts.close.push(m);
              }
            }
          }

          return e;
        },
        _openShortly: function _openShortly(a) {
          var b = this,
              c = !0;

          if ("stable" != b.__state && "appearing" != b.__state && !b.__timeouts.open && (b._trigger({
            type: "start",
            event: a,
            stop: function stop() {
              c = !1;
            }
          }), c)) {
            var d = 0 == a.type.indexOf("touch") ? b.__options.delayTouch : b.__options.delay;
            d[0] ? b.__timeouts.open = setTimeout(function () {
              b.__timeouts.open = null, b.__pointerIsOverOrigin && b._touchIsMeaningfulEvent(a) ? (b._trigger("startend"), b._open(a)) : b._trigger("startcancel");
            }, d[0]) : (b._trigger("startend"), b._open(a));
          }

          return b;
        },
        _optionsExtract: function _optionsExtract(b, c) {
          var d = this,
              e = a.extend(!0, {}, c),
              f = d.__options[b];
          return f || (f = {}, a.each(c, function (a, b) {
            var c = d.__options[a];
            void 0 !== c && (f[a] = c);
          })), a.each(e, function (b, c) {
            void 0 !== f[b] && ("object" != _typeof(c) || c instanceof Array || null == c || "object" != _typeof(f[b]) || f[b] instanceof Array || null == f[b] ? e[b] = f[b] : a.extend(e[b], f[b]));
          }), e;
        },
        _plug: function _plug(b) {
          var c = a.tooltipster._plugin(b);

          if (!c) throw new Error('The "' + b + '" plugin is not defined');
          return c.instance && a.tooltipster.__bridge(c.instance, this, c.name), this;
        },
        _touchIsEmulatedEvent: function _touchIsEmulatedEvent(a) {
          for (var b = !1, c = new Date().getTime(), d = this.__touchEvents.length - 1; d >= 0; d--) {
            var e = this.__touchEvents[d];
            if (!(c - e.time < 500)) break;
            e.target === a.target && (b = !0);
          }

          return b;
        },
        _touchIsMeaningfulEvent: function _touchIsMeaningfulEvent(a) {
          return this._touchIsTouchEvent(a) && !this._touchSwiped(a.target) || !this._touchIsTouchEvent(a) && !this._touchIsEmulatedEvent(a);
        },
        _touchIsTouchEvent: function _touchIsTouchEvent(a) {
          return 0 == a.type.indexOf("touch");
        },
        _touchRecordEvent: function _touchRecordEvent(a) {
          return this._touchIsTouchEvent(a) && (a.time = new Date().getTime(), this.__touchEvents.push(a)), this;
        },
        _touchSwiped: function _touchSwiped(a) {
          for (var b = !1, c = this.__touchEvents.length - 1; c >= 0; c--) {
            var d = this.__touchEvents[c];

            if ("touchmove" == d.type) {
              b = !0;
              break;
            }

            if ("touchstart" == d.type && a === d.target) break;
          }

          return b;
        },
        _trigger: function _trigger() {
          var b = Array.prototype.slice.apply(arguments);
          return "string" == typeof b[0] && (b[0] = {
            type: b[0]
          }), b[0].instance = this, b[0].origin = this._$origin ? this._$origin[0] : null, b[0].tooltip = this._$tooltip ? this._$tooltip[0] : null, this.__$emitterPrivate.trigger.apply(this.__$emitterPrivate, b), a.tooltipster._trigger.apply(a.tooltipster, b), this.__$emitterPublic.trigger.apply(this.__$emitterPublic, b), this;
        },
        _unplug: function _unplug(b) {
          var c = this;

          if (c[b]) {
            var d = a.tooltipster._plugin(b);

            d.instance && a.each(d.instance, function (a, d) {
              c[a] && c[a].bridged === c[b] && delete c[a];
            }), c[b].__destroy && c[b].__destroy(), delete c[b];
          }

          return c;
        },
        close: function close(a) {
          return this.__destroyed ? this.__destroyError() : this._close(null, a), this;
        },
        content: function content(a) {
          var b = this;
          if (void 0 === a) return b.__Content;
          if (b.__destroyed) b.__destroyError();else if (b.__contentSet(a), null !== b.__Content) {
            if ("closed" !== b.__state && (b.__contentInsert(), b.reposition(), b.__options.updateAnimation)) if (h.hasTransitions) {
              var c = b.__options.updateAnimation;
              b._$tooltip.addClass("tooltipster-update-" + c), setTimeout(function () {
                "closed" != b.__state && b._$tooltip.removeClass("tooltipster-update-" + c);
              }, 1e3);
            } else b._$tooltip.fadeTo(200, .5, function () {
              "closed" != b.__state && b._$tooltip.fadeTo(200, 1);
            });
          } else b._close();
          return b;
        },
        destroy: function destroy() {
          var b = this;
          if (b.__destroyed) b.__destroyError();else {
            "closed" != b.__state ? b.option("animationDuration", 0)._close(null, null, !0) : b.__timeoutsClear(), b._trigger("destroy"), b.__destroyed = !0, b._$origin.removeData(b.__namespace).off("." + b.__namespace + "-triggerOpen"), a(h.window.document.body).off("." + b.__namespace + "-triggerOpen");

            var c = b._$origin.data("tooltipster-ns");

            if (c) if (1 === c.length) {
              var d = null;
              "previous" == b.__options.restoration ? d = b._$origin.data("tooltipster-initialTitle") : "current" == b.__options.restoration && (d = "string" == typeof b.__Content ? b.__Content : a("<div></div>").append(b.__Content).html()), d && b._$origin.attr("title", d), b._$origin.removeClass("tooltipstered"), b._$origin.removeData("tooltipster-ns").removeData("tooltipster-initialTitle");
            } else c = a.grep(c, function (a, c) {
              return a !== b.__namespace;
            }), b._$origin.data("tooltipster-ns", c);
            b._trigger("destroyed"), b._off(), b.off(), b.__Content = null, b.__$emitterPrivate = null, b.__$emitterPublic = null, b.__options.parent = null, b._$origin = null, b._$tooltip = null, a.tooltipster.__instancesLatestArr = a.grep(a.tooltipster.__instancesLatestArr, function (a, c) {
              return b !== a;
            }), clearInterval(b.__garbageCollector);
          }
          return b;
        },
        disable: function disable() {
          return this.__destroyed ? (this.__destroyError(), this) : (this._close(), this.__enabled = !1, this);
        },
        elementOrigin: function elementOrigin() {
          return this.__destroyed ? void this.__destroyError() : this._$origin[0];
        },
        elementTooltip: function elementTooltip() {
          return this._$tooltip ? this._$tooltip[0] : null;
        },
        enable: function enable() {
          return this.__enabled = !0, this;
        },
        hide: function hide(a) {
          return this.close(a);
        },
        instance: function instance() {
          return this;
        },
        off: function off() {
          return this.__destroyed || this.__$emitterPublic.off.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        },
        on: function on() {
          return this.__destroyed ? this.__destroyError() : this.__$emitterPublic.on.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        },
        one: function one() {
          return this.__destroyed ? this.__destroyError() : this.__$emitterPublic.one.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        },
        open: function open(a) {
          return this.__destroyed ? this.__destroyError() : this._open(null, a), this;
        },
        option: function option(b, c) {
          return void 0 === c ? this.__options[b] : (this.__destroyed ? this.__destroyError() : (this.__options[b] = c, this.__optionsFormat(), a.inArray(b, ["trigger", "triggerClose", "triggerOpen"]) >= 0 && this.__prepareOrigin(), "selfDestruction" === b && this.__prepareGC()), this);
        },
        reposition: function reposition(a, b) {
          var c = this;
          return c.__destroyed ? c.__destroyError() : "closed" != c.__state && d(c._$origin) && (b || d(c._$tooltip)) && (b || c._$tooltip.detach(), c.__Geometry = c.__geometry(), c._trigger({
            type: "reposition",
            event: a,
            helper: {
              geo: c.__Geometry
            }
          })), c;
        },
        show: function show(a) {
          return this.open(a);
        },
        status: function status() {
          return {
            destroyed: this.__destroyed,
            enabled: this.__enabled,
            open: "closed" !== this.__state,
            state: this.__state
          };
        },
        triggerHandler: function triggerHandler() {
          return this.__destroyed ? this.__destroyError() : this.__$emitterPublic.triggerHandler.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)), this;
        }
      }, a.fn.tooltipster = function () {
        var b = Array.prototype.slice.apply(arguments),
            c = "You are using a single HTML element as content for several tooltips. You probably want to set the contentCloning option to TRUE.";
        if (0 === this.length) return this;

        if ("string" == typeof b[0]) {
          var d = "#*$~&";
          return this.each(function () {
            var e = a(this).data("tooltipster-ns"),
                f = e ? a(this).data(e[0]) : null;
            if (!f) throw new Error("You called Tooltipster's \"" + b[0] + '" method on an uninitialized element');
            if ("function" != typeof f[b[0]]) throw new Error('Unknown method "' + b[0] + '"');
            this.length > 1 && "content" == b[0] && (b[1] instanceof a || "object" == _typeof(b[1]) && null != b[1] && b[1].tagName) && !f.__options.contentCloning && f.__options.debug && console.log(c);
            var g = f[b[0]](b[1], b[2]);
            return g !== f || "instance" === b[0] ? (d = g, !1) : void 0;
          }), "#*$~&" !== d ? d : this;
        }

        a.tooltipster.__instancesLatestArr = [];
        var e = b[0] && void 0 !== b[0].multiple,
            g = e && b[0].multiple || !e && f.multiple,
            h = b[0] && void 0 !== b[0].content,
            i = h && b[0].content || !h && f.content,
            j = b[0] && void 0 !== b[0].contentCloning,
            k = j && b[0].contentCloning || !j && f.contentCloning,
            l = b[0] && void 0 !== b[0].debug,
            m = l && b[0].debug || !l && f.debug;
        return this.length > 1 && (i instanceof a || "object" == _typeof(i) && null != i && i.tagName) && !k && m && console.log(c), this.each(function () {
          var c = !1,
              d = a(this),
              e = d.data("tooltipster-ns"),
              f = null;
          e ? g ? c = !0 : m && (console.log("Tooltipster: one or more tooltips are already attached to the element below. Ignoring."), console.log(this)) : c = !0, c && (f = new a.Tooltipster(this, b[0]), e || (e = []), e.push(f.__namespace), d.data("tooltipster-ns", e), d.data(f.__namespace, f), f.__options.functionInit && f.__options.functionInit.call(f, f, {
            origin: this
          }), f._trigger("init")), a.tooltipster.__instancesLatestArr.push(f);
        }), this;
      }, b.prototype = {
        __init: function __init(b) {
          this.__$tooltip = b, this.__$tooltip.css({
            left: 0,
            overflow: "hidden",
            position: "absolute",
            top: 0
          }).find(".tooltipster-content").css("overflow", "auto"), this.$container = a('<div class="tooltipster-ruler"></div>').append(this.__$tooltip).appendTo(h.window.document.body);
        },
        __forceRedraw: function __forceRedraw() {
          var a = this.__$tooltip.parent();

          this.__$tooltip.detach(), this.__$tooltip.appendTo(a);
        },
        constrain: function constrain(a, b) {
          return this.constraints = {
            width: a,
            height: b
          }, this.__$tooltip.css({
            display: "block",
            height: "",
            overflow: "auto",
            width: a
          }), this;
        },
        destroy: function destroy() {
          this.__$tooltip.detach().find(".tooltipster-content").css({
            display: "",
            overflow: ""
          }), this.$container.remove();
        },
        free: function free() {
          return this.constraints = null, this.__$tooltip.css({
            display: "",
            height: "",
            overflow: "visible",
            width: ""
          }), this;
        },
        measure: function measure() {
          this.__forceRedraw();

          var a = this.__$tooltip[0].getBoundingClientRect(),
              b = {
            size: {
              height: a.height || a.bottom - a.top,
              width: a.width || a.right - a.left
            }
          };

          if (this.constraints) {
            var c = this.__$tooltip.find(".tooltipster-content"),
                d = this.__$tooltip.outerHeight(),
                e = c[0].getBoundingClientRect(),
                f = {
              height: d <= this.constraints.height,
              width: a.width <= this.constraints.width && e.width >= c[0].scrollWidth - 1
            };

            b.fits = f.height && f.width;
          }

          return h.IE && h.IE <= 11 && b.size.width !== h.window.document.documentElement.clientWidth && (b.size.width = Math.ceil(b.size.width) + 1), b;
        }
      };
      var j = navigator.userAgent.toLowerCase();
      -1 != j.indexOf("msie") ? h.IE = parseInt(j.split("msie")[1]) : -1 !== j.toLowerCase().indexOf("trident") && -1 !== j.indexOf(" rv:11") ? h.IE = 11 : -1 != j.toLowerCase().indexOf("edge/") && (h.IE = parseInt(j.toLowerCase().split("edge/")[1]));
      var k = "tooltipster.sideTip";
      return a.tooltipster._plugin({
        name: k,
        instance: {
          __defaults: function __defaults() {
            return {
              arrow: !0,
              distance: 6,
              functionPosition: null,
              maxWidth: null,
              minIntersection: 16,
              minWidth: 0,
              position: null,
              side: "top",
              viewportAware: !0
            };
          },
          __init: function __init(a) {
            var b = this;
            b.__instance = a, b.__namespace = "tooltipster-sideTip-" + Math.round(1e6 * Math.random()), b.__previousState = "closed", b.__options, b.__optionsFormat(), b.__instance._on("state." + b.__namespace, function (a) {
              "closed" == a.state ? b.__close() : "appearing" == a.state && "closed" == b.__previousState && b.__create(), b.__previousState = a.state;
            }), b.__instance._on("options." + b.__namespace, function () {
              b.__optionsFormat();
            }), b.__instance._on("reposition." + b.__namespace, function (a) {
              b.__reposition(a.event, a.helper);
            });
          },
          __close: function __close() {
            this.__instance.content() instanceof a && this.__instance.content().detach(), this.__instance._$tooltip.remove(), this.__instance._$tooltip = null;
          },
          __create: function __create() {
            var b = a('<div class="tooltipster-base tooltipster-sidetip"><div class="tooltipster-box"><div class="tooltipster-content"></div></div><div class="tooltipster-arrow"><div class="tooltipster-arrow-uncropped"><div class="tooltipster-arrow-border"></div><div class="tooltipster-arrow-background"></div></div></div></div>');
            this.__options.arrow || b.find(".tooltipster-box").css("margin", 0).end().find(".tooltipster-arrow").hide(), this.__options.minWidth && b.css("min-width", this.__options.minWidth + "px"), this.__options.maxWidth && b.css("max-width", this.__options.maxWidth + "px"), this.__instance._$tooltip = b, this.__instance._trigger("created");
          },
          __destroy: function __destroy() {
            this.__instance._off("." + self.__namespace);
          },
          __optionsFormat: function __optionsFormat() {
            var b = this;

            if (b.__options = b.__instance._optionsExtract(k, b.__defaults()), b.__options.position && (b.__options.side = b.__options.position), "object" != _typeof(b.__options.distance) && (b.__options.distance = [b.__options.distance]), b.__options.distance.length < 4 && (void 0 === b.__options.distance[1] && (b.__options.distance[1] = b.__options.distance[0]), void 0 === b.__options.distance[2] && (b.__options.distance[2] = b.__options.distance[0]), void 0 === b.__options.distance[3] && (b.__options.distance[3] = b.__options.distance[1]), b.__options.distance = {
              top: b.__options.distance[0],
              right: b.__options.distance[1],
              bottom: b.__options.distance[2],
              left: b.__options.distance[3]
            }), "string" == typeof b.__options.side) {
              var c = {
                top: "bottom",
                right: "left",
                bottom: "top",
                left: "right"
              };
              b.__options.side = [b.__options.side, c[b.__options.side]], "left" == b.__options.side[0] || "right" == b.__options.side[0] ? b.__options.side.push("top", "bottom") : b.__options.side.push("right", "left");
            }

            6 === a.tooltipster._env.IE && b.__options.arrow !== !0 && (b.__options.arrow = !1);
          },
          __reposition: function __reposition(b, c) {
            var d,
                e = this,
                f = e.__targetFind(c),
                g = [];

            e.__instance._$tooltip.detach();

            var h = e.__instance._$tooltip.clone(),
                i = a.tooltipster._getRuler(h),
                j = !1,
                k = e.__instance.option("animation");

            switch (k && h.removeClass("tooltipster-" + k), a.each(["window", "document"], function (d, k) {
              var l = null;
              if (e.__instance._trigger({
                container: k,
                helper: c,
                satisfied: j,
                takeTest: function takeTest(a) {
                  l = a;
                },
                results: g,
                type: "positionTest"
              }), 1 == l || 0 != l && 0 == j && ("window" != k || e.__options.viewportAware)) for (var d = 0; d < e.__options.side.length; d++) {
                var m = {
                  horizontal: 0,
                  vertical: 0
                },
                    n = e.__options.side[d];
                "top" == n || "bottom" == n ? m.vertical = e.__options.distance[n] : m.horizontal = e.__options.distance[n], e.__sideChange(h, n), a.each(["natural", "constrained"], function (a, d) {
                  if (l = null, e.__instance._trigger({
                    container: k,
                    event: b,
                    helper: c,
                    mode: d,
                    results: g,
                    satisfied: j,
                    side: n,
                    takeTest: function takeTest(a) {
                      l = a;
                    },
                    type: "positionTest"
                  }), 1 == l || 0 != l && 0 == j) {
                    var h = {
                      container: k,
                      distance: m,
                      fits: null,
                      mode: d,
                      outerSize: null,
                      side: n,
                      size: null,
                      target: f[n],
                      whole: null
                    },
                        o = "natural" == d ? i.free() : i.constrain(c.geo.available[k][n].width - m.horizontal, c.geo.available[k][n].height - m.vertical),
                        p = o.measure();
                    if (h.size = p.size, h.outerSize = {
                      height: p.size.height + m.vertical,
                      width: p.size.width + m.horizontal
                    }, "natural" == d ? c.geo.available[k][n].width >= h.outerSize.width && c.geo.available[k][n].height >= h.outerSize.height ? h.fits = !0 : h.fits = !1 : h.fits = p.fits, "window" == k && (h.fits ? "top" == n || "bottom" == n ? h.whole = c.geo.origin.windowOffset.right >= e.__options.minIntersection && c.geo.window.size.width - c.geo.origin.windowOffset.left >= e.__options.minIntersection : h.whole = c.geo.origin.windowOffset.bottom >= e.__options.minIntersection && c.geo.window.size.height - c.geo.origin.windowOffset.top >= e.__options.minIntersection : h.whole = !1), g.push(h), h.whole) j = !0;else if ("natural" == h.mode && (h.fits || h.size.width <= c.geo.available[k][n].width)) return !1;
                  }
                });
              }
            }), e.__instance._trigger({
              edit: function edit(a) {
                g = a;
              },
              event: b,
              helper: c,
              results: g,
              type: "positionTested"
            }), g.sort(function (a, b) {
              if (a.whole && !b.whole) return -1;
              if (!a.whole && b.whole) return 1;

              if (a.whole && b.whole) {
                var c = e.__options.side.indexOf(a.side),
                    d = e.__options.side.indexOf(b.side);

                return d > c ? -1 : c > d ? 1 : "natural" == a.mode ? -1 : 1;
              }

              if (a.fits && !b.fits) return -1;
              if (!a.fits && b.fits) return 1;

              if (a.fits && b.fits) {
                var c = e.__options.side.indexOf(a.side),
                    d = e.__options.side.indexOf(b.side);

                return d > c ? -1 : c > d ? 1 : "natural" == a.mode ? -1 : 1;
              }

              return "document" == a.container && "bottom" == a.side && "natural" == a.mode ? -1 : 1;
            }), d = g[0], d.coord = {}, d.side) {
              case "left":
              case "right":
                d.coord.top = Math.floor(d.target - d.size.height / 2);
                break;

              case "bottom":
              case "top":
                d.coord.left = Math.floor(d.target - d.size.width / 2);
            }

            switch (d.side) {
              case "left":
                d.coord.left = c.geo.origin.windowOffset.left - d.outerSize.width;
                break;

              case "right":
                d.coord.left = c.geo.origin.windowOffset.right + d.distance.horizontal;
                break;

              case "top":
                d.coord.top = c.geo.origin.windowOffset.top - d.outerSize.height;
                break;

              case "bottom":
                d.coord.top = c.geo.origin.windowOffset.bottom + d.distance.vertical;
            }

            "window" == d.container ? "top" == d.side || "bottom" == d.side ? d.coord.left < 0 ? c.geo.origin.windowOffset.right - this.__options.minIntersection >= 0 ? d.coord.left = 0 : d.coord.left = c.geo.origin.windowOffset.right - this.__options.minIntersection - 1 : d.coord.left > c.geo.window.size.width - d.size.width && (c.geo.origin.windowOffset.left + this.__options.minIntersection <= c.geo.window.size.width ? d.coord.left = c.geo.window.size.width - d.size.width : d.coord.left = c.geo.origin.windowOffset.left + this.__options.minIntersection + 1 - d.size.width) : d.coord.top < 0 ? c.geo.origin.windowOffset.bottom - this.__options.minIntersection >= 0 ? d.coord.top = 0 : d.coord.top = c.geo.origin.windowOffset.bottom - this.__options.minIntersection - 1 : d.coord.top > c.geo.window.size.height - d.size.height && (c.geo.origin.windowOffset.top + this.__options.minIntersection <= c.geo.window.size.height ? d.coord.top = c.geo.window.size.height - d.size.height : d.coord.top = c.geo.origin.windowOffset.top + this.__options.minIntersection + 1 - d.size.height) : (d.coord.left > c.geo.window.size.width - d.size.width && (d.coord.left = c.geo.window.size.width - d.size.width), d.coord.left < 0 && (d.coord.left = 0)), e.__sideChange(h, d.side), c.tooltipClone = h[0], c.tooltipParent = e.__instance.option("parent").parent[0], c.mode = d.mode, c.whole = d.whole, c.origin = e.__instance._$origin[0], c.tooltip = e.__instance._$tooltip[0], delete d.container, delete d.fits, delete d.mode, delete d.outerSize, delete d.whole, d.distance = d.distance.horizontal || d.distance.vertical;
            var l = a.extend(!0, {}, d);

            if (e.__instance._trigger({
              edit: function edit(a) {
                d = a;
              },
              event: b,
              helper: c,
              position: l,
              type: "position"
            }), e.__options.functionPosition) {
              var m = e.__options.functionPosition.call(e, e.__instance, c, l);

              m && (d = m);
            }

            i.destroy();
            var n, o;
            "top" == d.side || "bottom" == d.side ? (n = {
              prop: "left",
              val: d.target - d.coord.left
            }, o = d.size.width - this.__options.minIntersection) : (n = {
              prop: "top",
              val: d.target - d.coord.top
            }, o = d.size.height - this.__options.minIntersection), n.val < this.__options.minIntersection ? n.val = this.__options.minIntersection : n.val > o && (n.val = o);
            var p;
            p = c.geo.origin.fixedLineage ? c.geo.origin.windowOffset : {
              left: c.geo.origin.windowOffset.left + c.geo.window.scroll.left,
              top: c.geo.origin.windowOffset.top + c.geo.window.scroll.top
            }, d.coord = {
              left: p.left + (d.coord.left - c.geo.origin.windowOffset.left),
              top: p.top + (d.coord.top - c.geo.origin.windowOffset.top)
            }, e.__sideChange(e.__instance._$tooltip, d.side), c.geo.origin.fixedLineage ? e.__instance._$tooltip.css("position", "fixed") : e.__instance._$tooltip.css("position", ""), e.__instance._$tooltip.css({
              left: d.coord.left,
              top: d.coord.top,
              height: d.size.height,
              width: d.size.width
            }).find(".tooltipster-arrow").css({
              left: "",
              top: ""
            }).css(n.prop, n.val), e.__instance._$tooltip.appendTo(e.__instance.option("parent")), e.__instance._trigger({
              type: "repositioned",
              event: b,
              position: d
            });
          },
          __sideChange: function __sideChange(a, b) {
            a.removeClass("tooltipster-bottom").removeClass("tooltipster-left").removeClass("tooltipster-right").removeClass("tooltipster-top").addClass("tooltipster-" + b);
          },
          __targetFind: function __targetFind(a) {
            var b = {},
                c = this.__instance._$origin[0].getClientRects();

            if (c.length > 1) {
              var d = this.__instance._$origin.css("opacity");

              1 == d && (this.__instance._$origin.css("opacity", .99), c = this.__instance._$origin[0].getClientRects(), this.__instance._$origin.css("opacity", 1));
            }

            if (c.length < 2) b.top = Math.floor(a.geo.origin.windowOffset.left + a.geo.origin.size.width / 2), b.bottom = b.top, b.left = Math.floor(a.geo.origin.windowOffset.top + a.geo.origin.size.height / 2), b.right = b.left;else {
              var e = c[0];
              b.top = Math.floor(e.left + (e.right - e.left) / 2), e = c.length > 2 ? c[Math.ceil(c.length / 2) - 1] : c[0], b.right = Math.floor(e.top + (e.bottom - e.top) / 2), e = c[c.length - 1], b.bottom = Math.floor(e.left + (e.right - e.left) / 2), e = c.length > 2 ? c[Math.ceil((c.length + 1) / 2) - 1] : c[c.length - 1], b.left = Math.floor(e.top + (e.bottom - e.top) / 2);
            }
            return b;
          }
        }
      }), a;
    });
  }, {
    "jquery": 2
  }]
}, {}, [1]);